import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  public apiUrl: any;
  constructor(private http: HttpClient) {
      this.apiUrl = window['__env'].apiUrl;
  }

  geFavorites() {
    return this.http.get(`${this.apiUrl}/navigation/favorites`);
  }

  updateFavorites(requestObj: any) {
    return this.http.post(`${this.apiUrl}/navigation/favorites/update`, requestObj);
  }
}
