<span *ngIf="params.colDef.headerName === 'Action' || params.colDef.headerName === 'View Updates' && loggedInUser.role.value !== 'scw_supplier_user'"
    class="icon-jump-out grid-renderer-visibility-icon" title="Summary" 
    (click)="redirectToSummaryLink(params.data.case_link)">
</span>
<span *ngIf="params.colDef.headerName === 'Action' || params.colDef.headerName === 'View Updates'" 
    class="icon-eye grid-renderer-visibility-icon" title="View History" (click)="onSelectIncidentId()">
</span>

<span *ngIf="params.colDef.headerName === 'Case Number' && loggedInUser.role.value !== 'scw_supplier_user'" 
    (click)="redirectToEsp(params.value)">
    <a  target="_blank" class="grid-renderer-anchor">{{params.value}}
    </a>
</span>

<span *ngIf="params.colDef.headerName === 'Case Number' && loggedInUser.role.value === 'scw_supplier_user'">
    {{params.value}}
</span>

