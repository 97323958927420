import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { RouterConstants } from 'src/app/constants/router-constants';

@Component({
  selector: 'app-action-renderer',
  templateUrl: './action-renderer.component.html',
  styleUrls: ['./action-renderer.component.css']
})
export class ActionRendererComponent implements ICellRendererAngularComp {

  params: any;
  userType: any;

  constructor(private routerConstant: RouterConstants) {
    this.userType = this.routerConstant.getUserType();
  }

  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }

  updateSurveyInQuestion() {
    this.params.context.componentParent.updateSurveyInQuestion(this.params.data);
  }

  publishSurvey() {
    this.params.context.componentParent.viewPublishList(this.params.data);
  }

  sendIncidentReminder() {
    this.params.context.componentParent.sendIncidentReminder('open', this.params.data);
  }

  onClickDeleteOrArchive(type: any) {
    this.params.context.componentParent.onClickDeleteOrArchiveIncident(type, this.params.data);
  }

  unpublishIncident() {
    this.params.context.componentParent.unpublishIncident(this.params.data);
  }
 
 openResponse() {
      this.params.context.componentParent.openResponse(this.params.data.supplierName, this.params.data.parent.surveyId,this.params.data?.fileId, this.params.data?.fileName, this.params.data.supplierStatus);
    }
  
}
