import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth-guard/auth-guard.service';
import { CallbackComponent } from './callback/pages/callback.component';


const routes: Routes = [

  {
    path: 'callback',
    component: CallbackComponent,
  },
  {
    path: 'supplier-whitepaper',
    loadChildren: () => import(`./supplier-whitepaper/supplier-whitepaper.module`).then(
      module => module.SupplierWhitePaperModule
    ),
    canLoad: [AuthGuard]
  },
  {
    path: 'dashboard',
    loadChildren: () => import(`./dashboard/dashboard.module`).then(
      module => module.DashboardModule
    ),
    canLoad: [AuthGuard]
  },
  {
    path: 'messages',
    loadChildren: () => import(`./message/message.module`).then(
      module => module.MessageModule
    ),
    canLoad: [AuthGuard]
  },
  {
    path: 'contact-management',
    loadChildren: () => import(`./contact-management/contact-management.module`).then(
      module => module.ConatactManagementModule
    ),
    canLoad: [AuthGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'tools',
    loadChildren: () => import(`./tools/tools.module`).then(
      module => module.ToolsModule
    ),
    canLoad: [AuthGuard]
  },
  {
    path: 'documents',
    loadChildren: () => import(`./documents/documents.module`).then(
      module => module.DocumentsModule
    ),
    canLoad: [AuthGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'lookup',
    loadChildren: () => import(`./lookup/lookup.module`).then(
      module => module.LookupModule
    ),
    canLoad: [AuthGuard]
  },
  {
    path: 'wizards',
    loadChildren: () => import(`./wizards/wizards.module`).then(
      module => module.WizardsModule
    ),
    canLoad: [AuthGuard]
  },
  {
    path: 'supplier-home',
    loadChildren: () => import(`./supplier-home/supplier-home.module`).then(
      module => module.SupplierHomeModule
    ),
    canLoad: [AuthGuard]
  },
  {
    path: 'supplier-new-home',
    loadChildren: () => import(`./supplier-home-v2/supplier-home-v2.module`).then(
      module => module.SupplierHomeV2Module
    ),
    canLoad: [AuthGuard]
  },
  {
    path: 'questionnaire',
    loadChildren: () => import(`./questionnaire/questionnaire.module`).then(
      module => module.QuestionnairetModule
    ),
    canLoad: [AuthGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'fulfillment',
    loadChildren: () => import(`./fullfilment/fullfilment.module`).then(
      module => module.FullfilmentModule
    ),
    canLoad: [AuthGuard]
  },
  {
    path: 'quality',
    loadChildren: () => import(`./quality/quality.module`).then(
      module => module.QualityModule
    ),
    canLoad: [AuthGuard]
  },
  {
    path: 'baao-metrics',
    loadChildren: () => import(`./baao-metrics/baao-metrics.module`).then(
      module => module.BaaoMetricsModule
    ),
    canLoad: [AuthGuard]
  },
  {
    path: 'events',
    loadChildren: () => import(`./events/events.module`).then(
      module => module.EventsModule
    ),
    canLoad: [AuthGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'manage-metrics',
    loadChildren: () => import(`./manage-metrics/manage-metrics.module`).then(
      module => module.ManageMetricsModule
    ),
    canLoad: [AuthGuard]
  },
  {
    path: 'supplier-profile',
    loadChildren: () => import(`./supplier-profile/supplier-profile.module`).then(
      module => module.SupplierProfileModule
    ),
    canLoad: [AuthGuard]
  },
  {
    path: 'resiliency-score-card',
    loadChildren: () => import(`./resiliency/resiliency.module`).then(
      module => module.ResiliencyModule
    ),
    canLoad: [AuthGuard]
  },
  {
    path: 'fhr-detailed-view',
    loadChildren: () => import(`./fhr/fhr-detailed-view.module`).then(
      module => module.FhrDetailedViewModule
    ),
    canLoad: [AuthGuard]
  },
  {
    path: 'sbr-score-card',
    loadChildren: () => import(`./sbr/sbr.module`).then(
      module => module.SbrModule
    ),
    canLoad: [AuthGuard]
  },
  {
    path: 'about-us',
    loadChildren: () => import(`./about-us/about-us.module`).then(
      module => module.AboutUsModule
    ),
    canLoad: [AuthGuard]
  },
  {
    path: 'faq',
    loadChildren: () => import(`./faq/faq.module`).then(
      module => module.FaqModule
    ),
    canLoad: [AuthGuard]
  },
  {
    path: 'supplier-sustainability-score',
    loadChildren: () => import(`./supplier-sustainability-score/supplier-sustainability-score.module`).then(
      module => module.SupplierSustainabilityScoreModule
    ),
    canLoad: [AuthGuard]
  },
  {
    path: 'supplier-summary',
    loadChildren: () => import(`./supplier-mail-summary-track/supplier-mail-summary-track.module`).then(
      module => module.SupplierMailSummaryTrackModule
    )
  },
  {
    path: 'logout',
    loadChildren: () => import(`./logout/logout.module`).then(
      module => module.LogoutModule
    ),
    canDeactivate: [AuthGuard]
  },
  {
    path: 'no-cepm-role',
    loadChildren: () => import(`./no-cepm-role/no-cepm-role.module`).then(
      module => module.NoCepmRoleModule
    ),
    canDeactivate: [AuthGuard]
  },
  {
    path: 'supplier-out-of-scope',
    loadChildren: () => import(`./supplier-out-of-scope/supplier-out-of-scope.module`).then(
      module => module.SupplierOutOfScopeModule
    ),
    canDeactivate: [AuthGuard]
  },
  {
    path: 'confirmation',
    loadChildren: () => import(`./confirmation/confirmation.module`).then(
      module => module.ConfirmationModule
    )
  },
  {
    path: 'sucm-home',
    loadChildren: () => import(`./sucm-home/sucm-home.module`).then(
      module => module.SucmHomeModule
    ),
    canLoad: [AuthGuard]
  },
  {
    path: 'general-user-home',
    loadChildren: () => import(`./general-user-home/general-user-home.module`).then(
      module => module.GeneralUserHomeModule
    ),
    canLoad: [AuthGuard]
  },
  {
    path: 'incident-management',
    loadChildren: () => import(`./incident-management/incident-management.module`).then(
      module => module.IncidentManagementModule
    ),
    canLoad: [AuthGuard]
  },
  {
    path: 'download',
    loadChildren: () => import(`./download/download.module`).then(
      module => module.DownloadModule
    ),
    canLoad: [AuthGuard]
  },
  {
    path: 'parent-mapping',
    loadChildren: () => import(`./parent-mapping/parent-mapping.module`).then(
      module => module.ParentMappingModule
    ),
    canLoad: [AuthGuard]
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false, onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
