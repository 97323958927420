import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class SupplierWhitepaperService {
    public apiUrl: any;
    constructor(private http: HttpClient) {
        this.apiUrl = window['__env'].apiUrl;
    }

    getSupplierList() {
        return this.http.get(`${this.apiUrl}/whitepaper/supplierData`);
    }

    getSectionList() {
        return this.http.get(`${this.apiUrl}/whitepaper/getDocumentSections`);
    }

    createWhitepaper(data: any) {
        return this.http.post(`${this.apiUrl}/whitepaper/createWhitepaper`, data);
    }

    updateWhitepaper(data: any) {
        return this.http.post(`${this.apiUrl}/whitepaper/createUpdateWhitepaper`, data);
    }

    getAllWhitePepersByUser(pageNo: any, itemsPerPage: any, wpStatus: string, searchtext: string, isDeleted: any) {
        return this.http.get(`${this.apiUrl}/whitepaper/getAllWhitepaperByUserId?page=${pageNo}&size=${itemsPerPage}&status=${wpStatus}&keyword=${searchtext}&isDeleted=${isDeleted}`);
    }

    getWhitepaperById(whitepaperId: any) {
        return this.http.get(`${this.apiUrl}/whitepaper/getWhiteppaper/${whitepaperId}`);
    }

    getUploadedFile(fileName: any, whitepaperId: any) {
        return this.http.get(`${this.apiUrl}/whitepaper/getWhitepaperFile?filename=${encodeURIComponent(fileName)}&whitepaperId=${whitepaperId}`, {
            responseType: "blob",
            headers: new HttpHeaders().append("Content-Type", "application/json")
        });
    }

    getAllGSMUsers() {
        return this.http.get(`${this.apiUrl}/whitepaper/getAllCollaborators`);
    }

    getReoprtingManagers(userId: any) {
        return this.http.post(`${this.apiUrl}/ldapService/getTopManagers?ldapUserId=${encodeURIComponent(userId)}`, {});
    }

    getLeadCommodity(supplierName: any) {
        return this.http.get(`${this.apiUrl}/whitepaper/commodities?supplierName=${encodeURIComponent(supplierName)}`);
    }

    getTechnologySpend(supplierName: any) {
        return this.http.get(`${this.apiUrl}/whitepaper/getTechnologySpend?supplierName=${encodeURIComponent(supplierName)}`);
    }

    getPslSpendData(supplierName: any) {
        return this.http.get(`${this.apiUrl}/whitepaper/getPSLStatusSpendData?supplierName=${encodeURIComponent(supplierName)}`);
    }

    getSpendAndProductivityData(supplierName: any) {
        return this.http.get(`${this.apiUrl}/whitepaper/getCommoditiesSpendData?supplierName=${encodeURIComponent(supplierName)}`);
    }

    getNpiData(supplierName: any) {
        return this.http.get(`${this.apiUrl}/whitepaper/getNPIProgramData?supplierName=${encodeURIComponent(supplierName)}`);
    }

    getCommodityHealthData(supplierName: any) {
        return this.http.post(`${this.apiUrl}/whitepaper/getCommodityHealthforSupplier?supplier=${encodeURIComponent(supplierName)}`, {});
    }

    getResiliencyData(supplierName: any) {
        return this.http.get(`${this.apiUrl}/whitepaper/getResiliencyData?supplierName=${encodeURIComponent(supplierName)}`);
    }

    getBusinessData(supplierName: any) {
        return this.http.get(`${this.apiUrl}/whitepaper/getNPIPotentialAwardData?supplierName=${encodeURIComponent(supplierName)}`);
    }

    downloadWhitepaper(whitepaperId: any, docType: any) {
        return this.http.post(`${this.apiUrl}/whitepaper/publishWhitepaper?whitepaperId=${whitepaperId}&type=${docType}`, {}, {
            responseType: "blob",
            headers: new HttpHeaders().append("Content-Type", "application/json")
        });
    }

    checkIfWhitepaperExistsForSupplier(supplierName: any) {
        return this.http.get(`${this.apiUrl}/whitepaper/getPublishedStatusBySupplier?supplier=${encodeURIComponent(supplierName)}`);
    }

    getAllImagesByWhitepaperId(whitepaperId: any) {
        return this.http.get(`${this.apiUrl}/whitepaper/getAllWhitepaperFiles?whitepaperId=${whitepaperId}`);
    }

    getImagesByFileNames(fileNames: any) {
        return this.http.post(`${this.apiUrl}/whitepaper/getFilesByFileNames`, fileNames);
    }

    saveWhitepaperFields(data: any) {
        return this.http.post(`${this.apiUrl}/whitepaper/saveWhitepaperFields`, data);
    }

    getWhitepaperFields() {
        return this.http.get(`${this.apiUrl}/whitepaper/fetchSavedWhitepaperFields`);
    }

    sendReviewMail(data: any) {
        return this.http.post(`${this.apiUrl}/whitepaper/sendMailforReview`, data);
    }

    sendPublishMail(data: any) {
        return this.http.post(`${this.apiUrl}/whitepaper/sendPublishMail`, data);
    }

    sendPendingRevisionMail(data: any) {
        return this.http.post(`${this.apiUrl}/whitepaper/sendPendingRevisonMail`, data);
    }

    sendReReviewMail(data: any) {
        return this.http.post(`${this.apiUrl}/whitepaper/sendReadyforReReviewMail`, data);
    }

    sendReadyToPublishMail(data: any) {
        return this.http.post(`${this.apiUrl}/whitepaper/sendReadyForPublishMail`, data);
    }

    getPublishedWhitepaperId(supplierName: any) {
        return this.http.post(`${this.apiUrl}/whitepaper/getPublishedWhitepaperId?supplier=${encodeURIComponent(supplierName)}`, {});
    }

    lockWhitepaperSection(requestObj: any) {
        return this.http.post(`${this.apiUrl}/whitepaper/lockWhitepaperSection`, requestObj);
    }

    unlockWhitepaperSection(requestObj: any) {
        return this.http.post(`${this.apiUrl}/whitepaper/unlockWhitepaperSection`, requestObj);
    }

    getWhitepaperSectionLockStatus(requestObj: any) {
        return this.http.post(`${this.apiUrl}/whitepaper/getWhitepaperSectionLockStatus`, requestObj);
    }
}
