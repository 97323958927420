import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, RouteConfigLoadEnd, RouteConfigLoadStart, Router, RouterEvent } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from './shared-service/shared.service';
import { TimeoutComponent } from './timeout/timeout.component';
import * as $ from 'jquery';
import { CepmService } from './services/cepm.service';
import { LoaderService } from './loader/loader.service';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { RouterConstants } from './constants/router-constants';
import { DashboardService } from './services/dashboard.service';
import { ToolsService } from './services/tools.service';
import { EncrDecrService } from './services/encrypt-decrypt.service';
import { MainMenuTab, SubMenuTabs, TabMenuGroup } from './constants/menu-panel-constants';
import { ContactManagementService } from './services/contact-management.service';
import { LookupService } from './services/lookup.service';
import { EditorConstants } from './constants/editor-constants';
import { RegionService } from './services/fetchRegion.service';
import { AgGridAngular } from 'ag-grid-angular';
import { DataRefreshRendererComponent } from './data-refresh-renderer/data-refresh-renderer.component';
import { SplunkLogsService } from './services/splunkLogs.service';
import { SupplierHomeService } from './services/supplier-home.service';
import { AuthService } from './auth/auth.service';
import { DateFilterService } from './services/date-filter.service';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { catchError, lastValueFrom, of, tap } from 'rxjs';
import { set } from 'lodash';
import { SupplierWhitepaperService } from './services/supplier-whitepaper.service';
import { DataService } from './services/data.service';
import { ContentObserver } from '@angular/cdk/observers';
import { NavigationService } from './services/navigation.service';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  isSocmRestricted: any = false;
  hideSectionsForSocm: any = true;
  isFilterOpened: any = false;
  isMenuOpened: any = false;
  loggedInUser: any = {};
  selectedSupplier: any = 'ALL';
  modalRefBox: any;
  isLoggedIn: any = false;
  browserScreenHeight: any = '';
  toolsList: any = [];
  showSubTabs = false;
  timeoutModalRefBox: any;
  searchedSupplier: any = '';
  supplierList: any = [];
  filteredSupplierList: any = [];

  isAllCommoditiesSelected: any = false;
  selectedCommodity: any = [];
  commodityArr: any = [];
  commodityList: any = [];
  searchedCommodity: any = '';

  isAllSucmSelected: any = false;
  selectedSucm: any = [];
  sucmArr: any = [];
  sucmList: any = [];
  searchedSucm: any = '';

  mainTabs: MainMenuTab[] = [];
  selectedMainMenuIndex: any = 0;
  selectedTabMenuGrpIndex: any = 0;
  selectedSubMenuIndex: any = 0;
  openReportIssuesPopup: any = false;
  disableToggleAnimation: any = true;
  infoPopup: any = false;
  tools = EditorConstants.modules;
  readMeInfo: any = '';
  isHelpPageOpened: any = false;
  isUserRestricted: any = true;
  isSupplierRestricted: any = false;
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  sortSupplierType: any = '';
  isDataRefreshPopup: any = false;
  addSupplierNotes: any = false;
  viewSupplierNote: any = false;
  selectedSupplierNote: any = {};
  suppliers: any = [];
  mentionConfig: any = {};
  supplierNotes: any = '';
  enableSupplierNote: any = 'N';
  public frameworkComponents;
  public context;
  dynamicSupplierName = null;
  dynamicSurveyId = null;
  isHovering: boolean = false;
  isUserPersonaHovering: boolean = false;
  breadcrumbs: any[] = [];
  SCWTagLine: any = '';
  maxLinks = 5;
  visitedLinks: any[] = [];
  isWhitepaperAccess: any = false;
  scrollMsg: any = '';
  favorites: Array<any> = [];



  @ViewChild('dataRefreshGrid') dataRefreshGrid: AgGridAngular;
  dataRefreshGridConfig: any = {
    rowData: [],
    columnDefs: [
      {
        headerName: 'Metric',
        field: 'metric',
        headerTooltip: 'Metric',
        tooltipValueGetter: params => params.value
      },
      {
        headerName: 'Last Refresh (PST Hrs)',
        field: 'lastRefresh',
        headerTooltip: 'Last Refresh (PST Hrs)',
        type: 'dateColumn',
        tooltipValueGetter: params => params.value
      },
      {
        headerName: 'Next Refresh (PST Hrs)',
        field: 'nextRefresh',
        headerTooltip: 'Next Refresh (PST Hrs)',
        type: 'dateColumn',
        tooltipValueGetter: params => params.value
      },
      {
        headerName: 'Source',
        field: 'source',
        headerTooltip: 'Source',
        tooltipValueGetter: params => params.value,
        cellRenderer: 'dataRefreshRenderer'
      },
      {
        headerName: 'Frequency',
        field: 'frequency',
        headerTooltip: 'Frequency',
        tooltipValueGetter: params => params.value
      },
      {
        headerName: 'Job Status',
        field: 'jobStatus',
        headerTooltip: 'Job Status',
        tooltipValueGetter: params => params.value,
        cellRenderer: 'dataRefreshRenderer'
      }
    ]
  };

  dataRefreshGridDefaultColDef: any = {
    sortable: true,
    resizable: true,
    editable: false,
    enableRowGroup: false,
    floatingFilter: true,
    filter: 'agTextColumnFilter'
  }

  dataRefreshGridOptions: any = {
    tooltipShowDelay: 0
  };

  columnTypes: any = {
    dateColumn: {
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: (filterLocalDateAtMidnight, cellValue) => this.dateFilter.comparator(filterLocalDateAtMidnight, cellValue),
      },
      comparator: (date1: any, date2: any) => this.dateFilter.dateComparatorSort(date1, date2)
    }
  };

  constructor(
    private idle: Idle,
    private keepalive: Keepalive,
    public router: Router,
    private modalService: NgbModal,
    private sharedService: SharedService,
    private loaderService: LoaderService,
    private lookupService: LookupService,
    private supplierService: SupplierHomeService,
    private cepmService: CepmService,
    private dateFilter: DateFilterService,
    private dashboardService: DashboardService,
    public routerConstants: RouterConstants,
    private toolsService: ToolsService,
    private encDecSrvice: EncrDecrService,
    private contactsService: ContactManagementService,
    private cdRef: ChangeDetectorRef,
    private regionService: RegionService,
    private splunkLogsService: SplunkLogsService,
    private whitepaperService: SupplierWhitepaperService,
    private authService: AuthService,
    private dataService: DataService,
    private navigationService: NavigationService,
    private snackBar: MatSnackBar,
  ) {
    cdRef.detach();
    setInterval(() => {
      this.cdRef.detectChanges();
    }, 200);

    this.context = { componentParent: this };
    this.frameworkComponents = {
      dataRefreshRenderer: DataRefreshRendererComponent
    };

    this.browserScreenHeight = window.innerHeight;
    router.events.subscribe(
      (event: any): void => {
        if (event instanceof RouteConfigLoadStart) {
          this.loaderService.openLoader();

        }
        else if (event instanceof RouteConfigLoadEnd) {
          this.loaderService.closeLoader();

        }
        this.getFavorites();
        this.breadcrumbs = this.generateBreadcrumbs(this.mainTabs);

      }
    );
    // sets an idle timeout of 16 minutes (check if user is idle for 10mins)
    //idle.setIdle(1200);
    idle.setIdle(1800);


    // sets a timeout period of 1sec.
    // after 1sec of inactivity, the user will be considered timed out.
    idle.setTimeout(1);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onTimeout.subscribe(() => {
      const lastActiveTime = localStorage.getItem('userIsActive');
      if (!lastActiveTime || new Date(lastActiveTime) < new Date(Date.now() - idle.getIdle())) {
        // No activity in any tabs, so proceed with the logout
        this.idleState = 'Timed out!';
        this.timedOut = true;
        this.modalService.dismissAll(ConfirmationComponent);
        $('.modal').hide();
        this.logout();
      } else {
        // There was activity, so reset the idle timer
        idle.watch();
      }
    });

    idle.onIdleStart.subscribe(() => {
      this.idleState = `You've gone idle!`;
      const lastActiveTime = localStorage.getItem('userIsActive');
      if (lastActiveTime && new Date(lastActiveTime) > new Date(Date.now() - idle.getIdle())) {
        idle.watch();
      }
    });

    idle.onTimeoutWarning.subscribe((countdownInSecs) => {
      const minsLeft = (countdownInSecs - (countdownInSecs %= 60)) / 60 + (9 < countdownInSecs ? ':' : ':0') + countdownInSecs;
      this.idleState = `You will time out in ${minsLeft} minutes!`;
    });

    // sets the ping interval to 15 seconds
    keepalive.interval(15);

    keepalive.onPing.subscribe(() => this.lastPing = new Date());


    window.addEventListener('storage', (event) => {
      if (event.key === 'lastActiveTime') {
        this.idle.watch();
      }
    });


    // Lets check the path everytime the route changes, stop or start the idle check as appropriate.
    router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes('general-user-home')) {
          this.onClickFilterPanel('open');
        }
        if ((event.url.includes('sucm-home/my-view') || event.url.includes('general-user-home') || event.url.includes('dashboard') || event.url.includes('sucm-home/overall-view')) && this.selectedSupplier !== 'ALL') {
          this.onSelectSupplier('ALL');
        }
        if (event.url === '/logout') {
          idle.stop();
        }
        else {
          idle.watch();
        }
        this.loaderService.closeLoader();
        this.breadcrumbs = this.generateBreadcrumbs(this.mainTabs);
        this.scrollMessage(); 
        //this.getFavorites();
        //this.loadFavorites();


      }
    });
    this.reset();
    const visitedLinksString = sessionStorage.getItem('visitedLinks');
    if (visitedLinksString) {
      this.visitedLinks = JSON.parse(visitedLinksString);
    }
  }


  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }


  async ngOnInit(): Promise<void> {

    this.setupActivityListener();

    // check event if user has clicked on loggedIn back
    this.sharedService.getClickEvent().subscribe(async (data: any) => {
      if (data) {
        window.localStorage.clear();
        this.initializeLoggedInVariables();
        window.location.href = '/';
        await this.getCepmRoles('login');
        this.getTagLineLookupValues();
        this.scrollMessage();
      }
      else {
        this.logout();
      }
    });

    this.sharedService.getTabClickEvent().subscribe((data: any) => {
      if (data) {
        this.breadcrumbs = this.generateBreadcrumbs(this.mainTabs, data);
      }
    })

    this.sharedService.getNonBaaoSupp().subscribe((data: any) => {
      if (data) {
        this.initializeFilterList();
      }
    });

    this.sharedService.getLogOut().subscribe((data: any) => {
      if (data) {
        this.onClickLogout();
      }
    });

    this.routerConstants.getIsPageReloaded().subscribe(async (data: any) => {
      if (data) {
        await this.getCepmRoles('reload');
        this.getTagLineLookupValues();
        this.scrollMessage(); 

      }
    });

    this.sharedService.getSupplierNote().subscribe((data: any) => {
      this.getSupplierNote(data);
    });

    this.sharedService.getRedirectionUrl().subscribe(async (url: any) => {
      const redirectedFromTo = window.localStorage.getItem('redirectedFrom-To');
      const redirectedToMainTab = redirectedFromTo.split('-')[1].toLowerCase().trim();
      const redirectedFrom = redirectedFromTo.split('-')[0].toLowerCase().trim();
      window.localStorage.removeItem('redirectedFrom-To');
      if ((redirectedFrom === 'contacts' && this.displaySelectedSuplier() === 'ALL') || redirectedFrom === 'sucm home') {
        await this.onSelectSupplier({ supplierName: this.encDecSrvice.get(window.localStorage.getItem('selectedSupplier')) }, true);
      }

      const visibleTabs = this.mainTabs.filter((mainTab) => mainTab.mainTabName.toLowerCase() === redirectedToMainTab);
      window.localStorage.setItem('previousUrl', this.router.url);
      for (const mainTab of visibleTabs) {
        if (mainTab.tabGroups.length === 0) {
          if (mainTab.mainTabUrl === `/${url}`) {
            this.onClickMainMenuTab(mainTab);
            break;
          }
        }
        else {
          for (const tabGrp of mainTab.tabGroups) {
            for (const subTab of tabGrp.subTabs) {
              if (subTab.subTabUrl === `/${url}`) {
                this.onClickSubMenuTab(mainTab, tabGrp, subTab);
                break;
              }
            }
          }
        }
      }
    });

    this.sharedService.getQuickTour().subscribe((data: any) => {
      if (data === 'open' || data === 'close') {
        this.onClickQuickTour(data);
      }
    });

    this.sharedService.getFilterPanelValue().subscribe((data: any) => {
      this.onClickFilterPanel(data);
    });

    const currentUrl: any = window.location.href;

    if (currentUrl && (!currentUrl.split('/')[3] || currentUrl.split('/')[3] === 'callback')) {
      // user logs in for the first time to the application
      window.localStorage.clear();
      await this.getCepmRoles('login');
      this.getTagLineLookupValues();
      this.scrollMessage();
    }
    else if (window.localStorage.length > 0) {
      // user is already logged in and refresh the current url
      window.localStorage.setItem('type', 'reload');
      this.selectedMainMenuIndex = Number(window.sessionStorage.getItem('selectedMainMenuIndex'));
      this.selectedTabMenuGrpIndex = Number(window.sessionStorage.getItem('selectedTabMenuGrpIndex'));
      this.selectedSubMenuIndex = Number(window.sessionStorage.getItem('selectedSubMenuIndex'));
    }
    else {
      // without log in user tries to open url in different tab
      //this.logout();
    }
    //this.getTagLineLookupValues();
    //this.scrollMessage(); 

  }

  initializeLoggedInVariables() {
    this.isFilterOpened = false;
    this.isMenuOpened = false;
    this.selectedMainMenuIndex = 0;
    this.selectedTabMenuGrpIndex = 0;
    this.selectedSubMenuIndex = 0;
    this.selectedSupplier = 'ALL';
    this.isLoggedIn = false;
    this.addSupplierNotes = false;
    this.viewSupplierNote = false;
    this.selectedSupplierNote = {};
    this.suppliers = [];
    this.mentionConfig = {};
    this.supplierNotes = '';
    this.enableSupplierNote = 'N';

    this.searchedSupplier = '';
    this.supplierList = [];
    this.filteredSupplierList = [];

    this.isAllCommoditiesSelected = false;
    this.selectedCommodity = [];
    this.commodityArr = [];
    this.commodityList = [];
    this.searchedCommodity = '';

    this.isAllSucmSelected = false;
    this.selectedSucm = [];
    this.sucmArr = [];
    this.sucmList = [];
    this.searchedSucm = '';

    this.mainTabs = [];
    this.openReportIssuesPopup = false;
    this.disableToggleAnimation = true;
  }

  async getCepmRoles(type: any) {
    // fetch Ip Address and Region
    this.regionService.getIpAddr();
    this.loggedInUser = {};
    this.initializeFilterVariables();
    if (type === 'login') {
      window.localStorage.setItem('type', 'login');
      await this.cepmService.getCepmRole(false);
      this.loggedInUser = this.routerConstants.getLoggedInUser();
      if (this.loggedInUser && Object.keys(this.loggedInUser).length > 0 && this.loggedInUser.role.value) {
        if (this.loggedInUser.supplierScope === 'Y') {
          this.loaderService.openLoader();
          const respData: any = await lastValueFrom(this.contactsService.checkInternalContact())
            .catch((err: any) => {
              this.loaderService.closeLoader();
              this.openConfirmationPopup('Error', err.error.errorMsg, false);
            });
          if (respData && Object.keys(respData).length > 0 && respData.status === 'S') {
            this.loaderService.closeLoader();
            this.isLoggedIn = (this.loggedInUser && Object.keys(this.loggedInUser).length > 0) ? true : false;
            if (this.loggedInUser.role.value === 'sucm_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'comm_dir_res') {
              await this.checkIfUserIsRestricted();
            }
            else if (this.loggedInUser.role.value === 'SCO_scw_general_user_res') {
              await this.checkIfAccessIsRestricted();
            }

            if ((this.loggedInUser.role.value === 'scw_supplier_user_res' && this.loggedInUser.nonRFQScope === 'Y')
              || this.loggedInUser.role.value !== 'scw_supplier_user_res') {
              if (this.loggedInUser.role.value !== 'scw_supplier_user_res') {
                await this.checkIfUserIsWhitepaperAdmin();
              }
              this.initializeTabs();
              this.redirectBasedOnRole();
            }
          }
          else {
            //this.logout();
          }
        }
        else if (this.loggedInUser.supplierScope === 'N') {
          this.isLoggedIn = false;
          window.localStorage.clear();
          this.router.navigate(['/supplier-out-of-scope']);
        }
      }
      else {
        this.redirectBasedOnRole();
      }
    }
    else {
      
      this.loggedInUser = this.routerConstants.getLoggedInUser();
      this.isLoggedIn = true;
      if (this.loggedInUser.role.value !== 'scw_supplier_user_res') {
        await this.checkIfUserIsWhitepaperAdmin();
        if (this.loggedInUser.role.value === 'sucm_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'comm_dir_res') {
          await this.checkIfUserIsRestricted();
        }
        if (this.loggedInUser.role.value === 'SCO_scw_general_user_res') {
          await this.checkIfAccessIsRestricted();
        }
        this.getFilterList();
        await this.initializeTabs();
      }
      else {
        this.selectedSupplier = this.encDecSrvice.get(window.localStorage.getItem('selectedSupplier'));
        this.initializeTabs();
      }
      this.getTools();
      //this.loadFavorites();
      //this.getFavorites();

    }
    //   this.loggedInUser = this.routerConstants.getLoggedInUser();
    //   if (this.loggedInUser && Object.keys(this.loggedInUser).length > 0 && this.loggedInUser.role.value) {
    //     if (this.loggedInUser.supplierScope === 'Y') {
    //       this.isLoggedIn = (this.loggedInUser && Object.keys(this.loggedInUser).length > 0) ? true : false;
    //       if (this.loggedInUser.role.value === 'sucm_res') {
    //         await this.checkIfUserIsRestricted();
    //       }
    //       else if (this.loggedInUser.role.value === 'SCO_scw_general_user_res') {
    //         await this.checkIfAccessIsRestricted();
    //       }

    //       if ((this.loggedInUser.role.value === 'scw_supplier_user_res' && this.loggedInUser.nonRFQScope === 'Y')
    //         || this.loggedInUser.role.value !== 'scw_supplier_user_res') {
    //         this.initializeTabs();
    //       }
    //       else {
    //         this.logout();;;
    //       }
    //     }
    //     else if (this.loggedInUser.supplierScope === 'N') {
    //       this.isLoggedIn = false;
    //       window.localStorage.clear();
    //       this.router.navigate(['/supplier-out-of-scope']);
    //     }
    //   }
    //   else {
    //     this.redirectBasedOnRole();
    //   }
    // }
  }

  async checkIfUserIsWhitepaperAdmin() {
    this.isWhitepaperAccess = false;
    let loggedInUser = this.routerConstants.getLoggedInUser();
    this.isWhitepaperAccess = this.loggedInUser.isWhitepaperAdmin === 'Y' ? true : false;
    if (!this.isWhitepaperAccess) {
      try {
        const response = await lastValueFrom(
          this.whitepaperService.getAllGSMUsers().pipe(
            tap((response: any) => {
              // Check if the user has whitepaper access
              if (response && response.length > 0) {
                this.isWhitepaperAccess = response.filter((r: any) => r.cec_id === this.loggedInUser.userId).length > 0 ? true : false;
              }
            }),
            catchError((err: any) => {
              console.error('Error fetching GSM users:', err);
              return of(null); // Return a fallback value
            })
          )
        );
      } catch (error) {
        console.error('Error in service call:', error);
      } finally {
        this.loaderService.closeLoader(); // Close the loader
      }
    }
  
    loggedInUser.hasWhitepaperAccess = this.isWhitepaperAccess ? 'Y' : 'N';
    this.routerConstants.setLoggedInUser(loggedInUser);
  }

  async redirectBasedOnRole() {
    if (this.loggedInUser && Object.keys(this.loggedInUser).length > 0 && this.loggedInUser.role.value) {
      let currentUrl: any = window.location.href;
      this.selectedSupplier = this.loggedInUser.role.value === 'scw_supplier_user_res' ?
        this.loggedInUser.mcoCompany : this.selectedSupplier;
      window.localStorage.setItem('supplierName', this.encDecSrvice.set(this.selectedSupplier));
      window.localStorage.setItem('selectedSupplier', this.encDecSrvice.set(this.selectedSupplier));
      window.sessionStorage.setItem('selectedSupplier', this.encDecSrvice.set(this.selectedSupplier));
      window.localStorage.setItem('showNavigationHelpPopup', 'true');
      let redirectionalFlag = false;
      let redirectionUrl = currentUrl.split('/')[3];
      if (window.location.href.includes('messages') || window.location.href.includes('wizards')) {

        redirectionalFlag = true;
      }
      if (this.loggedInUser.role.value === 'scw_admin_res' && this.loggedInUser.role.value !== 'scw_supplier_user_res') {
        await this.initializeFilterList();
        let url = this.router.url;
        let redirection = url.substring(1);
        const mainTabName = this.getMainTabNameByUrl(url);
        let parts = url.split('/');
        if (window.location.href.includes('download') && parts[2] && parts[3] && parts[4]) {
          window.localStorage.setItem('externalDownloadFlag', 'true');
          window.localStorage.setItem('downloadSection', parts[2]);
          window.localStorage.setItem('downloadFlag', parts[3]);
          window.localStorage.setItem('downloadId', parts[4]);

          window.localStorage.setItem('redirectedFrom-To', 'Login-Admin');
          this.sharedService.setRedirectionUrl("download");


        } else if (window.location.href.includes('incident-management') && parts[2] && parts[3]) {
          if (this.loggedInUser.role.value === 'scw_admin_res') {
            window.localStorage.setItem('selectedSupplierUrl', this.encDecSrvice.set(parts[2]));
          }
          window.localStorage.setItem('selectedSurveyIdUrl', this.encDecSrvice.set(parts[3]));
          window.localStorage.setItem('fromexternalUrl', "true");

          window.localStorage.setItem('redirectedFrom-To', 'Login-Collaboration');
          this.sharedService.setRedirectionUrl("incident-management");
        }
        // else if (window.location.href.includes('supplier-whitepaper') && parts[2]) {
        //   window.localStorage.setItem('selectedWhitepaperIdFromUrl', parts[2]);
        //   window.localStorage.setItem('fromexternalUrl', "true");

        //   window.localStorage.setItem('redirectedFrom-To', 'Login-Admin');
        //   this.sharedService.setRedirectionUrl("supplier-whitepaper");
        // } 
        else if (url === '/' || url.startsWith('/callback')) {
          window.localStorage.setItem('redirectedFrom-To', 'Login-Workbench');
          this.sharedService.setRedirectionUrl('dashboard');
        } else if (mainTabName) {

          window.localStorage.setItem('redirectedFrom-To', 'Login-' + mainTabName);
          this.sharedService.setRedirectionUrl(redirection);
        } else {
          window.localStorage.setItem('redirectedFrom-To', 'Login-Workbench');
          this.sharedService.setRedirectionUrl('dashboard');
        }

      } else if (this.loggedInUser.role.value === 'comm_dir_res') {
        await this.initializeFilterList();
        let url = this.router.url;
        let redirection = url.substring(1);
        const mainTabName = this.getMainTabNameByUrl(url);
        let parts = url.split('/');
        if (this.isUserRestricted) {
          if (window.location.href.includes('incident-management') && parts[2] && parts[3]) {
            window.localStorage.setItem('selectedSupplierUrl', this.encDecSrvice.set(parts[2]));
            window.localStorage.setItem('selectedSurveyIdUrl', this.encDecSrvice.set(parts[3]));
            window.localStorage.setItem('fromexternalUrl', "true");
            window.localStorage.setItem('redirectedFrom-To', 'Login-Collaboration');
            this.sharedService.setRedirectionUrl("incident-management");
          } else if (url === '/' || url.startsWith('/callback')) {
            window.localStorage.setItem('redirectedFrom-To', 'Login-SCW Home');
            this.sharedService.setRedirectionUrl('general-user-home');
          }else if (mainTabName) {
            window.localStorage.setItem('redirectedFrom-To', 'Login-' + mainTabName);
            this.sharedService.setRedirectionUrl(redirection);
          }
        }
        else {
          if (window.location.href.includes('incident-management') && parts[2] && parts[3]) {
            window.localStorage.setItem('selectedSupplierUrl', this.encDecSrvice.set(parts[2]));
            window.localStorage.setItem('selectedSurveyIdUrl', this.encDecSrvice.set(parts[3]));
            window.localStorage.setItem('fromexternalUrl', "true");
            window.localStorage.setItem('redirectedFrom-To', 'Login-Collaboration');
            this.sharedService.setRedirectionUrl("incident-management");
          } else if (url === '/' || url.startsWith('/callback')) { 
            window.localStorage.setItem('redirectedFrom-To', 'Login-SCW Home');
            this.sharedService.setRedirectionUrl('sucm-home/my-view');
          }else if (mainTabName) {
            window.localStorage.setItem('redirectedFrom-To', 'Login-' + mainTabName);
            this.sharedService.setRedirectionUrl(redirection);
          }
        }
      } else if (this.loggedInUser.role.value === 'socm_res') {
        await this.initializeFilterList();
        let url = this.router.url;
        let redirection = url.substring(1);
        const mainTabName = this.getMainTabNameByUrl(url);
        let parts = url.split('/');
        if (this.isUserRestricted) {
          if (window.location.href.includes('incident-management') && parts[2] && parts[3]) {
            window.localStorage.setItem('selectedSupplierUrl', this.encDecSrvice.set(parts[2]));
            window.localStorage.setItem('selectedSurveyIdUrl', this.encDecSrvice.set(parts[3]));
            window.localStorage.setItem('fromexternalUrl', "true");
            window.localStorage.setItem('redirectedFrom-To', 'Login-Collaboration');
            this.sharedService.setRedirectionUrl("incident-management");
          } else if (url === '/' || url.startsWith('/callback')) {
            window.localStorage.setItem('redirectedFrom-To', 'Login-SCW Home');
            this.sharedService.setRedirectionUrl('general-user-home');
          }else if (mainTabName) {
            window.localStorage.setItem('redirectedFrom-To', 'Login-' + mainTabName);
            this.sharedService.setRedirectionUrl(redirection);
          }
        }else{ 
          if (window.location.href.includes('incident-management') && parts[2] && parts[3]) {
            window.localStorage.setItem('selectedSupplierUrl', this.encDecSrvice.set(parts[2]));
            window.localStorage.setItem('selectedSurveyIdUrl', this.encDecSrvice.set(parts[3]));
            window.localStorage.setItem('fromexternalUrl', "true");
            window.localStorage.setItem('redirectedFrom-To', 'Login-Collaboration');
            this.sharedService.setRedirectionUrl("incident-management");
          } else if (url === '/' || url.startsWith('/callback')) {       
            window.localStorage.setItem('redirectedFrom-To', 'Login-SCW Home');
            this.sharedService.setRedirectionUrl('sucm-home/my-view');
          }else if (mainTabName) {
            window.localStorage.setItem('redirectedFrom-To', 'Login-' + mainTabName);
            this.sharedService.setRedirectionUrl(redirection);
          }
        }
      }
      else if (this.loggedInUser.role.value === 'gsm_general_use' && !window.location.href.includes('incident-management')) {
        await this.initializeFilterList();
        window.localStorage.setItem('redirectedFrom-To', 'Login-SCW Home');
        this.sharedService.setRedirectionUrl('general-user-home');
      }
      else if (this.loggedInUser.role.value === 'SCO_scw_general_user_res' && !this.isSocmRestricted && !window.location.href.includes('incident-management')) {
        await this.initializeFilterList();
        window.localStorage.setItem('redirectedFrom-To', 'Login-SCW Home');
        this.sharedService.setRedirectionUrl('general-user-home');
      }
      else if (this.loggedInUser.role.value === 'sucm_res') {
        await this.initializeFilterList();
        let url = this.router.url;
        let redirection = url.substring(1);
        const mainTabName = this.getMainTabNameByUrl(url);
        let parts = url.split('/');
        if (this.isUserRestricted) {
          if (window.location.href.includes('incident-management') && parts[2] && parts[3]) {
            window.localStorage.setItem('selectedSupplierUrl', this.encDecSrvice.set(parts[2]));
            window.localStorage.setItem('selectedSurveyIdUrl', this.encDecSrvice.set(parts[3]));
            window.localStorage.setItem('fromexternalUrl', "true");
            window.localStorage.setItem('redirectedFrom-To', 'Login-Collaboration');
            this.sharedService.setRedirectionUrl("incident-management");
          } else if (url === '/' || url.startsWith('/callback')) {
            window.localStorage.setItem('redirectedFrom-To', 'Login-SCW Home');
            this.sharedService.setRedirectionUrl('general-user-home');
          }else if (mainTabName) {

            window.localStorage.setItem('redirectedFrom-To', 'Login-' + mainTabName);
            this.sharedService.setRedirectionUrl(redirection);
          }
        }
        else {
          if (window.location.href.includes('incident-management') && parts[2] && parts[3]) {
            window.localStorage.setItem('selectedSupplierUrl', this.encDecSrvice.set(parts[2]));
            window.localStorage.setItem('selectedSurveyIdUrl', this.encDecSrvice.set(parts[3]));
            window.localStorage.setItem('fromexternalUrl', "true");
            window.localStorage.setItem('redirectedFrom-To', 'Login-Collaboration');
            this.sharedService.setRedirectionUrl("incident-management");
          } else if (url === '/' || url.startsWith('/callback')) {
            window.localStorage.setItem('redirectedFrom-To', 'Login-SCW Home');
            this.sharedService.setRedirectionUrl('sucm-home/my-view');
          }else if (mainTabName) {

            window.localStorage.setItem('redirectedFrom-To', 'Login-' + mainTabName);
            this.sharedService.setRedirectionUrl(redirection);
          }
        }
      }
      else if (this.loggedInUser.role.value === 'scw_supplier_user_res' && this.loggedInUser.role.value !== 'scw_admin_res') {
        await this.getTools();
        let url = this.router.url;
        let redirection = url.substring(1);
        const mainTabName = this.getMainTabNameByUrl(url);
        let parts = url.split('/');
        if (window.location.href.includes('download') && parts[2] && parts[3] && parts[4]) {
          window.localStorage.setItem('externalDownloadFlag', 'true');
          window.localStorage.setItem('downloadSection', parts[2]);
          window.localStorage.setItem('downloadFlag', parts[3]);
          window.localStorage.setItem('downloadId', parts[4]);

          window.localStorage.setItem('redirectedFrom-To', 'Login-SCW Home');
          this.sharedService.setRedirectionUrl("download");


        } else if (window.location.href.includes('incident-management') && parts[2] && parts[3]) {
          window.localStorage.setItem('selectedSurveyIdUrl', this.encDecSrvice.set(parts[3]));
          window.localStorage.setItem('fromexternalUrlSupplierLogin', "true");

          window.localStorage.setItem('redirectedFrom-To', 'Login-Collaboration');
          this.sharedService.setRedirectionUrl("incident-management");
        } else if (url === '/' || url.startsWith('/callback')) {
          window.localStorage.setItem('isFirstTimeNewsPopupVisited', 'true');
          window.localStorage.setItem('isFirstTimeNewsSectionVisited', 'true');
          window.localStorage.setItem('redirectedFrom-To', 'Login-SCW Home');
          this.sharedService.setRedirectionUrl('supplier-home');
        } else if (window.location.href.includes('supplier-home')) {
          window.localStorage.setItem('isFirstTimeNewsPopupVisited', 'true');
          window.localStorage.setItem('isFirstTimeNewsSectionVisited', 'true');
          window.localStorage.setItem('redirectedFrom-To', 'Login-SCW Home');
          this.sharedService.setRedirectionUrl('supplier-home');
        } else if (mainTabName) {
          window.localStorage.setItem('redirectedFrom-To', 'Login-' + mainTabName);
          this.sharedService.setRedirectionUrl(redirection);
        } else {
          window.localStorage.setItem('isFirstTimeNewsPopupVisited', 'true');
          window.localStorage.setItem('isFirstTimeNewsSectionVisited', 'true');
          window.localStorage.setItem('redirectedFrom-To', 'Login-SCW Home');
          this.sharedService.setRedirectionUrl('supplier-home');
        }

      }
      else {
        if (!window.location.href.includes('incident-management')) {
          this.isLoggedIn = false;
          window.localStorage.clear();
          this.router.navigate(['/no-cepm-role']);
        }
      }
    }
    else {
      this.isLoggedIn = false;
      window.localStorage.clear();
      this.router.navigate(['/no-cepm-role']);
    }
  }

  async checkIfUserIsRestricted() {
    let usersList: any = [];
    if (window.localStorage.getItem('sucmPageAccessUsers')) {
      usersList = JSON.parse(this.encDecSrvice.get(window.localStorage.getItem('sucmPageAccessUsers')));
    }
    else {
      this.loaderService.openLoader();
      const respData: any = await lastValueFrom(this.lookupService.getDropdownLookup('SCW_SUCM_ACCESS_USERS'))
        .catch((err: any) => {
          this.loaderService.closeLoader();
          this.openConfirmationPopup('Error', err.error.errorMsg, false);
        });
      this.loaderService.closeLoader();
      if (respData && respData.lookupArray && respData.lookupArray.length > 0) {
        usersList = respData.lookupArray;
        window.localStorage.setItem('sucmPageAccessUsers', this.encDecSrvice.set(JSON.stringify(respData.lookupArray)));
      }
    }
    if (usersList.length > 0) {
      let matchedUser: any = [];
      matchedUser = usersList.filter((u: any) => u.description === this.loggedInUser.email);
      if (matchedUser.length > 0) {
        this.isUserRestricted = false;
      }
      else {
        this.isUserRestricted = true;
      }
    }
    else {
      this.isUserRestricted = true;
    }
  }

  async checkIfAccessIsRestricted() {
    let usersList: any = [];
    if (window.localStorage.getItem('restrictedAccessUsers')) {
      usersList = JSON.parse(this.encDecSrvice.get(window.localStorage.getItem('restrictedAccessUsers')));
    }
    else {
      this.loaderService.openLoader();
      const respData: any = await lastValueFrom(this.lookupService.getDropdownLookup('SCO_USER_EXTENDED_ACCESS'))
        .catch((err: any) => {
          this.loaderService.closeLoader();
          this.openConfirmationPopup('Error', err.error.errorMsg, false);
        });
      this.loaderService.closeLoader();
      if (respData && respData.lookupArray && respData.lookupArray.length > 0) {
        usersList = respData.lookupArray;
        window.localStorage.setItem('restrictedAccessUsers', this.encDecSrvice.set(JSON.stringify(respData.lookupArray)));
      }
    }
    if (usersList.length > 0) {
      let matchedUser: any = [];
      matchedUser = usersList.filter((u: any) => u.description === this.loggedInUser.email);
      if (matchedUser.length > 0) {
        if (matchedUser[0].enableFlag === 'Y') {
          this.hideSectionsForSocm = false;
          this.isSocmRestricted = false;
        }
        else {
          this.hideSectionsForSocm = true;
          this.isSocmRestricted = true;
        }
      }
      else {
        this.hideSectionsForSocm = true;
        this.isSocmRestricted = false;
      }
    }
    else {
      this.hideSectionsForSocm = true;
      this.isSocmRestricted = false;
    }
    window.localStorage.setItem('hideSectionsForSocm', `${this.hideSectionsForSocm}`);
  }

  async initializeFilterList() {
    this.loaderService.openLoader();
    const filterListResp: any = await lastValueFrom(this.cepmService.fetchAllSearchData())
      .catch((err: any) => {
        this.loaderService.closeLoader();
        this.openConfirmationPopup('Error', err.error.errorMsg, false);
      });
    this.loaderService.closeLoader();
    window.localStorage.setItem('filterListResp', JSON.stringify(filterListResp));
    this.getFilterList();
  }

  getFilterList() {
    this.initializeFilterVariables();
    this.selectedSupplier = this.encDecSrvice.get(window.localStorage.getItem('supplierName'));
    this.isSupplierRestricted = JSON.parse(window.localStorage.getItem('isSupplierRestricted'));
    const selectedSucm: any = window.localStorage.getItem('selectedSucm');
    this.selectedSucm = (selectedSucm && selectedSucm.length) > 0 ? JSON.parse(window.localStorage.getItem('selectedSucm')) : [];
    const selectedCommodity: any = window.localStorage.getItem('selectedCommodities');
    this.selectedCommodity = (selectedCommodity && selectedCommodity.length > 0) ? JSON.parse(window.localStorage.getItem('selectedCommodities')) : [];
    if (this.selectedSupplier !== 'ALL') {
      this.supplierList = JSON.parse(window.localStorage.getItem('supplierList'));
      this.commodityList = JSON.parse(window.localStorage.getItem('commodityList'));
      this.sucmList = JSON.parse(window.localStorage.getItem('sucmList'));
    }
    else {
      const filterListResp: any = JSON.parse(window.localStorage.getItem('filterListResp'));
      this.supplierList = filterListResp.supplier;
      this.commodityList = filterListResp.commodity;
      this.sucmList = filterListResp.sucm;
      window.localStorage.setItem('supplierList', JSON.stringify(this.supplierList));
      window.localStorage.setItem('commodityList', JSON.stringify(this.commodityList));
      window.localStorage.setItem('sucmList', JSON.stringify(this.sucmList));
    }
    this.isAllCommoditiesSelected = this.selectedCommodity.length === this.commodityList.length ? true : false;
    this.isAllSucmSelected = this.selectedSucm.length === this.sucmList.length ? true : false;
  }

  async initializeTabs() {
    //sucm
    //  if(this.loggedInUser.role.value === 'SCO_scw_general_user_res' || this.loggedInUser.role.value === 'sucm_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser?.role?.value === 'gsm_general_use' || this.loggedInUser?.role?.value === 'scw_admin_res'){
    //   this.mainTabs=[
    //     {
    //       mainTabName: 'AdminUser',
    //       mainTabUrl: '/sucm-home/overall-view',
    //       labelName: 'Home',
    //       isMainTabVisible: this.loggedInUser.role.value === 'scw_admin_res' && this.selectedSupplier === 'ALL',
    //       mainTabTooltip: '',
    //       tabGroups: [          
    //       ]
          
    //     },
    //     {
    //       mainTabName: 'AdminUser Supplier',
    //       mainTabUrl: '',
    //       labelName: 'Home',
    //       isMainTabVisible: this.loggedInUser.role.value === 'scw_admin_res' && this.selectedSupplier !== 'ALL',
    //       mainTabTooltip: '',
    //       tabGroups: [   
    //         {
    //           tabGroupName: '',
    //           tabGroupUrl: '',
    //           isTabGroupVisible: this.selectedSupplier !== 'ALL',
    //           tabGroupTooltip: '',
    //           subTabs: [
    //             {
    //               subTabName: 'Home',
    //               subTabUrl: '/sucm-home/overall-view',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.selectedSupplier !== 'ALL',
    //             },
    //             {
    //               subTabName: 'Supplier Home',
    //               subTabUrl: '/supplier-new-home',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.selectedSupplier !== 'ALL',
    //             }
    //           ]
    //         }       
    //       ]
          
    //     },
    //     {
    //       mainTabName: 'Sucm',
    //       mainTabUrl: this.isUserRestricted?'/general-user-home':'/sucm-home/my-view',
    //       labelName: 'Home',
    //       isMainTabVisible: this.loggedInUser.role.value === 'sucm_res' && this.selectedSupplier === 'ALL',
    //       mainTabTooltip: '',
    //       tabGroups: [          
    //       ]
          
    //     },

    //     {
    //       mainTabName: 'Sucm Supplier',
    //       mainTabUrl: '',
    //       labelName: 'Home',
    //       isMainTabVisible: this.loggedInUser.role.value === 'sucm_res' && this.selectedSupplier !== 'ALL',
    //       mainTabTooltip: '',
    //       tabGroups: [   
    //         {
    //           tabGroupName: '',
    //           tabGroupUrl: '',
    //           isTabGroupVisible: this.selectedSupplier !== 'ALL',
    //           tabGroupTooltip: '',
    //           subTabs: [
    //             {
    //               subTabName: 'Home',
    //               subTabUrl: this.isUserRestricted?'/general-user-home':'/sucm-home/my-view',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.selectedSupplier !== 'ALL',
    //             },
    //             {
    //               subTabName: 'Supplier Home',
    //               subTabUrl: '/supplier-new-home',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.selectedSupplier !== 'ALL',
    //             }
    //           ]
    //         }       
    //       ]
          
    //     },

    //     {
    //       mainTabName: 'Socm',
    //       labelName: 'Home',
    //       mainTabUrl: this.isUserRestricted?'/general-user-home':'/sucm-home/my-view',
    //       isMainTabVisible: this.loggedInUser.role.value === 'socm_res' && this.selectedSupplier === 'ALL',
    //       mainTabTooltip: '',
    //       tabGroups: [          
    //       ]
          
    //     },

    //     {
    //       mainTabName: 'Socm Supplier',
    //       mainTabUrl: '',
    //       labelName: 'Home',
    //       isMainTabVisible: this.loggedInUser.role.value === 'socm_res' && this.selectedSupplier !== 'ALL',
    //       mainTabTooltip: '',
    //       tabGroups: [   
    //         {
    //           tabGroupName: '',
    //           tabGroupUrl: '',
    //           isTabGroupVisible: this.selectedSupplier !== 'ALL',
    //           tabGroupTooltip: '',
    //           subTabs: [
    //             {
    //               subTabName: 'Home',
    //               subTabUrl: this.isUserRestricted?'/general-user-home':'/sucm-home/my-view',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.selectedSupplier !== 'ALL',
    //             },
    //             {
    //               subTabName: 'Supplier Home',
    //               subTabUrl: '/supplier-new-home',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.selectedSupplier !== 'ALL',
    //             }
    //           ]
    //         }       
    //       ]
          
    //     },
    //     {
    //       mainTabName: 'Director',
    //       labelName: 'Home',
    //       mainTabUrl: this.isUserRestricted?'/general-user-home':'/sucm-home/my-view',
    //       isMainTabVisible: this.loggedInUser.role.value === 'comm_dir_res' && this.selectedSupplier === 'ALL',
    //       mainTabTooltip: '',
    //       tabGroups: [          
    //       ]
          
    //     },

    //     {
    //       mainTabName: 'Director Supplier',
    //       mainTabUrl: '',
    //       labelName: 'Home',
    //       isMainTabVisible: this.loggedInUser.role.value === 'comm_dir_res' && this.selectedSupplier !== 'ALL',
    //       mainTabTooltip: '',
    //       tabGroups: [   
    //         {
    //           tabGroupName: '',
    //           tabGroupUrl: '',
    //           isTabGroupVisible: this.selectedSupplier !== 'ALL',
    //           tabGroupTooltip: '',
    //           subTabs: [
    //             {
    //               subTabName: 'Home',
    //               subTabUrl: this.isUserRestricted?'/general-user-home':'/sucm-home/my-view',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.selectedSupplier !== 'ALL',
    //             },
    //             {
    //               subTabName: 'Supplier Home',
    //               subTabUrl: '/supplier-new-home',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.selectedSupplier !== 'ALL',
    //             }
    //           ]
    //         }       
    //       ]
          
    //     },

    //     {
    //       mainTabName: 'Sco',
    //       labelName: 'Home',
    //       mainTabUrl:'/general-user-home',
    //       isMainTabVisible: this.loggedInUser.role.value === 'SCO_scw_general_user_res' && this.selectedSupplier === 'ALL',
    //       mainTabTooltip: '',
    //       tabGroups: [          
    //       ]
          
    //     },

    //     {
    //       mainTabName: 'Sco Supplier',
    //       mainTabUrl: '',
    //       labelName: 'Home',
    //       isMainTabVisible: this.loggedInUser.role.value === 'SCO_scw_general_user_res' && this.selectedSupplier !== 'ALL',
    //       mainTabTooltip: '',
    //       tabGroups: [   
    //         {
    //           tabGroupName: '',
    //           tabGroupUrl: '',
    //           isTabGroupVisible: this.selectedSupplier !== 'ALL',
    //           tabGroupTooltip: '',
    //           subTabs: [
    //             {
    //               subTabName: 'Home',
    //               subTabUrl:'/general-user-home',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.selectedSupplier !== 'ALL',
    //             },
    //             {
    //               subTabName: 'Supplier Home',
    //               subTabUrl: '/supplier-new-home',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.selectedSupplier !== 'ALL',
    //             }
    //           ]
    //         }       
    //       ]
          
    //     },
    //     {
    //       mainTabName: 'General',
    //       labelName: 'Home',
    //       mainTabUrl:'/general-user-home',
    //       isMainTabVisible: this.loggedInUser.role.value === 'gsm_general_use' && this.selectedSupplier === 'ALL',
    //       mainTabTooltip: '',
    //       tabGroups: [          
    //       ]
          
    //     },

    //     {
    //       mainTabName: 'General Supplier',
    //       mainTabUrl: '',
    //       labelName: 'Home',
    //       isMainTabVisible: this.loggedInUser.role.value === 'gsm_general_use' && this.selectedSupplier !== 'ALL',
    //       mainTabTooltip: '',
    //       tabGroups: [   
    //         {
    //           tabGroupName: '',
    //           tabGroupUrl: '',
    //           isTabGroupVisible: this.selectedSupplier !== 'ALL',
    //           tabGroupTooltip: '',
    //           subTabs: [
    //             {
    //               subTabName: 'Home',
    //               subTabUrl:'/general-user-home',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.selectedSupplier !== 'ALL',
    //             },
    //             {
    //               subTabName: 'Supplier Home',
    //               subTabUrl: '/supplier-new-home',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.selectedSupplier !== 'ALL',
    //             }
    //           ]
    //         }       
    //       ]
          
    //     },

    //     {
    //       mainTabName: (this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res' ) && this.selectedSupplier === 'ALL' ? 'Collaboration' : 'Admin',
    //       mainTabUrl: '',
    //       labelName: this.selectedSupplier === 'ALL' ? 'Collaboration' : 'Admin',
    //       isMainTabVisible: (this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res' ) && this.selectedSupplier === 'ALL',
    //       mainTabTooltip: '',
    //       tabGroups: [{
    //         tabGroupName: ' ',
    //         tabGroupUrl: '',
    //         isTabGroupVisible: this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res' ,
    //         tabGroupTooltip: '',
    //         subTabs: [               
             
    //           {
    //             subTabName: 'Contact Management',
    //             subTabUrl: '/contact-management',
    //             subTabTooltip: '',
    //             isSubTabVisible: this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res',
    //           },
              
    //           {
    //             subTabName: 'Documents',
    //             subTabUrl: '/documents/cisco-documents',
    //             subTabTooltip: '',
    //             isSubTabVisible: this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res',
    //           },
    //           {
    //             subTabName: 'Trainings',
    //             subTabUrl: '/documents/training-documents',
    //             subTabTooltip: '',
    //             isSubTabVisible: this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res',
    //           },
    //           {
    //             subTabName: 'Questionnaire',
    //             subTabUrl: '/questionnaire',
    //             subTabTooltip: '',
    //             isSubTabVisible: this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res',
    //           },
    //           {
    //             subTabName: 'Incident Tracker',
    //             subTabUrl: '/incident-management',
    //             subTabTooltip: '',
    //             isSubTabVisible: this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res',
              
    //           },
    //           {
    //             subTabName: 'Supplier Whitepaper',
    //             subTabUrl: '/supplier-whitepaper',
    //             subTabTooltip: '',
    //             isSubTabVisible: (this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res') && this.isWhitepaperAccess,
    //           },
    //           {
    //             subTabName: 'Events Calendar',
    //             subTabUrl: '/events/cisco-events',
    //             subTabTooltip: '',
    //             isSubTabVisible: this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res',
    //           },
    //           {
    //             subTabName: 'SC Apps',
    //             subTabUrl: '/tools',
    //             subTabTooltip: '',
    //             isSubTabVisible: this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res',
    //           },
    //           {
    //             subTabName: 'Parent Supplier Mapping',
    //             subTabUrl: '/parent-mapping',
    //             subTabTooltip: '',
    //             isSubTabVisible:this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res',
    //           },
              

    //         ]
    //       }
    //     ]
    //   },

        
    //     {
    //       mainTabName: (this.loggedInUser.role.value !== 'gsm_general_use' || this.loggedInUser.role.value !== 'SCO_scw_general_user_res' ) && this.selectedSupplier === 'ALL' ? 'Collaboration' : 'Admin2',
    //       mainTabUrl: '',
    //       labelName: (this.selectedSupplier === 'ALL' && this.loggedInUser?.role?.value !== 'scw_admin_res') ? 'Collaboration' : 'Admin',
    //       isMainTabVisible: (this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res') && this.selectedSupplier === 'ALL',
    //       mainTabTooltip: '',
    //       tabGroups: [
    //         {
    //           tabGroupName: ' ',
    //           tabGroupUrl: '',
    //           isTabGroupVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res',
    //           tabGroupTooltip: '',
    //           subTabs: [
    //             {
    //               subTabName: 'SuCM Overview',
    //               subTabUrl: '/sucm-home/overall-view',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.loggedInUser.role.value === 'scw_admin_res' || this.loggedInUser.role.value === 'socm_res'
    //                 || this.loggedInUser.role.value === 'sucm_res' || this.loggedInUser.role.value === 'comm_dir_res',
    //             },
               
    //             {
    //               subTabName: 'Contact Management',
    //               subTabUrl: '/contact-management',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res',
    //             },
    //             {
    //               subTabName: 'Incident Tracker',
    //               subTabUrl: '/incident-management',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res',
                
    //             },
    //             {
    //               subTabName: 'Questionnaire',
    //               subTabUrl: '/questionnaire',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res',
    //             },
    //             {
    //               subTabName: 'Supplier Whitepaper',
    //               subTabUrl: '/supplier-whitepaper',
    //               subTabTooltip: '',
    //               isSubTabVisible: (this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res') && this.isWhitepaperAccess,
    //             },
    //             {
    //               subTabName: 'SC Apps',
    //               subTabUrl: '/tools',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res',
    //             },
    //             // {
    //             //   subTabName: 'Watchlist',
    //             //   subTabUrl: '/sucm-home/watchlist',
    //             //   subTabTooltip: '',
    //             //   isSubTabVisible:  this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'comm_dir_res',
    //             // }

    //           ]
    //         },
    //         {
    //           tabGroupName: '  ',
    //           tabGroupUrl: '',
    //           isTabGroupVisible: this.loggedInUser?.role?.value === 'scw_admin_res' ||this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res',
    //           tabGroupTooltip: '',
    //           subTabs: [
               
                
    //             {
    //               subTabName: 'Messages',
    //               subTabUrl: '/messages',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res',
    //             },
    //             {
    //               subTabName: 'Documents',
    //               subTabUrl: '/documents/cisco-documents',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res',
    //             },
    //             {
    //               subTabName: 'Trainings',
    //               subTabUrl: '/documents/training-documents',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res',
    //             },
    //             {
    //               subTabName: 'Events Calendar',
    //               subTabUrl: '/events/cisco-events',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res',
    //             },
    //             {
    //               subTabName: 'Dashboards',
    //               subTabUrl: '/dashboard',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res',
    //             },
    //             {
    //               subTabName: 'Wizards',
    //               subTabUrl: '/wizards',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res',
    //             },
    //             // {
    //             //   subTabName: 'Watchlist',
    //             //   subTabUrl: '/sucm-home/watchlist',
    //             //   subTabTooltip: '',
    //             //   isSubTabVisible: this.loggedInUser.role.value === 'sucm_res',
    //             // },
    //             // {
    //             //   subTabName: 'Adoption Metrics',
    //             //   subTabUrl: '/adoption-metrics',
    //             //   subTabTooltip: '',
    //             //   isSubTabVisible: this.loggedInUser.role.value === 'sucm_res',
    //             // },

    //           ]
    //         },
    //         {
    //           tabGroupName: '   ',
    //           tabGroupUrl: '',
    //           isTabGroupVisible: this.loggedInUser?.role?.value === 'scw_admin_res' ||this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res',
    //           tabGroupTooltip: '',
    //           subTabs: [
               
                
    //             {
    //               subTabName: 'Adoption Metrics',
    //               subTabUrl: '/adoption-metrics',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res',
    //             },
    //             {
    //               subTabName: 'Data Refresh Status',
    //               subTabUrl: '/data-refresh',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res',
    //             },
    //             {
    //             subTabName: 'Manage Metrics',
    //             subTabUrl: '/manage-metrics',
    //             subTabTooltip: '',
    //             isSubTabVisible: this.loggedInUser.role.value === 'scw_admin_res'
    //           },
    //           {
    //             subTabName: 'Lookup',
    //             subTabUrl: '/lookup',
    //             subTabTooltip: '',
    //             isSubTabVisible: this.loggedInUser.role.value === 'scw_admin_res'
    //           },
    //             // {
    //             //   subTabName: 'Watchlist',
    //             //   subTabUrl: '/sucm-home/watchlist',
    //             //   subTabTooltip: '',
    //             //   isSubTabVisible: this.loggedInUser?.role?.value === 'scw_admin_res',
    //             // },
    //             {
    //               subTabName: 'Parent Supplier Mapping',
    //               subTabUrl: '/parent-mapping',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.loggedInUser.role.value === 'scw_admin_res'||this.loggedInUser.role.value === 'sucm_res'|| this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res',
    //             },
                
                
    //           ]
    //         },
           

           
    //       ]
    //     },
    //     {
    //       mainTabName: 'Performance',
    //       labelName: 'Performance',
    //       mainTabUrl: '',
    //       isMainTabVisible: (this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res') && this.selectedSupplier !== 'ALL',
    //       mainTabTooltip: '',
    //       tabGroups: [
    //         {
    //           tabGroupName: 'SBR/RESILIENCY',
    //           tabGroupTooltip: '',
    //           tabGroupUrl: '',
    //           isTabGroupVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res' ||this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res' && this.selectedSupplier !== 'ALL',
    //           subTabs: [
    //             {
    //               subTabName: 'SBR Scores',
    //               subTabUrl: '/sbr-score-card',
    //               isSubTabVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res' ||this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res' && this.selectedSupplier !== 'ALL',
    //               subTabTooltip: ''
    //             },
    //             {
    //               subTabName: 'Resiliency Scores',
    //               subTabUrl: '/resiliency-score-card',
    //               isSubTabVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res' && this.selectedSupplier !== 'ALL',
    //               subTabTooltip: ''
    //             },
    //             {
    //               subTabName: 'ESG (Sustainability) Metrics',
    //               subTabUrl: '/supplier-sustainability-score',
    //               isSubTabVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res' && this.selectedSupplier !== 'ALL',
    //               subTabTooltip: ''
    //             },
    //             {
    //               subTabName: 'FHR : Financial Health Rating',
    //               subTabUrl: '/fhr-detailed-view',
    //               isSubTabVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res' && this.selectedSupplier !== 'ALL',
    //               subTabTooltip: ''
    //             }
    //           ]
    //         },
    //         {
    //           tabGroupName: 'QUALITY',
    //           tabGroupTooltip: '',
    //           tabGroupUrl: '',
    //           isTabGroupVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res' && this.selectedSupplier !== 'ALL',
    //           subTabs: [
    //             {
    //               subTabName: 'QP',
    //               subTabUrl: '/quality/qp-qr-by-commodity',
    //               isSubTabVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res' && this.selectedSupplier !== 'ALL',
    //               subTabTooltip: ''
    //             },
    //             {
    //               subTabName: 'QR',
    //               subTabUrl: '/quality/qp-qr-by-commodity',
    //               isSubTabVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res' && this.selectedSupplier !== 'ALL',
    //               subTabTooltip: ''
    //             },
    //             // {
    //             //   subTabName: 'DPPM Metrics',
    //             //   subTabUrl: '/quality/dppm-metrics',
    //             //   isSubTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
    //             //   subTabTooltip: ''
    //             // },
    //             {
    //               subTabName: 'Quality Cases',
    //               subTabUrl: '/quality/quality-cases',
    //               isSubTabVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res' && this.selectedSupplier !== 'ALL',
    //               subTabTooltip: ''
    //             }
    //           ]
    //         },
    //         {
    //           tabGroupName: 'FULFILLMENT',
    //           tabGroupTooltip: '',
    //           tabGroupUrl: '',
    //           isTabGroupVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res' && this.selectedSupplier !== 'ALL',
    //           subTabs: [
    //             {
    //               subTabName: 'OTC',
    //               subTabUrl: '/fulfillment/ct2r-and-otc',
    //               isSubTabVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res' && this.selectedSupplier !== 'ALL',
    //               subTabTooltip: ''
    //             },
    //             {
    //               subTabName: 'Del to CT2R',
    //               subTabUrl: '/fulfillment/ct2r-and-otc',
    //               isSubTabVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res' && this.selectedSupplier !== 'ALL',
    //               subTabTooltip: ''
    //             },
    //             {
    //               subTabName: 'Agility',
    //               subTabUrl: '/fulfillment/agility',
    //               isSubTabVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res' && this.selectedSupplier !== 'ALL',
    //               subTabTooltip: ''
    //             }
    //           ]
    //         },
    //         {
    //           tabGroupName: 'SOURCING METRICS',
    //           tabGroupTooltip: '',
    //           tabGroupUrl: '',
    //           isTabGroupVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res' && this.selectedSupplier !== 'ALL',
    //           subTabs: [
    //             {
    //               subTabName: 'BAAO Metrics',
    //               subTabUrl: '/baao-metrics',
    //               isSubTabVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res' && this.selectedSupplier !== 'ALL',
    //               subTabTooltip: ''
    //             }
    //           ]
    //         },
    //         {
    //           tabGroupName: 'PERFORMANCE SCORECARD',
    //           tabGroupTooltip: '',
    //           tabGroupUrl: '',
    //           isTabGroupVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res' && this.selectedSupplier !== 'ALL',
    //           subTabs: [
    //             {
    //               subTabName: 'PERFORMANCE SCORECARD',
    //               subTabUrl: '/supplier-home',
    //               isSubTabVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res' && this.selectedSupplier !== 'ALL',
    //               subTabTooltip: 'PERFORMANCE SCORECARD'
    //             }
    //           ]
    //         },
    //       ]
    //     },
    //     {
    //       mainTabName: 'Collaboration',
    //       labelName: 'Collaboration',
    //       mainTabUrl: '',
    //       isMainTabVisible: (this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res') && this.selectedSupplier !== 'ALL',
    //       mainTabTooltip: '',
    //       tabGroups: [
    //         {
    //           tabGroupName: 'Questionnaire',
    //           tabGroupUrl: '',
    //           isTabGroupVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res' && this.selectedSupplier !== 'ALL',
    //           tabGroupTooltip: '',
    //           subTabs: [           
    //             {
    //               subTabName: 'QUESTIONNAIRE',
    //               subTabUrl: '/questionnaire',
    //               isSubTabVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res' && this.selectedSupplier !== 'ALL',
    //               subTabTooltip: ''
    //             }
    //           ]
    //         },
    //         {
    //           tabGroupName: 'Incident Tracker',
    //           tabGroupUrl: '',
    //           isTabGroupVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res' && this.selectedSupplier !== 'ALL',
    //           tabGroupTooltip: '',
    //           subTabs: [
    //             {
    //               subTabName: 'INCIDENT TRACKER',
    //               subTabUrl: '/incident-management',
    //               isSubTabVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res' && this.selectedSupplier !== 'ALL',
    //               subTabTooltip: ''
    //             }
    //           ]
    //         },
    //         {
    //           tabGroupName: 'Contacts',
    //           tabGroupUrl: '',
    //           isTabGroupVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res' && this.selectedSupplier !== 'ALL',
    //           tabGroupTooltip: '',
    //           subTabs: [
    //             {
    //               subTabName: 'CONTACTS',
    //               subTabUrl: '/contact-management',
    //               isSubTabVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res' && this.selectedSupplier !== 'ALL',
    //               subTabTooltip: ''
    //             },
    //             {
    //               subTabName: 'My Company',
    //               subTabUrl: '/supplier-profile',
    //               subTabTooltip: '',
    //               isSubTabVisible: false
    //             }
              
    //           ]
    //         },
    //         {
    //           tabGroupName: 'Messages',
    //           tabGroupUrl: '',
    //           isTabGroupVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res' && this.selectedSupplier !== 'ALL',
    //           tabGroupTooltip: '',
    //           subTabs: [
    //             {
    //               subTabName: 'MESSAGES',
    //               subTabUrl: '/messages',
    //               isSubTabVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res' && this.selectedSupplier !== 'ALL',
    //               subTabTooltip: ''
    //             }
    //           ]
    //         },
            
    //         {
    //           tabGroupName: 'DOCUMENTS',
    //           tabGroupUrl: '',
    //           isTabGroupVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res' && this.selectedSupplier !== 'ALL',
    //           tabGroupTooltip: '',
    //           subTabs: [
    //             {
    //               subTabName: 'Cisco Documents',
    //               subTabUrl: '/documents/cisco-documents',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res' && this.selectedSupplier !== 'ALL',
    //             },
    //             {
    //               subTabName: 'Supplier Documents',
    //               subTabUrl: '/documents/supplier-documents',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res' && this.selectedSupplier !== 'ALL',
    //             }
    //           ]
    //         },
    //         {
    //           tabGroupName: 'EVENTS',
    //           tabGroupUrl: '',
    //           isTabGroupVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res' && this.selectedSupplier !== 'ALL',
    //           tabGroupTooltip: '',
    //           subTabs: [
    //             {
    //               subTabName: 'Cisco Events',
    //               subTabUrl: '/events/cisco-events',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res' && this.selectedSupplier !== 'ALL',
    //             },
    //             {
    //               subTabName: 'Supplier Events',
    //               subTabUrl: '/events/suppliers-events',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res' && this.selectedSupplier !== 'ALL',
    //             },
    //             {
    //               subTabName: 'Event Timeline Calendar',
    //               subTabUrl: '/events/event-timeline',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res' && this.selectedSupplier !== 'ALL',
    //             },
    //           ]
    //         }
    //       ]
    //     },
    //     {
    //       mainTabName: 'SC Apps',
    //       labelName: 'SC Apps',
    //       mainTabUrl: '',
    //       isMainTabVisible: (this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res') && this.selectedSupplier !== 'ALL',
    //       mainTabTooltip: '',
    //       tabGroups: []
    //     },
    //     {
    //       mainTabName: 'Training',
    //       labelName: 'Trainings',
    //       mainTabUrl: '',
    //       isMainTabVisible: (this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res') && this.selectedSupplier !== 'ALL',
    //       mainTabTooltip: '',
    //       tabGroups: [
    //         {
    //           tabGroupName: 'Trainings',
    //           tabGroupUrl: '',
    //           isTabGroupVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res' && this.selectedSupplier !== 'ALL',
    //           tabGroupTooltip: '',
    //           subTabs: [
    //             {
    //               subTabName: 'Trainings',
    //               subTabUrl: '/documents/training-documents',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res' && this.selectedSupplier !== 'ALL',
    //             },
               
    //             {
    //               subTabName: 'FAQs',
    //               subTabUrl: '/faq',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res' && this.selectedSupplier !== 'ALL',
    //             },
    //             {
    //               subTabName: 'About SCW',
    //               subTabUrl: '/about-us',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res' && this.selectedSupplier !== 'ALL',
    //             },
    //           ]
    //         }
    //       ]
    //     },
    //     {
    //       mainTabName: 'Report Issues',
    //       labelName: 'Report Issues',
    //       mainTabUrl: '/report-issues',
    //       isMainTabVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res',
    //       mainTabTooltip: '',
    //       tabGroups: [          
    //       ]
          
    //     },
    //     {
    //       mainTabName: 'Contact Us',
    //       labelName: 'Contact Us',
    //       mainTabUrl: '/winodw-open',
    //       isMainTabVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res',
    //       mainTabTooltip: '',
    //       tabGroups: [
            
    //       ]
    //     }, 
        
    //     {
    //       mainTabName: ((this.loggedInUser.role.value !== 'gsm_general_use' || this.loggedInUser.role.value !== 'SCO_scw_general_user_res' )) && this.selectedSupplier === 'ALL' ? 'Collaboration' : 'Admin3',
    //       mainTabUrl: '',
    //       labelName: this.selectedSupplier === 'ALL' ? 'Collaboration' : 'Admin',
    //       isMainTabVisible: (this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res') && this.selectedSupplier !== 'ALL',
    //       mainTabTooltip: '',
    //       tabGroups: [
    //         {
    //           tabGroupName: ' ',
    //           tabGroupUrl: '',
    //           isTabGroupVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res',
    //           tabGroupTooltip: '',
    //           subTabs: [
    //             {
    //               subTabName: 'SuCM Overview',
    //               subTabUrl: '/sucm-home/overall-view',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.loggedInUser.role.value === 'scw_admin_res' || this.loggedInUser.role.value === 'socm_res'
    //                 || this.loggedInUser.role.value === 'sucm_res' || this.loggedInUser.role.value === 'comm_dir_res',
    //             },
               
    //             {
    //               subTabName: 'Contact Management',
    //               subTabUrl: '/contact-management',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res',
    //             },
    //             {
    //               subTabName: 'Incident Tracker',
    //               subTabUrl: '/incident-management',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res',
                
    //             },
    //             {
    //               subTabName: 'Questionnaire',
    //               subTabUrl: '/questionnaire',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res',
    //             },
    //             {
    //               subTabName: 'Supplier Whitepaper',
    //               subTabUrl: '/supplier-whitepaper',
    //               subTabTooltip: '',
    //               isSubTabVisible: (this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res') && this.isWhitepaperAccess,
    //             },
    //             {
    //               subTabName: 'SC Apps',
    //               subTabUrl: '/tools',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res',
    //             },
    //             {
    //               subTabName: 'Watchlist',
    //               subTabUrl: '/sucm-home/watchlist',
    //               subTabTooltip: '',
    //               isSubTabVisible:  this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'comm_dir_res',
    //             },

    //           ]
    //         },
    //         {
    //           tabGroupName: '  ',
    //           tabGroupUrl: '',
    //           isTabGroupVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res',
    //           tabGroupTooltip: '',
    //           subTabs: [
               
                
    //             {
    //               subTabName: 'Messages',
    //               subTabUrl: '/messages',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res',
    //             },
    //             {
    //               subTabName: 'Documents',
    //               subTabUrl: '/documents/cisco-documents',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res',
    //             },
    //             {
    //               subTabName: 'Trainings',
    //               subTabUrl: '/documents/training-documents',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res',
    //             },
    //             {
    //               subTabName: 'Events Calendar',
    //               subTabUrl: '/events/cisco-events',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res',
    //             },
    //             {
    //               subTabName: 'Dashboards',
    //               subTabUrl: '/dashboard',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res',
    //             },
    //             {
    //               subTabName: 'Wizards',
    //               subTabUrl: '/wizards',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res',
    //             },
    //             // {
    //             //   subTabName: 'Watchlist',
    //             //   subTabUrl: '/sucm-home/watchlist',
    //             //   subTabTooltip: '',
    //             //   isSubTabVisible: this.loggedInUser.role.value === 'sucm_res',
    //             // },
    //             // {
    //             //   subTabName: 'Adoption Metrics',
    //             //   subTabUrl: '/adoption-metrics',
    //             //   subTabTooltip: '',
    //             //   isSubTabVisible: this.loggedInUser.role.value === 'sucm_res',
    //             // },

    //           ]
    //         },
    //         {
    //           tabGroupName: '   ',
    //           tabGroupUrl: '',
    //           isTabGroupVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res',
    //           tabGroupTooltip: '',
    //           subTabs: [
               
                
    //             {
    //               subTabName: 'Adoption Metrics',
    //               subTabUrl: '/adoption-metrics',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res',
    //             },
    //             {
    //               subTabName: 'Data Refresh Status',
    //               subTabUrl: '/data-refresh',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res',
    //             },
    //             {
    //               subTabName: 'Manage Metrics',
    //               subTabUrl: '/manage-metrics',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.loggedInUser.role.value === 'scw_admin_res'
    //             },
    //             {
    //               subTabName: 'Lookup',
    //               subTabUrl: '/lookup',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.loggedInUser.role.value === 'scw_admin_res'
    //             },
    //             {
    //               subTabName: 'Watchlist',
    //               subTabUrl: '/sucm-home/watchlist',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'sucm_res',
    //             },
    //             {
    //               subTabName: 'Parent Supplier Mapping',
    //               subTabUrl: '/parent-mapping',
    //               subTabTooltip: '',
    //               isSubTabVisible:this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res',
    //             },             
                
    //           ]
    //         },
           

           
    //       ]
    //     },
    //     {
    //       mainTabName: (this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res' ) && this.selectedSupplier === 'ALL' ? 'Collaboration' : 'Admin4',
    //       mainTabUrl: '',
    //       labelName: this.selectedSupplier === 'ALL' ? 'Collaboration' : 'Admin',
    //       isMainTabVisible: (this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res') && this.selectedSupplier !== 'ALL',
    //       mainTabTooltip: '',
    //       tabGroups: [
    //         {
    //           tabGroupName: ' ',
    //           tabGroupUrl: '',
    //           isTabGroupVisible: this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res' ,
    //           tabGroupTooltip: '',
    //           subTabs: [
                
               
    //             {
    //               subTabName: 'Contact Management',
    //               subTabUrl: '/contact-management',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res',
    //             },
                
    //             {
    //               subTabName: 'Documents',
    //               subTabUrl: '/documents/cisco-documents',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res',
    //             },
    //             {
    //               subTabName: 'Trainings',
    //               subTabUrl: '/documents/training-documents',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res',
    //             },
    //             {
    //               subTabName: 'Questionnaire',
    //               subTabUrl: '/questionnaire',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res',
    //             },
    //             {
    //               subTabName: 'Incident Tracker',
    //               subTabUrl: '/incident-management',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res',
                
    //             },
    //             {
    //               subTabName: 'Supplier Whitepaper',
    //               subTabUrl: '/supplier-whitepaper',
    //               subTabTooltip: '',
    //               isSubTabVisible: (this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res') && this.isWhitepaperAccess,
    //             },
    //             {
    //               subTabName: 'Events Calendar',
    //               subTabUrl: '/events/cisco-events',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res',
    //             },
    //             {
    //               subTabName: 'SC Apps',
    //               subTabUrl: '/tools',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res',
    //             },
    //             {
    //               subTabName: 'Parent Supplier Mapping',
    //               subTabUrl: '/parent-mapping',
    //               subTabTooltip: '',
    //               isSubTabVisible:this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res',
    //             },
                

    //           ]
    //         }
    //       ]
    //     },
    //     {
    //       mainTabName: 'FAQsGeneral',
    //       labelName: 'FAQs',
    //       mainTabUrl: '/faq',
    //       isMainTabVisible: this.loggedInUser.role.value === 'gsm_general_use' ,
    //       mainTabTooltip: '',
    //       tabGroups: [
            
    //       ]
    //     },
    //     {
    //       mainTabName: 'Favorites',
    //       labelName: 'Favorites',
    //       mainTabUrl: '',
    //       mainTabTooltip: '',
    //       isMainTabVisible: this.loggedInUser?.role?.value !== 'scw_supplier_user_res',
    //       tabGroups: [
    //         {
    //           tabGroupName: '',
    //           tabGroupTooltip: '',
    //           tabGroupUrl: '',
    //           isTabGroupVisible: true,
    //           subTabs: []
    //         }
    //       ]
    //     },
    //     {
    //       mainTabName: 'Watchlist',
    //       labelName: 'Watchlist',
    //       mainTabUrl: '/sucm-home/watchlist',
    //       mainTabTooltip: '',
    //       isMainTabVisible: (this.loggedInUser.role.value === 'sucm_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser?.role?.value === 'scw_admin_res')&& this.selectedSupplier === 'ALL',
    //       tabGroups: [
            
    //       ]
    //     },
    //     {
    //       mainTabName: 'Search Supplier',
    //       labelName: 'Search Supplier',
    //       mainTabUrl: '/search-supplier',
    //       isMainTabVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res',
    //       mainTabTooltip: '',
    //       tabGroups: [
            
    //       ]
    //     }, 

    //   ];
    //   this.breadcrumbs = this.generateBreadcrumbs(this.mainTabs);


    //  }
    //  else if(this.loggedInUser.role.value === 'scw_supplier_user_res'){
    //   this.mainTabs = [
    //     {
    //       mainTabName: 'Home',
    //       labelName: 'Home',
    //       mainTabUrl: '/supplier-new-home',
    //       isMainTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res',
    //       mainTabTooltip: '',
    //       tabGroups: [

    //       ]
    //     },

        
    //     {
    //       mainTabName: 'Performance',
    //       labelName: 'Performance',
    //       mainTabUrl: '',
    //       isMainTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' ||
    //         (((this.loggedInUser.role.value === 'SCO_scw_general_user_res' && !this.hideSectionsForSocm)
    //           || this.loggedInUser.role.value !== 'SCO_scw_general_user_res') && this.selectedSupplier !== 'ALL'),
    //       mainTabTooltip: '',
    //       tabGroups: [
    //         {
    //           tabGroupName: 'SBR/RESILIENCY',
    //           tabGroupTooltip: '',
    //           tabGroupUrl: '',
    //           isTabGroupVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
    //           subTabs: [
    //             {
    //               subTabName: 'SBR Scores',
    //               subTabUrl: '/sbr-score-card',
    //               isSubTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
    //               subTabTooltip: ''
    //             },
    //             {
    //               subTabName: 'Resiliency Scores',
    //               subTabUrl: '/resiliency-score-card',
    //               isSubTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
    //               subTabTooltip: ''
    //             },
    //             {
    //               subTabName: 'ESG (Sustainability) Metrics',
    //               subTabUrl: '/supplier-sustainability-score',
    //               isSubTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
    //               subTabTooltip: ''
    //             },
    //             {
    //               subTabName: 'FHR : Financial Health Rating',
    //               subTabUrl: '/fhr-detailed-view',
    //               isSubTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
    //               subTabTooltip: ''
    //             }
    //           ]
    //         },
    //         {
    //           tabGroupName: 'QUALITY',
    //           tabGroupTooltip: '',
    //           tabGroupUrl: '',
    //           isTabGroupVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
    //           subTabs: [
    //             {
    //               subTabName: 'QP',
    //               subTabUrl: '/quality/qp-qr-by-commodity',
    //               isSubTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
    //               subTabTooltip: ''
    //             },
    //             {
    //               subTabName: 'QR',
    //               subTabUrl: '/quality/qp-qr-by-commodity',
    //               isSubTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
    //               subTabTooltip: ''
    //             },
    //             // {
    //             //   subTabName: 'DPPM Metrics',
    //             //   subTabUrl: '/quality/dppm-metrics',
    //             //   isSubTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
    //             //   subTabTooltip: ''
    //             // },
    //             {
    //               subTabName: 'Quality Cases',
    //               subTabUrl: '/quality/quality-cases',
    //               isSubTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
    //               subTabTooltip: ''
    //             }
    //           ]
    //         },
    //         {
    //           tabGroupName: 'FULFILLMENT',
    //           tabGroupTooltip: '',
    //           tabGroupUrl: '',
    //           isTabGroupVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
    //           subTabs: [
    //             {
    //               subTabName: 'OTC',
    //               subTabUrl: '/fulfillment/ct2r-and-otc',
    //               isSubTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
    //               subTabTooltip: ''
    //             },
    //             {
    //               subTabName: 'Del to CT2R',
    //               subTabUrl: '/fulfillment/ct2r-and-otc',
    //               isSubTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
    //               subTabTooltip: ''
    //             },
    //             {
    //               subTabName: 'Agility',
    //               subTabUrl: '/fulfillment/agility',
    //               isSubTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
    //               subTabTooltip: ''
    //             }
    //           ]
    //         },
    //         {
    //           tabGroupName: 'SOURCING METRICS',
    //           tabGroupTooltip: '',
    //           tabGroupUrl: '',
    //           isTabGroupVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
    //           subTabs: [
    //             {
    //               subTabName: 'BAAO Metrics',
    //               subTabUrl: '/baao-metrics',
    //               isSubTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
    //               subTabTooltip: ''
    //             }
    //           ]
    //         },
    //         {
    //           tabGroupName: 'PERFORMANCE SCORECARD',
    //           tabGroupTooltip: '',
    //           tabGroupUrl: '',
    //           isTabGroupVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
    //           subTabs: [
    //             {
    //               subTabName: 'PERFORMANCE SCORECARD',
    //               subTabUrl: '/supplier-home',
    //               isSubTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
    //               subTabTooltip: ''
    //             }
    //           ]
    //         },
    //       ]
    //     },
    //     {
    //       mainTabName: 'Collaboration',
    //       labelName: 'Collaboration',
    //       mainTabUrl: '',
    //       isMainTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
    //       mainTabTooltip: '',
    //       tabGroups: [
    //         {
    //           tabGroupName: 'Questionnaire',
    //           tabGroupUrl: '',
    //           isTabGroupVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.loggedInUser.role.value === 'sucm_res' || this.loggedInUser.role.value === 'scw_admin_res',
    //           tabGroupTooltip: '',
    //           subTabs: [
    //             {
    //               subTabName: 'QUESTIONNAIRE',
    //               subTabUrl: '/questionnaire',
    //               isSubTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
    //               subTabTooltip: ''
    //             }
    //           ]
    //         },
    //         {
    //           tabGroupName: 'Incident Tracker',
    //           tabGroupUrl: '',
    //           isTabGroupVisible: this.loggedInUser.role.value === 'scw_supplier_user_res',
    //           tabGroupTooltip: '',
    //           subTabs: [
    //             {
    //               subTabName: 'INCIDENT TRACKER',
    //               subTabUrl: '/incident-management',
    //               isSubTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
    //               subTabTooltip: ''
    //             }
    //           ]
    //         },
    //         {
    //           tabGroupName: 'Contacts',
    //           tabGroupUrl: '',
    //           isTabGroupVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.loggedInUser.role.value === 'sucm_res' || this.loggedInUser.role.value === 'scw_admin_res',
    //           tabGroupTooltip: '',
    //           subTabs: [
    //             {
    //               subTabName: 'CONTACTS',
    //               subTabUrl: '/contact-management',
    //               isSubTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
    //               subTabTooltip: ''
    //             },
    //             {
    //               subTabName: 'My Company',
    //               subTabUrl: '/supplier-profile',
    //               subTabTooltip: '',
    //               isSubTabVisible: false
    //             }

    //           ]
    //         },
    //         {
    //           tabGroupName: 'Messages',
    //           tabGroupUrl: '',
    //           isTabGroupVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res' && this.selectedSupplier !== 'ALL',
    //           tabGroupTooltip: '',
    //           subTabs: [
    //             {
    //               subTabName: 'MESSAGES',
    //               subTabUrl: '/messages',
    //               isSubTabVisible: this.loggedInUser?.role?.value === 'scw_admin_res' || this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res' || this.loggedInUser.role.value === 'sucm_res' && this.selectedSupplier !== 'ALL',
    //               subTabTooltip: ''
    //             }
    //           ]
    //         },

    //         {
    //           tabGroupName: 'DOCUMENTS',
    //           tabGroupUrl: '',
    //           isTabGroupVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
    //           tabGroupTooltip: '',
    //           subTabs: [
    //             {
    //               subTabName: 'Cisco Documents',
    //               subTabUrl: '/documents/cisco-documents',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
    //             },
    //             {
    //               subTabName: 'Supplier Documents',
    //               subTabUrl: '/documents/supplier-documents',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
    //             }
    //           ]
    //         },
    //         {
    //           tabGroupName: 'EVENTS',
    //           tabGroupUrl: '',
    //           isTabGroupVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
    //           tabGroupTooltip: '',
    //           subTabs: [
    //             {
    //               subTabName: 'Cisco Events',
    //               subTabUrl: '/events/cisco-events',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
    //             },
    //             {
    //               subTabName: 'Supplier Events',
    //               subTabUrl: '/events/suppliers-events',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
    //             },
    //             {
    //               subTabName: 'Event Timeline Calendar',
    //               subTabUrl: '/events/event-timeline',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
    //             },
    //           ]
    //         }
    //       ]
    //     },
    //     {
    //       mainTabName: 'SC Apps',
    //       labelName: 'SC Apps',
    //       mainTabUrl: '',
    //       isMainTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
    //       mainTabTooltip: '',
    //       tabGroups: []
    //     },
    //     {
    //       mainTabName: 'Training',
    //       labelName: 'Trainings',
    //       mainTabUrl: '',
    //       isMainTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
    //       mainTabTooltip: '',
    //       tabGroups: [
    //         {
    //           tabGroupName: 'Trainings',
    //           tabGroupUrl: '',
    //           isTabGroupVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
    //           tabGroupTooltip: '',
    //           subTabs: [
    //             {
    //               subTabName: 'Trainings',
    //               subTabUrl: '/documents/training-documents',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
    //             },

    //             {
    //               subTabName: 'FAQs',
    //               subTabUrl: '/faq',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
    //             },
    //             {
    //               subTabName: 'About SCW',
    //               subTabUrl: '/about-us',
    //               subTabTooltip: '',
    //               isSubTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
    //             },
    //           ]
    //         }
    //       ]
    //     },
    //     {
    //       mainTabName: 'Report Issues',
    //       labelName: 'Report Issues',
    //       mainTabUrl: '/report-issues',
    //       isMainTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res',
    //       mainTabTooltip: '',
    //       tabGroups: [

    //       ]
    //     },
    //     {
    //       mainTabName: 'Contact Us',
    //       labelName: 'Contact Us',
    //       mainTabUrl: '/winodw-open',
    //       isMainTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res',
    //       mainTabTooltip: '',
    //       tabGroups: [

    //       ]
    //     },
    //   ];
    //   this.breadcrumbs = this.generateBreadcrumbs(this.mainTabs);

    // }else{
    this.mainTabs = [
      {
        mainTabName: 'SCW Home',
        labelName: 'SCW Home',
        mainTabUrl: '',
        isMainTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL'
          || this.loggedInUser.role.value === 'sucm_res' || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'gsm_general_use',
        mainTabTooltip: '',
        tabGroups: [
          {
            tabGroupName: 'SCW Home',
            tabGroupTooltip: '',
            tabGroupUrl: '',
            isTabGroupVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL'
              || this.loggedInUser.role.value === 'sucm_res' || this.loggedInUser.role.value === 'comm_dir_res'
              || this.loggedInUser.role.value === 'gsm_general_use',
            subTabs: [
              {
                subTabName: 'Supplier Home',
                subTabUrl: '/supplier-home',
                isSubTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
                subTabTooltip: ''
              },
              // {
              //   subTabName: 'Performance Scorecard',
              //   subTabUrl: '/supplier-home',
              //   isSubTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
              //   subTabTooltip: ''
              // },
              {
                subTabName: (this.loggedInUser.role.value === 'sucm_res' || this.loggedInUser.role.value === 'comm_dir_res') ? 'SUCM Home' : 'SOCM Home',
                subTabUrl: '/sucm-home/my-view',
                isSubTabVisible: (this.loggedInUser.role.value === 'sucm_res' || this.loggedInUser.role.value === 'comm_dir_res') && !this.isUserRestricted,
                subTabTooltip: ''
              },
              {
                subTabName: (this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res') ? 'General User Home' : 'SUCM Home',
                subTabUrl: '/general-user-home',
                isSubTabVisible: this.loggedInUser.role.value === 'gsm_general_use'
                  || (this.loggedInUser.role.value === 'sucm_res' && this.isUserRestricted)
                  || (this.loggedInUser.role.value === 'comm_dir_res' && this.isUserRestricted)
                  || this.loggedInUser.role.value === 'SCO_scw_general_user_res',
                subTabTooltip: ''
              },
              {
                subTabName: 'About Us',
                subTabUrl: '/about-us',
                isSubTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
                subTabTooltip: ''
              },
              {
                subTabName: 'FAQs',
                subTabUrl: '/faq',
                isSubTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL'
                  || this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res',
                subTabTooltip: ''
              },
              {
                subTabName: 'Download',
                subTabUrl: '/download',
                subTabTooltip: '',
                isSubTabVisible: false
              }
            ]
          }
        ]
      },
      {
        mainTabName: 'Performance',
        labelName: 'Performance',
        mainTabUrl: '',
        isMainTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' ||
          (((this.loggedInUser.role.value === 'SCO_scw_general_user_res' && !this.hideSectionsForSocm)
            || this.loggedInUser.role.value !== 'SCO_scw_general_user_res') && this.selectedSupplier !== 'ALL'),
        mainTabTooltip: '',
        tabGroups: [
          {
            tabGroupName: 'SBR/RESILIENCY',
            tabGroupTooltip: '',
            tabGroupUrl: '',
            isTabGroupVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
            subTabs: [
              {
                subTabName: 'SBR Scores',
                subTabUrl: '/sbr-score-card',
                isSubTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
                subTabTooltip: ''
              },
              {
                subTabName: 'Resiliency Scores',
                subTabUrl: '/resiliency-score-card',
                isSubTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
                subTabTooltip: ''
              },
              {
                subTabName: 'ESG (Sustainability) Metrics',
                subTabUrl: '/supplier-sustainability-score',
                isSubTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
                subTabTooltip: ''
              },
              {
                subTabName: 'FHR : Financial Health Rating',
                subTabUrl: '/fhr-detailed-view',
                isSubTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
                subTabTooltip: ''
              }
            ]
          },
          {
            tabGroupName: 'QUALITY',
            tabGroupTooltip: '',
            tabGroupUrl: '',
            isTabGroupVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
            subTabs: [
              {
                subTabName: 'QP/QR By Commodity',
                subTabUrl: '/quality/qp-qr-by-commodity',
                isSubTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
                subTabTooltip: ''
              },
              {
                subTabName: 'QP/QR Detailed View',
                subTabUrl: '/quality/qp-qr-detailed-view',
                isSubTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
                subTabTooltip: ''
              },
              // {
              //   subTabName: 'DPPM Metrics',
              //   subTabUrl: '/quality/dppm-metrics',
              //   isSubTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
              //   subTabTooltip: ''
              // },
              {
                subTabName: 'Quality Cases',
                subTabUrl: '/quality/quality-cases',
                isSubTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
                subTabTooltip: ''
              }
            ]
          },
          {
            tabGroupName: 'FULFILLMENT',
            tabGroupTooltip: '',
            tabGroupUrl: '',
            isTabGroupVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
            subTabs: [
              {
                subTabName: 'CT2R and OTC',
                subTabUrl: '/fulfillment/ct2r-and-otc',
                isSubTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
                subTabTooltip: ''
              },
              {
                subTabName: 'Agility',
                subTabUrl: '/fulfillment/agility',
                isSubTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
                subTabTooltip: ''
              }
            ]
          },
          {
            tabGroupName: 'SOURCING METRICS',
            tabGroupTooltip: '',
            tabGroupUrl: '',
            isTabGroupVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
            subTabs: [
              {
                subTabName: 'BAAO Metrics',
                subTabUrl: '/baao-metrics',
                isSubTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
                subTabTooltip: ''
              }
            ]
          },
        ]
      },
      {
        mainTabName: 'Collaboration',
        labelName: 'Collaboration',
        mainTabUrl: '',
        isMainTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
        mainTabTooltip: '',
        tabGroups: [
          {
            tabGroupName: 'SURVEYS/EVENTS',
            tabGroupUrl: '',
            isTabGroupVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.loggedInUser.role.value === 'sucm_res' || this.loggedInUser.role.value === 'scw_admin_res',
            tabGroupTooltip: '',
            subTabs: [
              {
                subTabName: 'Questionnaire',
                subTabUrl: '/questionnaire',
                subTabTooltip: '',
                isSubTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
              },
              {
                subTabName: 'Cisco Events Calendar',
                subTabUrl: '/events/cisco-events',
                subTabTooltip: '',
                isSubTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
              },
              {
                subTabName: 'Supplier Events Calendar',
                subTabUrl: '/events/suppliers-events',
                subTabTooltip: '',
                isSubTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
              },
              {
                subTabName: 'Event Timeline Calendar',
                subTabUrl: '/events/event-timeline',
                subTabTooltip: '',
                isSubTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
              },
              //add the incident tracker code here
              {
                subTabName: 'Incident Tracker',
                subTabUrl: '/incident-management',
                isSubTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
                subTabTooltip: ''
              },
              {
                subTabName: 'Incident Management',
                subTabUrl: '/incident-management/' + this.dynamicSupplierName + '/' + this.dynamicSurveyId,
                subTabTooltip: '',
                isSubTabVisible: false
              }
            ]
          },
          {
            tabGroupName: 'CONTACTS/PROFILE',
            tabGroupUrl: '',
            isTabGroupVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
            tabGroupTooltip: '',
            subTabs: [
              {
                subTabName: 'Supplier Contacts',
                subTabUrl: '/contact-management',
                subTabTooltip: '',
                isSubTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
              },
              {
                subTabName: 'My Company',
                subTabUrl: '/supplier-profile',
                subTabTooltip: '',
                isSubTabVisible: (this.loggedInUser.role.value === 'scw_admin_res' || this.loggedInUser.role.value === 'sucm_res' || this.loggedInUser.role.value === 'comm_dir_res')
                  && this.selectedSupplier !== 'ALL'
              }
            ]
          },
          {
            tabGroupName: 'DOCUMENTS',
            tabGroupUrl: '',
            isTabGroupVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
            tabGroupTooltip: '',
            subTabs: [
              {
                subTabName: 'Cisco Documents',
                subTabUrl: '/documents/cisco-documents',
                subTabTooltip: '',
                isSubTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
              },
              {
                subTabName: 'Supplier Documents',
                subTabUrl: '/documents/supplier-documents',
                subTabTooltip: '',
                isSubTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
              }
            ]
          }
        ]
      },
      {
        mainTabName: 'Tools',
        labelName: 'Tools',
        mainTabUrl: '',
        isMainTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
        mainTabTooltip: '',
        tabGroups: []
      },
      {
        mainTabName: 'Training',
        labelName: 'Trainings',
        mainTabUrl: '',
        isMainTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
        mainTabTooltip: '',
        tabGroups: [
          {
            tabGroupName: 'TRAINING',
            tabGroupUrl: '',
            isTabGroupVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
            tabGroupTooltip: '',
            subTabs: [
              {
                subTabName: 'Trainings',
                subTabUrl: '/documents/training-documents',
                subTabTooltip: '',
                isSubTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
              }
              // {
              //   subTabName: 'About',
              //   subTabUrl: '/documents/training-documents',
              //   subTabTooltip: '',
              //   isSubTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
              // },
              // {
              //   subTabName: 'FAQs',
              //   subTabUrl: '/documents/training-documents',
              //   subTabTooltip: '',
              //   isSubTabVisible: this.loggedInUser.role.value === 'scw_supplier_user_res' || this.selectedSupplier !== 'ALL',
              // }
            ]
          }
        ]
      },
      {
        mainTabName: 'Workbench',
        labelName: 'Workbench',
        mainTabUrl: '',
        isMainTabVisible: (((this.loggedInUser.role.value === 'SCO_scw_general_user_res' && !this.hideSectionsForSocm)
          || this.loggedInUser.role.value === 'gsm_general_use') && this.selectedSupplier !== 'ALL')
          || this.loggedInUser.role.value === 'sucm_res' || this.loggedInUser.role.value === 'socm_res'
          || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'scw_admin_res',
        mainTabTooltip: '',
        tabGroups: [
          {
            tabGroupName: 'WORKBENCH',
            tabGroupUrl: '',
            isTabGroupVisible: ((this.loggedInUser.role.value === 'SCO_scw_general_user_res'
              || this.loggedInUser.role.value === 'gsm_general_use') && this.selectedSupplier !== 'ALL')
              || this.loggedInUser.role.value === 'sucm_res' || this.loggedInUser.role.value === 'socm_res'
              || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'scw_admin_res',
            tabGroupTooltip: '',
            subTabs: [
              {
                subTabName: 'Dashboards',
                subTabUrl: '/dashboard',
                subTabTooltip: '',
                isSubTabVisible: ((this.loggedInUser.role.value === 'SCO_scw_general_user_res'
                  || this.loggedInUser.role.value === 'gsm_general_use') && this.selectedSupplier !== 'ALL')
                  || this.loggedInUser.role.value === 'sucm_res' || this.loggedInUser.role.value === 'socm_res'
                  || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'scw_admin_res'
              },
              {
                subTabName: 'Messages',
                subTabUrl: '/messages',
                subTabTooltip: '',
                isSubTabVisible: ((this.loggedInUser.role.value === 'SCO_scw_general_user_res'
                  || this.loggedInUser.role.value === 'gsm_general_use') && this.selectedSupplier !== 'ALL')
                  || this.loggedInUser.role.value === 'sucm_res' || this.loggedInUser.role.value === 'socm_res'
                  || this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'scw_admin_res'
              },
              {
                subTabName: 'Wizards',
                subTabUrl: '/wizards',
                subTabTooltip: '',
                isSubTabVisible: this.loggedInUser.role.value !== 'SCO_scw_general_user_res'
                  && this.loggedInUser.role.value !== 'gsm_general_use'
              }
            ]
          }
        ]
      },
      {
        mainTabName: 'Admin',
        labelName: 'Admin',
        mainTabUrl: '',
        isMainTabVisible: this.loggedInUser.role.value !== 'scw_supplier_user_res',
        mainTabTooltip: '',
        tabGroups: [
          {
            tabGroupName: 'ADMIN',
            tabGroupUrl: '',
            isTabGroupVisible: this.loggedInUser.role.value !== 'scw_supplier_user_res',
            tabGroupTooltip: '',
            subTabs: [
              {
                subTabName: 'Lookup',
                subTabUrl: '/lookup',
                subTabTooltip: '',
                isSubTabVisible: this.loggedInUser.role.value === 'scw_admin_res'
              },
              {
                subTabName: 'Tools',
                subTabUrl: '/tools',
                subTabTooltip: '',
                isSubTabVisible: this.loggedInUser.role.value !== 'scw_supplier_user_res',
              },
              {
                subTabName: 'Questionnaire',
                subTabUrl: '/questionnaire',
                subTabTooltip: '',
                isSubTabVisible: this.loggedInUser.role.value !== 'scw_supplier_user_res',
              },
              {
                subTabName: 'Contact Management',
                subTabUrl: '/contact-management',
                subTabTooltip: '',
                isSubTabVisible: this.loggedInUser.role.value !== 'scw_supplier_user_res',
              },
              {
                subTabName: 'Events Calendar',
                subTabUrl: '/events/cisco-events',
                subTabTooltip: '',
                isSubTabVisible: this.loggedInUser.role.value !== 'scw_supplier_user_res',
              },
              {
                subTabName: 'Manage Metrics',
                subTabUrl: '/manage-metrics',
                subTabTooltip: '',
                isSubTabVisible: this.loggedInUser.role.value === 'scw_admin_res'
              },
              {
                subTabName: 'Documents',
                subTabUrl: '/documents/cisco-documents',
                subTabTooltip: '',
                isSubTabVisible: this.loggedInUser.role.value !== 'scw_supplier_user_res',
              },
              {
                subTabName: 'Trainings',
                subTabUrl: '/documents/training-documents',
                subTabTooltip: '',
                isSubTabVisible: this.loggedInUser.role.value !== 'scw_supplier_user_res'
              },
              {
                subTabName: 'SuCM Overview',
                subTabUrl: '/sucm-home/overall-view',
                subTabTooltip: '',
                isSubTabVisible: this.loggedInUser.role.value === 'scw_admin_res' || this.loggedInUser.role.value === 'socm_res'
                  || ((this.loggedInUser.role.value === 'sucm_res' || this.loggedInUser.role.value === 'comm_dir_res') && !this.isUserRestricted)
              },
              {
                subTabName: 'Supplier Whitepaper',
                subTabUrl: '/supplier-whitepaper',
                subTabTooltip: '',
                isSubTabVisible: this.isWhitepaperAccess
              },
              {
                subTabName: 'Watchlist',
                subTabUrl: '/sucm-home/watchlist',
                subTabTooltip: '',
                isSubTabVisible: this.loggedInUser.role.value === 'scw_admin_res' || this.loggedInUser.role.value === 'socm_res'
                  || ((this.loggedInUser.role.value === 'sucm_res' || this.loggedInUser.role.value === 'comm_dir_res') && !this.isUserRestricted)
              },
              {
                              subTabName: 'Parent Supplier Mapping',
                              subTabUrl: '/parent-mapping',
                              subTabTooltip: '',
                              isSubTabVisible: this.loggedInUser.role.value === 'scw_admin_res'||this.loggedInUser.role.value === 'sucm_res'|| this.loggedInUser.role.value === 'comm_dir_res' || this.loggedInUser.role.value === 'socm_res'
                              || this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res',
              },
              {
                subTabName: 'Incident Management',
                subTabUrl: '/incident-management',
                subTabTooltip: '',
                isSubTabVisible: false
              },
              {
                subTabName: 'Download',
                subTabUrl: '/download',
                subTabTooltip: '',
                isSubTabVisible: false
              }
            ]
          }
        ]
      },
    ];
    //}

    await this.getToolTips();
  }

  getMainTabNameByUrl(subTabUrl: string): string {
    let mainTabName: string = '';

    this.mainTabs.some(mainTab => {
      return mainTab.tabGroups.some(tabGroup => {
        const subTab = tabGroup.subTabs.find(tab => tab.subTabUrl === subTabUrl);

        if (subTab) {
          mainTabName = mainTab.mainTabName;
          return true;
        }

        return false;
      });
    });

    return mainTabName;
  }

  onClickFilterPanel(val: any) {
    if (this.isMenuOpened && val === 'open') {
      this.onClickMenuPanel('close');
    }
    this.isFilterOpened = val === 'open' ? true : false;
  }

  onClickMenuPanel(val: any) {
    if (this.isFilterOpened && val === 'open') {
      this.onClickFilterPanel('close');
    }
    this.selectedMainMenuIndex = Number(window.sessionStorage.getItem('selectedMainMenuIndex'));
    this.selectedTabMenuGrpIndex = Number(window.sessionStorage.getItem('selectedTabMenuGrpIndex'));
    this.selectedSubMenuIndex = Number(window.sessionStorage.getItem('selectedSubMenuIndex'));
    this.isMenuOpened = val === 'open' ? true : false;
  }

  onClickMainMenuBreadCrum(mainTab){

      window.localStorage.setItem('type', 'login');
      window.sessionStorage.setItem('selectedMainMenuIndex', this.selectedMainMenuIndex);
      this.selectedTabMenuGrpIndex = 0;
      window.sessionStorage.setItem('selectedTabMenuGrpIndex', this.selectedTabMenuGrpIndex);
      this.selectedSubMenuIndex = 0;
      window.sessionStorage.setItem('selectedSubMenuIndex', this.selectedSubMenuIndex);
      this.onClickMenuPanel('close');
      if(mainTab?.label == 'Home'){
        //this.router.navigate([mainTab?.url]);
        
        
        if (this.isUserRestricted) {
          this.breadcrumbs = [];
          this.breadcrumbs.push({ label: 'Home', url: '/general-user-home' });
          if(this.loggedInUser.role.value === 'sucm_res'){
            window.localStorage.setItem('redirectedFrom-To', 'Login-Sucm');
            this.sharedService.setRedirectionUrl('general-user-home');
          }else if(this.loggedInUser.role.value === 'socm_res'){
            window.localStorage.setItem('redirectedFrom-To', 'Login-Socm');
            this.sharedService.setRedirectionUrl('general-user-home');
          }else if(this.loggedInUser.role.value === 'comm_dir_res'){
            window.localStorage.setItem('redirectedFrom-To', 'Login-Director');
            this.sharedService.setRedirectionUrl('general-user-home');
          }else if(this.loggedInUser.role.value === 'SCO_scw_general_user_res'){
            window.localStorage.setItem('redirectedFrom-To', 'Login-Sco');
            this.sharedService.setRedirectionUrl('general-user-home');
          }else if(this.loggedInUser.role.value === 'gsm_general_use'){
            window.localStorage.setItem('redirectedFrom-To', 'Login-General');
            this.sharedService.setRedirectionUrl('general-user-home');
          }else if(this.loggedInUser.role.value === 'scw_admin_res'){
            window.localStorage.setItem('redirectedFrom-To', 'Login-AdminUser');
            this.sharedService.setRedirectionUrl('sucm-home/overall-view');
          }else if(this.loggedInUser.role.value === 'scw_supplier_user_res'){
            window.localStorage.setItem('redirectedFrom-To', 'Login-Home');
            this.sharedService.setRedirectionUrl('supplier-home');
          }
        }
        else{
          this.breadcrumbs = [];
          this.breadcrumbs.push({ label: 'Home', url: '/sucm-home/my-view' });
          if(this.loggedInUser.role.value === 'sucm_res'){
            window.localStorage.setItem('redirectedFrom-To', 'Login-Sucm');
            this.sharedService.setRedirectionUrl('sucm-home/my-view');
          }else if(this.loggedInUser.role.value === 'socm_res'){
            window.localStorage.setItem('redirectedFrom-To', 'Login-Socm');
            this.sharedService.setRedirectionUrl('sucm-home/my-view');
          }else if(this.loggedInUser.role.value === 'comm_dir_res'){
            window.localStorage.setItem('redirectedFrom-To', 'Login-Director');
            this.sharedService.setRedirectionUrl('sucm-home/my-view');
          }else if(this.loggedInUser.role.value === 'SCO_scw_general_user_res'){
            window.localStorage.setItem('redirectedFrom-To', 'Login-Sco');
            this.sharedService.setRedirectionUrl('sucm-home/my-view');
          }else if(this.loggedInUser.role.value === 'gsm_general_use'){
            window.localStorage.setItem('redirectedFrom-To', 'Login-General');
            this.sharedService.setRedirectionUrl('sucm-home/my-view');
          }else if(this.loggedInUser.role.value === 'scw_admin_res'){
            window.localStorage.setItem('redirectedFrom-To', 'Login-AdminUser');
            this.sharedService.setRedirectionUrl('sucm-home/overall-view');
          }else if(this.loggedInUser.role.value === 'scw_supplier_user_res'){
            window.localStorage.setItem('redirectedFrom-To', 'Login-Home');
            this.sharedService.setRedirectionUrl('supplier-new-home');
          }
        }
      }else{
        if(this.loggedInUser.role.value === 'sucm_res'){
          window.localStorage.setItem('isFirstTimeSupplierSelected', 'true');
          window.localStorage.setItem('redirectedFrom-To', 'Sucm-Sucm Supplier');
          this.sharedService.setRedirectionUrl('supplier-new-home');

        }else if(this.loggedInUser.role.value === 'socm_res'){
          window.localStorage.setItem('isFirstTimeSupplierSelected', 'true');
          window.localStorage.setItem('redirectedFrom-To', 'Socm-Socm Supplier');
          this.sharedService.setRedirectionUrl('supplier-new-home');

        }else if(this.loggedInUser.role.value === 'comm_dir_res'){
          window.localStorage.setItem('isFirstTimeSupplierSelected', 'true');
          window.localStorage.setItem('redirectedFrom-To', 'Director-Director Supplier');
          this.sharedService.setRedirectionUrl('supplier-new-home');

        }else if(this.loggedInUser.role.value === 'SCO_scw_general_user_res'){
          window.localStorage.setItem('isFirstTimeSupplierSelected', 'true');
          window.localStorage.setItem('redirectedFrom-To', 'Sco-Sco Supplier');
          this.sharedService.setRedirectionUrl('supplier-new-home');

        }else if(this.loggedInUser.role.value === 'gsm_general_use'){
          window.localStorage.setItem('isFirstTimeSupplierSelected', 'true');
          window.localStorage.setItem('redirectedFrom-To', 'General-General Supplier');
          this.sharedService.setRedirectionUrl('supplier-new-home');
          
        }else if(this.loggedInUser.role.value === 'scw_admin_res'){
          window.localStorage.setItem('isFirstTimeSupplierSelected', 'true');
          window.localStorage.setItem('redirectedFrom-To', 'AdminUser-AdminUser Supplier');
          this.sharedService.setRedirectionUrl('supplier-new-home');
          
        }else {
          window.localStorage.setItem('isFirstTimeSupplierSelected', 'true');
          window.localStorage.setItem('redirectedFrom-To', 'Admin-SCW Home');
          this.sharedService.setRedirectionUrl('supplier-home');
        }

      }
      this.breadcrumbs = this.generateBreadcrumbs(this.mainTabs);
      this.onRemoveSupplierRecentVisit
    
  }

  onClickMainMenuTab(mainTab: any) {

    const mainMenuIndex = this.mainTabs.findIndex((mainMenuTab) => mainTab.mainTabName === mainMenuTab.mainTabName);
    
    if(mainTab?.mainTabUrl != '/winodw-open' && mainTab?.mainTabName != 'Search Supplier'){ 
      this.selectedMainMenuIndex = mainMenuIndex;
    }
    if(mainTab?.mainTabUrl == '/faq'){ 
      window.localStorage.setItem('faqMaintab', 'true');
    }
    if (mainTab?.mainTabUrl == '/report-issues') {
      this.onClickReportIssues('open')
    }
    if(mainTab?.labelName == 'Home'){
    }
    if(mainTab?.mainTabUrl == '/search-supplier'){
      this.onClickFilterPanel(this.isFilterOpened?'close':'open')

    }
    else if(mainTab?.mainTabUrl == '/winodw-open'){
      const emailAddress = 'scw_support@cisco.com';
      const mailtoLink = `mailto:${emailAddress}`;
      window.location.href = mailtoLink;

    }else if(mainTab?.mainTabUrl == '/faq'){ 
      window.localStorage.setItem('faqMaintab', 'true');
      this.selectedMainMenuIndex = mainMenuIndex;
      window.sessionStorage.setItem('selectedMainMenuIndex', this.selectedMainMenuIndex);
      this.router.navigateByUrl('/').then(() => {
        this.router.navigate(['faq']);
      });
    }if(mainTab?.mainTabUrl == '/sucm-home/watchlist'){ 
      this.selectedMainMenuIndex = mainMenuIndex;
      window.sessionStorage.setItem('selectedMainMenuIndex', this.selectedMainMenuIndex);
      this.router.navigateByUrl('/').then(() => {
        this.router.navigate(['sucm-home/watchlist']);
      });
    }else if(mainTab?.mainTabUrl == '/sucm-home/my-view'){ 
      window.sessionStorage.setItem('selectedMainMenuIndex', '0');
      window.sessionStorage.setItem('selectedTabMenuGrpIndex', '0');
      window.sessionStorage.setItem('selectedSubMenuIndex', '0');
      this.onRemoveSupplierRecentVisit();
      let currentUrl = this.router.url;
      
        this.router.navigateByUrl('/').then(() => {
          this.router.navigate(['sucm-home/my-view']);
        });
      
      

    }else if (mainTab?.mainTabUrl) {
      window.localStorage.setItem('type', 'login');
      window.sessionStorage.setItem('selectedMainMenuIndex', this.selectedMainMenuIndex);
      this.selectedTabMenuGrpIndex = 0;
      window.sessionStorage.setItem('selectedTabMenuGrpIndex', this.selectedTabMenuGrpIndex);
      this.selectedSubMenuIndex = 0;
      window.sessionStorage.setItem('selectedSubMenuIndex', this.selectedSubMenuIndex);
      this.onClickMenuPanel('close');
      if(mainTab?.labelName == 'Home'){
        this.onRemoveSupplierRecentVisit();
      }
      this.router.navigate([mainTab.mainTabUrl]);
    }

    if(mainTab?.mainTabUrl == '/faq'){
    this.onClickFilterPanel('close');
    }

    this.breadcrumbs = this.generateBreadcrumbs(this.mainTabs);
    let obj = {
      mainTabName: mainTab.mainTabName,
      linkName: mainTab.mainTabName,
      linkUrl: mainTab.mainTabUrl
    }
    //this.addLink(obj);

  }

  onClickSubMenuTab(mainTab: MainMenuTab, tabGroup: TabMenuGroup, subTab: SubMenuTabs) {

    window.localStorage.setItem('type', 'login');
    if (subTab.subTabName === 'Adoption Metrics' && subTab.subTabUrl === '/adoption-metrics') {
      // Call the redirect function
      //this.redirectToSCWMetrics();
      window.open('https://tableau.cisco.com/#/site/SWSC/workbooks/124167/views', '_blank');

  }else if (subTab.subTabName === 'Data Refresh Status' && subTab.subTabUrl === '/data-refresh') {
    // Call the redirect function
    this.onClickDataIntegrationIcon('open');
    //window.open(subTab.subTabUrl, '_blank');
  }else if ((mainTab.mainTabName === 'Tools' || mainTab.mainTabName === 'SC Apps') && subTab.subTabName !== 'Tools : Detailed View') {
      this.addSplunkLog(subTab, 'verticalMenuExternalToolsNavigation');
      window.open(subTab.subTabUrl, '_blank');
    }else if (mainTab.mainTabName === 'Favorites') {
      // Handle Favorites tab navigation
      const matchingTab = this.findMatchingTabByUrl(subTab.subTabUrl);
      if (matchingTab) {
          this.navigateToTab(matchingTab.mainTab, matchingTab.tabGroup, matchingTab.subTab);
      } 
  }
    else {
      const mainMenuIndex = this.mainTabs.findIndex((mainMenuTab) => mainTab.mainTabName === mainMenuTab.mainTabName);
      this.selectedMainMenuIndex = mainMenuIndex;
      window.sessionStorage.setItem('selectedMainMenuIndex', this.selectedMainMenuIndex);
      const tabGroupIndex = this.mainTabs[this.selectedMainMenuIndex].tabGroups.findIndex((tabGrp) =>
        tabGroup.tabGroupName === tabGrp.tabGroupName);
      this.selectedTabMenuGrpIndex = tabGroupIndex;
      window.sessionStorage.setItem('selectedTabMenuGrpIndex', this.selectedTabMenuGrpIndex);
      const subMenuIndex = this.mainTabs[this.selectedMainMenuIndex].tabGroups[tabGroupIndex].subTabs
        .findIndex((sTab) => sTab.subTabName === subTab.subTabName);
      this.selectedSubMenuIndex = subMenuIndex;
      window.sessionStorage.setItem('selectedSubMenuIndex', this.selectedSubMenuIndex);
      const selectedSupplier = this.mainTabs[this.selectedMainMenuIndex].labelName === 'Admin' ?
        'ALL' : this.selectedSupplier;
      window.localStorage.setItem('selectedSupplier', this.encDecSrvice.set(selectedSupplier));
      window.sessionStorage.setItem('selectedSupplier', this.encDecSrvice.set(selectedSupplier));

      this.onClickMenuPanel('close');
      if ((mainTab.mainTabName === 'Tools' || mainTab.mainTabName === 'SC Apps') && subTab.subTabName === 'Tools : Detailed View') {
        this.addSplunkLog(subTab, 'supplierHomeToolsNavigation');
      }

      let currentUrl = this.router.url;
      if (currentUrl === subTab.subTabUrl) {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate([currentUrl]);
        });
      }
      else {
        this.router.navigateByUrl('/').then(() => {
          this.router.navigate([subTab.subTabUrl]);
        });
      }
    }
    let obj = {
      mainTabName: mainTab.mainTabName,
      linkName: subTab.subTabName,
      linkUrl: subTab.subTabUrl,
      supplier: this.encDecSrvice.get(window.localStorage.getItem('selectedSupplier'))
    }
    this.addLink(obj);
    this.onClickFilterPanel('close');

    this.breadcrumbs = this.generateBreadcrumbs(this.mainTabs);

  }

  addSplunkLog(subTab: any, type: any) {
    const splunkToolsObj = {
      logType: type,
      supplierName: this.encDecSrvice.get(window.localStorage.getItem('selectedSupplier')),
      regionCode: window.localStorage.getItem('regionCode'),
      os: window.localStorage.getItem('os'),
      LocalTime: new Date(),
      countryCode: window.localStorage.getItem('countryCode'),
      ToolClicked: subTab.subTabName,
      emailId: this.loggedInUser.email,
      userId: this.loggedInUser.userId,
      continentCode: window.localStorage.getItem('continentCode'),
    };
    this.splunkLogsService.additionalLogs(splunkToolsObj).subscribe();
  }

  async getToolTips() {
    const toolTipsArr: any = window.localStorage.getItem('supplierTabToolTips');
    if (toolTipsArr && JSON.parse(toolTipsArr).length > 0) {
      this.showToolTipForSupplierTabs();
    }
    else {
      await lastValueFrom(this.dashboardService.getToolTipsDetails(this.loggedInUser.role.value))
        .then((respData: any) => {
          if (respData && respData.length > 0) {
            window.localStorage.setItem('supplierTabToolTips', JSON.stringify(respData));
            this.showToolTipForSupplierTabs();
          }
        })
        .catch((err: any) => {
          this.openConfirmationPopup('Error', err.error.errorMsg, false);
        });
    }
  }

  showToolTipForSupplierTabs() {
    const toolTipsArr: any = window.localStorage.getItem('supplierTabToolTips');
    if (toolTipsArr && JSON.parse(toolTipsArr).length > 0) {
      for (const mainTab of this.mainTabs) {
        if ((mainTab.mainTabName === 'Performance' || mainTab.mainTabName === 'Collaboration'
          || mainTab.mainTabName === 'Training' || mainTab.mainTabName === 'SCW Home')&&(this.loggedInUser.role.value !== 'sucm_res')&&(this.loggedInUser.role.value !== 'comm_dir_res')) {
          if (mainTab.tabGroups.length > 0) {
            for (const toolTip of JSON.parse(toolTipsArr)) {
              const tabGrp = mainTab.tabGroups.filter((tGrp) =>
                tGrp.tabGroupName.toLowerCase().trim() === toolTip.widgetName.toLowerCase().trim());
              if (tabGrp && tabGrp.length > 0) {
                tabGrp[0].tabGroupTooltip = toolTip.toolTip;
              }
              else {
                for (const tabGroup of mainTab.tabGroups) {
                  const subTab = tabGroup.subTabs.filter((sTab) =>
                    sTab.subTabName.toLowerCase().trim() === toolTip.widgetName.toLowerCase().trim());
                  if (subTab && subTab.length > 0) {
                    subTab[0].subTabTooltip = toolTip.toolTip;
                  }
                }
              }
            }
          }
          else {
            for (const toolTip of JSON.parse(toolTipsArr)) {
              if (toolTip.widgetName.toLowerCase() === this.mainTabs[this.selectedMainMenuIndex].mainTabName.toLowerCase()) {
                this.mainTabs[this.selectedMainMenuIndex].mainTabTooltip = toolTip.toolTip;
              }
            }
          }
        }
      }
    }
  }

  showHideSupplierIcons(iconName: any) {
    if (this.router.url.includes('sucm-home') && iconName === 'read-me' && (this.loggedInUser.role.value === 'sucm_res' || this.loggedInUser.role.value === 'comm_dir_res')) {
      return true;
    }
    else if (window.localStorage.getItem('selectedSupplier') &&
      this.encDecSrvice.get(window.localStorage.getItem('selectedSupplier')) !== 'ALL') {
      if (iconName === 'feedback') {
        return false;
      }
      else if (iconName === 'home-icon') {
        if (this.loggedInUser.role.value === 'sucm_res' || this.loggedInUser.role.value === 'comm_dir_res') {
          if (this.router.url.includes('supplier-home')) {
            return true;
          }
        }
        else {
          if (!this.router.url.includes('supplier-home')) {
            return true;
          }
        }
      }
      else if (iconName === 'email') {
        return false;
      }
      else if (iconName === 'read-me') {
        if (this.router.url.includes('fulfillment') || this.router.url.includes('contact-management')
          || this.router.url.includes('questionnaire') || this.router.url.includes('quality')
          || this.router.url.includes('events') || this.router.url.includes('resiliency-score-card')
          || this.router.url.includes('sbr-score-card') || this.router.url.includes('cisco-documents')
          || this.router.url.includes('supplier-documents') || this.router.url.includes('baao-metrics')
          || this.router.url.includes('supplier-profile') || this.router.url.includes('dashboard')
          || this.router.url.includes('messages') || this.router.url.includes('wizards')
          || this.router.url.includes('tools') || this.router.url.includes('training-documents')
          || this.router.url.includes('fhr-detailed-view') || this.router.url.includes('supplier-sustainability-score')) {
          return true;
        }
        else {
          return false;
        }
      }
      else if (iconName === 'tools') {
        return !this.router.url.includes('tools') ? true : false;
      }
      else if (iconName === 'supplier-notes') {
        return (this.loggedInUser.role.value === 'scw_admin_res' || this.loggedInUser.role.value === 'sucm_res' || this.loggedInUser.role.value === 'comm_dir_res')
          && this.router.url.includes('supplier-home') ? true : false;
      }
    }
    else {
      return false;
    }
  }

  getSupportMail() {
    if (window.localStorage.getItem('selectedSupplier') &&
      this.encDecSrvice.get(window.localStorage.getItem('selectedSupplier')) !== 'ALL') {
      if (this.router.url.includes('sbr-score-card')) {
        return 'sbr_scw_support@cisco.com';
      }
      else if (this.router.url.includes('quality')) {
        return 'quality_scw_support@cisco.com';
      }
      else if (this.router.url.includes('resiliency-score-card')) {
        return 'resilency_scw_support@cisco.com';
      }
      else if (this.router.url.includes('baao-metrics')) {
        return 'sciam-support@cisco.com';
      }
      else if (this.router.url.includes('events') || this.router.url.includes('supplier-profile')
        || this.router.url.includes('fulfillment') || this.router.url.includes('documents')
        || this.router.url.includes('contact-management') || this.router.url.includes('questionnaire')
        || this.router.url.includes('supplier-home') || this.router.url.includes('tools')) {
        return 'cisco_scw_support@cisco.com';
      }
    }
    else {
      return false;
    }
  }

  onClickInfoIcon(val: any, type?: any) {
    if (val === 'open') {
      this.readMeInfo = '';
      this.infoPopup = true;
      this.loaderService.openLoader();
      this.lookupService.getDropdownLookup('SCW_INFO_ICON_DATA').subscribe({
        next: (respData: any) => {
          this.loaderService.closeLoader();
          if (respData && respData.lookupArray && respData.lookupArray.length > 0) {
            if (type === 'filter-info') {
              this.readMeInfo = respData.lookupArray.filter((s: any) => s.code === 'FILTER PANEL')[0];
            }
            else {
              if (this.router.url.includes('sbr-score-card')) {
                this.readMeInfo = respData.lookupArray.filter((s: any) => s.code.toUpperCase() === `SBR READ ME`)[0];
              }
              else if (this.router.url.includes('resiliency-score-card')) {
                this.readMeInfo = respData.lookupArray.filter((s: any) => s.code.toUpperCase() === `RESILIENCY READ ME`)[0];
              }
              else if (this.router.url.includes('questionnaire')) {
                this.readMeInfo = respData.lookupArray.filter((s: any) => s.code.toUpperCase() === `QUESTIONNAIRE READ ME`)[0];
              }
              else if (this.router.url.includes('events')) {
                this.readMeInfo = respData.lookupArray.filter((s: any) => s.code.toUpperCase() === `EVENTS READ ME`)[0];
              }
              else if (this.router.url.includes('contact-management')) {
                this.readMeInfo = respData.lookupArray.filter((s: any) => s.code.toUpperCase() === `CONTACTS READ ME`)[0];
              }
              else if (this.router.url.includes('quality')) {
                this.readMeInfo = respData.lookupArray.filter((s: any) => s.code.toUpperCase() === `QUALITY READ ME`)[0];
              }
              else if (this.router.url.includes('fulfillment')) {
                this.readMeInfo = respData.lookupArray.filter((s: any) => s.code.toUpperCase() === `FULFILLMENT READ ME`)[0];
              }
              else if (this.router.url.includes('cisco-documents') || this.router.url.includes('supplier-documents')) {
                this.readMeInfo = respData.lookupArray.filter((s: any) => s.code.toUpperCase() === `DOCUMENTS READ ME`)[0];
              }
              else if (this.router.url.includes('baao-metrics')) {
                this.readMeInfo = respData.lookupArray.filter((s: any) => s.code.toUpperCase() === `BAAO READ ME`)[0];
              }
              else if (this.router.url.includes('dashboard')) {
                this.readMeInfo = respData.lookupArray.filter((s: any) => s.code.toUpperCase() === `DASHBOARD READ ME`)[0];
              }
              else if (this.router.url.includes('messages')) {
                this.readMeInfo = respData.lookupArray.filter((s: any) => s.code.toUpperCase() === `MESSAGES READ ME`)[0];
              }
              else if (this.router.url.includes('wizards')) {
                this.readMeInfo = respData.lookupArray.filter((s: any) => s.code.toUpperCase() === `WIZARDS READ ME`)[0];
              }
              else if (this.router.url.includes('tools')) {
                this.readMeInfo = respData.lookupArray.filter((s: any) => s.code.toUpperCase() === `TOOLS READ ME`)[0];
              }
              else if (this.router.url.includes('supplier-profile')) {
                this.readMeInfo = respData.lookupArray.filter((s: any) => s.code.toUpperCase() === `SUPPLIER PROFILE READ ME`)[0];
              }
              else if (this.router.url.includes('training-documents')) {
                this.readMeInfo = respData.lookupArray.filter((s: any) => s.code.toUpperCase() === `TRAINING DOCUMENTS READ ME`)[0];
              }
              else if (this.router.url.includes('sucm-home')) {
                this.readMeInfo = respData.lookupArray.filter((s: any) => s.code.toUpperCase() === `SUCM HOME READ ME`)[0];
              }
              else if (this.router.url.includes('fhr-detailed-view')) {
                this.readMeInfo = respData.lookupArray.filter((s: any) => s.code.toUpperCase() === `FHR DETAILED VIEW READ ME`)[0];
              }
              else if (this.router.url.includes('supplier-sustainability-score')) {
                this.readMeInfo = respData.lookupArray.filter((s: any) => s.code.toUpperCase() === `SER READ ME`)[0];
              }
            }
          }
        },
        error: (err: any) => {
          this.loaderService.closeLoader();
          this.openConfirmationPopup('Error', err.error.errorMsg, false);
        }
      });
    }
    else {
      this.infoPopup = false;
      this.readMeInfo = '';
    }
  }

  onClickLogout() {
    window.localStorage.clear();
    this.isLoggedIn = false;
    this.router.navigate(['/logout']);
  }

  logout() {
    window.localStorage.setItem('logout-event', Math.random().toString())
    this.sharedService.logOut();
  }

  refreshToken() {
    this.loaderService.openLoader();
    this.cepmService.refreshToken(this.encDecSrvice.get(window.localStorage.getItem('rtoken')))
      .subscribe({
        next: (respData: any) => {
          this.loaderService.closeLoader();
          if (respData && Object.keys(respData).length > 0 && respData.status === 'S') {
            window.localStorage.setItem('adata', this.encDecSrvice.set(respData.token));
            window.localStorage.setItem('rtoken', this.encDecSrvice.set(respData.refreshToken));
          }
          else if (respData && Object.keys(respData).length > 0 && respData.status !== 'S' && respData.errorMsg) {
            this.openConfirmationPopup('Error', respData.errorMsg, false);
          }
          else {
            this.openConfirmationPopup('Error', 'Refresh Token Failed . Try Again Later', false);
          }
        },
        error: (err: any) => {
          this.loaderService.closeLoader();
          this.openConfirmationPopup('Error', err.error.errorMsg, false);
        }
      });
  }

  initializeFilterVariables() {
    this.searchedSupplier = '';
    this.supplierList = [];
    this.filteredSupplierList = [];

    this.isAllCommoditiesSelected = false;
    this.selectedCommodity = [];
    this.commodityArr = [];
    this.commodityList = [];
    this.searchedCommodity = '';

    this.isAllSucmSelected = false;
    this.selectedSucm = [];
    this.sucmArr = [];
    this.sucmList = [];
    this.searchedSucm = '';
  }

  changeForSpend(supplier: any) {
    const supplierName = supplier.supplierName;
    if (supplierName.indexOf('$ ') === 0) {
      return supplierName.replace('$ ', '').trim();
    }
    else if (supplierName.indexOf('! ') === 0) {
      return supplierName.replace('! ', '').trim();
    }
    else {
      return supplierName.trim();
    }
  }

  tooltipForRestrictedSupplier(supplier: any) {
    const supplierName = supplier.supplierName;
    if (supplierName.indexOf('! ') === 0) {
      return `Restricted supplier -reach out to SUCM/SCW admin for more info`;
    }
  }

  showRestrictedFlag(supplier: any) {
    const supplierName = supplier.supplierName;
    if (supplierName.indexOf('! ') === 0) {
      return true;
    }
    else {
      return false;
    }
  }

  onChangeSortType(value: any) {
    this.sortSupplierType = value;
    this.supplierList = JSON.parse(window.localStorage.getItem('supplierList'));
    const spendSuppArr = [];
    const nonSpendSuppArr = [];
    const restrictedSuppArr = [];
    this.supplierList.forEach((s: any) => {
      if (s.supplierName.indexOf('$ ') === 0) {
        spendSuppArr.push(s.supplierName.trim());
      }
      else if (s.supplierName.indexOf('! ') === 0) {
        restrictedSuppArr.push(s.supplierName.trim());
      }
      else {
        nonSpendSuppArr.push(s.supplierName);
      }
    });
    if (value === 'SortAlphabetically') {
      this.supplierList = [];
      const sortedList = [...spendSuppArr.sort(), ...restrictedSuppArr.sort(), ...nonSpendSuppArr];
      sortedList.forEach((s) => {
        this.supplierList.push({ supplierName: s });
      });
    }
  }

  applyClassForSupplier(supplier: any) {
    if (this.changeForSpend(supplier) === this.selectedSupplier.trim()) {
      return 'active-supplier';
    }
    else if (supplier.supplierName.indexOf('$ ') === 0) {
      return 'specialSupplier';
    }
    else if (supplier.supplierName.indexOf('! ') === 0) {
      return 'restrictedSupplier';
    }
    else {
      return 'normalSupplier';
    }
  }

  async onSelectSupplier(supplier: any, isRedirectedFromAnotherTab?: any) {
    this.saveSupplierNote('cancel');
    window.localStorage.setItem('type', 'login');
    window.localStorage.removeItem('toolsList');
    this.isSupplierRestricted = false;
    if (supplier === 'ALL') {
      this.initializeFilterVariables();
      this.selectedSupplier = supplier;
      window.localStorage.removeItem('isFirstTimeSupplierSelected');
      window.localStorage.removeItem('isFirstTimeNewsPopupVisited');
      window.localStorage.removeItem('isFirstTimeNewsSectionVisited');
      window.localStorage.setItem('supplierName', this.encDecSrvice.set(this.selectedSupplier));
      window.localStorage.setItem('isSupplierRestricted', JSON.stringify(this.isSupplierRestricted));
      window.localStorage.setItem('selectedSupplier', this.encDecSrvice.set(this.selectedSupplier));
      window.sessionStorage.setItem('selectedSupplier', this.encDecSrvice.set(this.selectedSupplier));
      window.localStorage.setItem('selectedSucm', JSON.stringify(this.selectedSucm));
      window.localStorage.setItem('selectedCommodities', JSON.stringify(this.selectedCommodity));
      this.getFilterList();
      this.onChangeSortType('');
      if ((this.mainTabs[Number(window.sessionStorage.getItem('selectedMainMenuIndex'))].mainTabName === 'Admin'
        || this.mainTabs[Number(window.sessionStorage.getItem('selectedMainMenuIndex'))].mainTabName === 'Workbench')
        && (this.loggedInUser.role.value !== 'SCO_scw_general_user_res' && this.loggedInUser.role.value !== 'gsm_general_use')) {
        this.selectedMainMenuIndex = Number(window.sessionStorage.getItem('selectedMainMenuIndex'));
        this.selectedTabMenuGrpIndex = Number(window.sessionStorage.getItem('selectedTabMenuGrpIndex'));
        this.selectedSubMenuIndex = Number(window.sessionStorage.getItem('selectedSubMenuIndex'));
        this.initializeTabs();
        let currentUrl = this.router.url;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate([currentUrl]);
        });
      }
      else if (this.mainTabs[Number(window.sessionStorage.getItem('selectedMainMenuIndex'))].mainTabName !== 'Admin'
        && this.mainTabs[Number(window.sessionStorage.getItem('selectedMainMenuIndex'))].mainTabName !== 'Workbench'
        && (this.loggedInUser.role.value === 'gsm_general_use' || this.loggedInUser.role.value === 'SCO_scw_general_user_res')) {
        this.initializeTabs();
        window.localStorage.setItem('redirectedFrom-To', 'Supplier-SCW Home');
        this.sharedService.setRedirectionUrl('general-user-home');
      }
      else if (this.mainTabs[Number(window.sessionStorage.getItem('selectedMainMenuIndex'))].mainTabName !== 'Admin'
        && this.mainTabs[Number(window.sessionStorage.getItem('selectedMainMenuIndex'))].mainTabName !== 'Workbench'
        && this.loggedInUser.role.value === 'sucm_res') {
        this.initializeTabs();
        if (this.isUserRestricted) {
          window.localStorage.setItem('redirectedFrom-To', 'Supplier-SCW Home');
          this.sharedService.setRedirectionUrl('general-user-home');
        }
        else {
          window.localStorage.setItem('redirectedFrom-To', 'Supplier-SCW Home');
          this.sharedService.setRedirectionUrl('sucm-home/my-view');
        }
      }
      else {
        this.initializeTabs();
        window.localStorage.setItem('redirectedFrom-To', 'Supplier-Workbench');
        this.sharedService.setRedirectionUrl('dashboard');
      }
    }
    else {
      this.onClickFilterPanel('close');
      let supplierName = supplier.supplierName;
      this.isSupplierRestricted = supplierName.indexOf('! ') === 0 ? true : false;
      supplierName = supplierName.indexOf('$ ') === 0 ? supplierName.replace('$ ', '').trim()
        : (supplierName.indexOf('! ') === 0 ? supplierName.replace('! ', '').trim() : supplierName.trim());
      this.selectedSupplier = supplierName;
      window.localStorage.setItem('supplierName', this.encDecSrvice.set(this.selectedSupplier));
      window.localStorage.setItem('selectedSupplier', this.encDecSrvice.set(this.selectedSupplier));
      window.sessionStorage.setItem('selectedSupplier', this.encDecSrvice.set(this.selectedSupplier));
      window.localStorage.setItem('isFirstTimeNewsPopupVisited', 'true');
      window.localStorage.setItem('isFirstTimeNewsSectionVisited', 'true');
      window.localStorage.setItem('isSupplierRestricted', JSON.stringify(this.isSupplierRestricted));
      this.initializeTabs();
      await this.getTools();
      await this.fetchDataBasedOnSearch('supplier', isRedirectedFromAnotherTab);
    }
  }

  async onChangeSucm() {
    if (this.selectedSucm.indexOf('Select All') === -1) {
      this.isAllSucmSelected = false;
      this.sucmArr = this.selectedSucm;
      if (this.sucmList.length === this.sucmArr.length) {
        this.isAllSucmSelected = true;
      }
      window.localStorage.setItem('selectedSucm', JSON.stringify(this.selectedSucm));
      await this.fetchDataBasedOnSearch('sucm');
    }
  }

  async selectAllSucm(event: any) {
    this.sucmArr = [];
    this.selectedSucm = [];
    let selectedSucms: any = [];
    if (event.checked) {
      this.sucmList.forEach((comm: any) => {
        selectedSucms.push(comm.sucmName);
      });
    }

    setTimeout(async () => {
      this.sucmArr = selectedSucms;
      this.selectedSucm = selectedSucms;
      window.localStorage.setItem('selectedSucm', JSON.stringify(this.selectedSucm));
      await this.fetchDataBasedOnSearch('sucm');
    }, 100);
  }

  async onChangeCommodity() {
    if (this.selectedCommodity.indexOf('Select All') === -1) {
      this.isAllCommoditiesSelected = false;
      this.commodityArr = this.selectedCommodity;
      if (this.commodityList.length === this.commodityArr.length) {
        this.isAllCommoditiesSelected = true;
      }
      window.localStorage.setItem('selectedCommodities', JSON.stringify(this.selectedCommodity));
      await this.fetchDataBasedOnSearch('commodity');
    }
  }

  async selectAllCommodities(event: any) {
    this.commodityArr = [];
    this.selectedCommodity = [];
    let selectedCommodities: any = [];
    if (event.checked) {
      this.commodityList.forEach((comm: any) => {
        selectedCommodities.push(comm.commodityName);
      });
    }

    setTimeout(async () => {
      this.commodityArr = selectedCommodities;
      this.selectedCommodity = selectedCommodities;
      window.localStorage.setItem('selectedCommodities', JSON.stringify(this.selectedCommodity));
      await this.fetchDataBasedOnSearch('commodity');
    }, 100);
  }
  async fetchDataBasedOnSearch(val: any, isRedirectedFromAnotherTab?: any) {
    window.localStorage.setItem('type', 'login');
    const requestObj: any = {};
    requestObj.supplierName = this.selectedSupplier === 'ALL' ? '' : this.selectedSupplier;
    requestObj.commodity = this.commodityArr;
    requestObj.sucm = this.sucmArr;
    console.log('inside fetchDataBasedOnSearch');

    this.loaderService.openLoader();
    await lastValueFrom(this.cepmService.fetchDataBasedOnSearch(requestObj))
      .then(async (respData: any) => {
        this.loaderService.closeLoader();
        if (val === 'sucm' || val === 'commodity') {
          if (this.commodityArr.length === 0 && this.sucmArr.length === 0) {
            this.supplierList = JSON.parse(window.localStorage.getItem('filterListResp')).supplier;
          }
          else {
            this.supplierList = respData.supplier;
          }
          val === 'sucm' ? (this.commodityList = respData.commodity) : (this.sucmList = respData.sucm);
        }
        else {
          this.commodityList = respData.commodity;
          this.sucmList = respData.sucm;
        }
        window.localStorage.setItem('commodityList', JSON.stringify(this.commodityList));
        window.localStorage.setItem('supplierList', JSON.stringify(this.supplierList));
        window.localStorage.setItem('sucmList', JSON.stringify(this.sucmList));

        const isFirstTimeSupplierSelected = JSON.parse(window.localStorage.getItem('isFirstTimeSupplierSelected'));
        console.log('inside redirect0000');

        if (!isRedirectedFromAnotherTab) {
          if (val === 'supplier' && !isFirstTimeSupplierSelected) {
            window.localStorage.setItem('isFirstTimeSupplierSelected', 'true');
            window.localStorage.setItem('redirectedFrom-To', 'Admin-SCW Home');
            this.sharedService.setRedirectionUrl('supplier-home');
          }
          else {
            if ((val === 'supplier' && this.mainTabs[Number(window.sessionStorage.getItem('selectedMainMenuIndex'))].mainTabName !== 'Admin')
              || (val === 'commodity' && (window.location.href.includes('/fulfillment')
                || window.location.href.includes('/sbr-score-card') || window.location.href.includes('/resiliency-score-card')
                || window.location.href.includes('/quality') || window.location.href.includes('/supplier-sustainability-score')))) {
              let currentUrl = this.router.url;
              this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigate([currentUrl]);
              });
            }
          }
        }
      })
      .catch((err: any) => {
        this.loaderService.closeLoader();
        this.openConfirmationPopup('Error', err.error.errorMsg, false);
      });
  }

  async getTools() {
    this.toolsList = window.localStorage.getItem('toolsList');
    if ((!this.toolsList || JSON.parse(this.toolsList).length === 0) && this.selectedSupplier !== 'ALL') {
      this.toolsList = [];
      this.loaderService.openLoader();
      await lastValueFrom(this.toolsService.getToolsDetails(this.selectedSupplier))
        .then((respData: any) => {
          this.loaderService.closeLoader();
          this.toolsList = respData;
          window.localStorage.setItem('toolsList', JSON.stringify(this.toolsList));
        })
        .catch((err: any) => {
          this.loaderService.closeLoader();
          this.openConfirmationPopup('Error', err.error.errorMsg, false);
        });
    }
    else {
      this.toolsList = JSON.parse(this.toolsList) ? JSON.parse(this.toolsList) : [];
    }

    // if (this.toolsList && this.toolsList.length > 0) {
    const defaultSubTab = {
      toolName: 'Tools : Detailed View',
      toolUrl: '/tools'
    };
    this.toolsList.unshift(defaultSubTab);
    let toolsLength = 5;
    const toolsTabIndex = this.mainTabs.findIndex((mainTab) => (mainTab.mainTabName === 'Tools' || mainTab.mainTabName === 'SC Apps'));
    this.mainTabs[toolsTabIndex].tabGroups.push({
      tabGroupName: 'TOOLS',
      tabGroupTooltip: '',
      tabGroupUrl: '',
      isTabGroupVisible: true,
      subTabs: []
    });
    this.toolsList.forEach((tool: any, index: any) => {
      if (index === toolsLength) {
        this.mainTabs[toolsTabIndex].tabGroups.push({
          tabGroupName: '',
          tabGroupTooltip: '',
          tabGroupUrl: '',
          isTabGroupVisible: true,
          subTabs: []
        });
        toolsLength = 5 * this.mainTabs[toolsTabIndex].tabGroups.length;
      }
      if (index < toolsLength) {
        this.mainTabs[toolsTabIndex].tabGroups[this.mainTabs[toolsTabIndex].tabGroups.length - 1].subTabs.push({
          subTabName: tool.toolName,
          subTabTooltip: tool.toolName,
          isSubTabVisible: true,
          subTabUrl: tool.toolUrl
        });
      }
    });
    // }
  }

  displaySelectedTabName() {
    if (this.mainTabs[Number(window.sessionStorage.getItem('selectedMainMenuIndex'))]) {
      if (this.mainTabs[Number(window.sessionStorage.getItem('selectedMainMenuIndex'))].tabGroups.length === 0) {
        return this.mainTabs[Number(window.sessionStorage.getItem('selectedMainMenuIndex'))].mainTabName;
      }
      else {
        return this.mainTabs[Number(window.sessionStorage.getItem('selectedMainMenuIndex'))].
          tabGroups[Number(window.sessionStorage.getItem('selectedTabMenuGrpIndex'))]
          .subTabs[Number(window.sessionStorage.getItem('selectedSubMenuIndex'))].subTabName;
      }
    }
  }



  displaySelectedSuplier() {
    if (this.mainTabs[Number(window.sessionStorage.getItem('selectedMainMenuIndex'))] &&
      this.mainTabs[Number(window.sessionStorage.getItem('selectedMainMenuIndex'))].mainTabName !== 'Admin') {
      return this.encDecSrvice.get(window.sessionStorage.getItem('selectedSupplier'));
    }
    else {
      return 'ALL';
    }
  }

  onClickReportIssues(val: any) {
    this.onClickFilterPanel('close');
    this.onClickMenuPanel('close');
    this.openReportIssuesPopup = val === 'open' ? true : false;
  }

  onClickQuickTour(val: any) {
    if (val === 'open') {
      this.isHelpPageOpened = true;
    }
    else if (val === 'sucm-quick-tour') {
      this.isHelpPageOpened = false;
      this.sharedService.setQuickTour(window.location.href.includes('watchlist') ? 'watchlist-quick-tour' : 'sucm-quick-tour');
    }
    else if (val === 'close') {
      this.isHelpPageOpened = false;
    }
  }

  onClickMyCompany() {
    window.localStorage.setItem('redirectedFrom-To', 'SCW Home-Collaboration');
    this.sharedService.setRedirectionUrl('supplier-profile');
  }

  onClickHomePage() {
    if (this.loggedInUser.role.value !== 'sucm_res') {
      window.localStorage.setItem('redirectedFrom-To', 'SCW Home-SCW Home');
      this.sharedService.setRedirectionUrl('supplier-home');
    }
    else {
      if (this.router.url.includes('supplier-home') && !this.isUserRestricted) {
        window.localStorage.setItem('redirectedFrom-To', 'SCW Home-SCW Home');
        this.sharedService.setRedirectionUrl('sucm-home/my-view');
      }
      else if (this.router.url.includes('supplier-home') && this.isUserRestricted) {
        window.localStorage.setItem('redirectedFrom-To', 'SCW Home-SCW Home');
        this.sharedService.setRedirectionUrl('general-user-home');
      }
    }
  }

  showDataIntegrationIcon() {
    if (this.loggedInUser.role.value !== 'scw_supplier_user_res') {
      if (this.router.url.includes('supplier-home') || this.router.url.includes('sucm-home/my-view')
        || this.router.url.includes('sucm-home/overall-view')) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  }

  onClickDataIntegrationIcon(val: any) {
    if (val === 'open') {
      this.dataRefreshGridConfig.rowData = [];
      this.isDataRefreshPopup = true;
      this.loaderService.openLoader();
      this.cepmService.getSchedularDetails().subscribe({
        next: (respData: any) => {
          this.loaderService.closeLoader();
          if (respData && respData.length > 0) {
            this.dataRefreshGridConfig.rowData = respData;
            this.dataRefreshGrid.api.sizeColumnsToFit();
          }
        },
        error: (err: any) => {
          this.loaderService.closeLoader();
          this.openConfirmationPopup('Error', err.error.errorMsg, false);
        }
      });
    }
    else {
      this.isDataRefreshPopup = false;
    }
  }
  redirectToSCWMetrics() {
    window.open("https://tableau.cisco.com/#/site/SWSC/workbooks/124167/views");
  }
  onClickQuickAccessTools() {
    window.localStorage.setItem('redirectedFrom-To', 'SCW Home-Tools');
    this.sharedService.setRedirectionUrl('tools');
  }

  onClickEventsTimeline() {
    window.localStorage.setItem('redirectedFrom-To', 'SCW Home-Collaboration');
    this.sharedService.setRedirectionUrl('events/event-timeline');
  }

  addNotesToSuplier(value: any) {
    if (value === 'open') {
      this.suppliers = [];
      this.supplierNotes = '';
      this.viewSupplierNote = false;
      this.addSupplierNotes = true;
      this.supplierList.forEach((s: any) => {
        const updatedSupplier: any = {};
        updatedSupplier.supplierName = s.supplierName.indexOf('$ ') === 0 ? s.supplierName.replace('$ ', '').trim()
          : (s.supplierName.indexOf('! ') === 0 ? s.supplierName.replace('! ', '').trim() : s.supplierName.trim());
        this.suppliers.push(updatedSupplier);
      });

      this.mentionConfig = {
        items: this.suppliers,
        labelKey: 'supplierName',
        triggerChar: '@',
        searchKey: 'supplierName',
        mentionSelect: item => {
          return item.supplierName;
        },
      }

      if (Object.keys(this.selectedSupplierNote).length > 0) {
        this.enableSupplierNote = this.selectedSupplierNote.enableSupplierNote;
        this.supplierNotes = this.selectedSupplierNote.supplierNote;
      }
    }
  }

  saveSupplierNote(val: any) {
    if (val === 'cancel') {
      this.enableSupplierNote = 'N';
      this.supplierNotes = '';
      this.addSupplierNotes = false;
      this.viewSupplierNote = false;
      if (Object.keys(this.selectedSupplierNote).length > 0) {
        this.supplierNotes = this.selectedSupplierNote.supplierNote;
        this.viewSupplierNote = true;
      }
    }
    else {
      let supplierName = '';
      if (this.loggedInUser.role.value === 'scw_supplier_user_res') {
        supplierName = this.loggedInUser.mcoCompany.split('-RFQ')[0];
      }
      else {
        supplierName = this.encDecSrvice.get(window.localStorage.getItem('selectedSupplier'));
      }

      const requestObj: any = {
        enableSupplierNote: this.enableSupplierNote,
        supplierName: supplierName,
        supplierNote: this.supplierNotes
      }

      this.loaderService.openLoader();
      this.supplierService.saveSupplierNotes(requestObj).subscribe({
        next: (respData: any) => {
          this.loaderService.closeLoader();
          if (Object.keys(respData).length > 0 && respData.status === 'S') {
            this.viewSupplierNote = requestObj.enableSupplierNote === 'Y' ? true : false;
            this.getSupplierNote(requestObj);
            this.openConfirmationPopup('Confirmation', 'Supplier Note saved Sucessfully', false);
          }
          else if (Object.keys(respData).length > 0 && respData.status === 'E' && respData.errorMsg) {
            this.openConfirmationPopup('Error', respData.errorMsg, false);
          }
          else {
            this.openConfirmationPopup('Error', 'Error while saving supplier note', false);
          }
        },
        error: (err: any) => {
          this.loaderService.closeLoader();
          this.openConfirmationPopup('Error', err.error.errorMsg, false);
        }
      });
    }
  }

  onSupplierNotesChanged(event: any) {
    if (event.text === '\n' && event.text.length === 1) {
      this.supplierNotes = '';
    }
    else if (event.editor.getLength() > 120) {
      event.editor.deleteText(120, event.editor.getLength());
    }
  }

  getSupplierNote(data: any) {
    this.selectedSupplierNote = {};
    this.supplierNotes = '';
    this.addSupplierNotes = false;
    this.viewSupplierNote = false;
    if (data && Object.keys(data).length > 0 && data.supplierNote) {
      this.enableSupplierNote = data.enableSupplierNote;
      this.selectedSupplierNote = data;
      if (data.enableSupplierNote === 'Y' &&
        (this.loggedInUser.role.value === 'scw_supplier_user_res' || this.loggedInUser.role.value === 'scw_admin_res'
          || this.loggedInUser.role.value === 'sucm_res')) {
        this.supplierNotes = data.supplierNote;
        this.addSupplierNotes = false;
        this.viewSupplierNote = true;
      }
    }
  }

  onClickSupplierNote(event: any) {
    if (this.loggedInUser.role.value === 'scw_supplier_user_res') {
      event.stopPropogation();
    }
    else if ((this.loggedInUser.role.value === 'scw_admin_res' || this.loggedInUser.role.value === 'sucm_res')
      && ((event.target.parentElement.href && event.target.parentElement.href.includes('/supplier-home'))
        || (event.target.href && event.target.href.includes('/supplier-home')))) {
      this.onSelectSupplier({ supplierName: event.target.outerText });
      event.stopPropogation();
    }
  }

  openTimeOutPopup(msg: any) {
    this.timeoutModalRefBox = this.modalService.open(TimeoutComponent, {
      centered: true,
      windowClass: 'alertModalWindowClass',
      backdrop: 'static',
      keyboard: false,
    });
    this.timeoutModalRefBox.componentInstance.message = msg;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.browserScreenHeight = window.innerHeight;
  }

  setHeightOfSupplierList() {
    let supplierListHeight = this.browserScreenHeight;
    const mainHeaderHeight: number = $('.app-header').innerHeight() ? $('.app-header').innerHeight() : 0;
    const footerHeight: number = $('.app-footer').innerHeight() ? $('.app-footer').innerHeight() : 0;
    supplierListHeight = Math.ceil(this.browserScreenHeight) - (45 + 45 + 65 + 65 + 65 + Math.ceil(mainHeaderHeight) + Math.ceil(footerHeight));
    // return supplierListHeight - 20;
    return supplierListHeight
  }

  setMainContainerHeight() {
    let mainContentHeight = this.browserScreenHeight;
    let mainHeaderHeight: number = $('.app-header').innerHeight() ? $('.app-header').innerHeight() : 0;
    let breadcrumHeight: number = $('#breadcrumb').innerHeight() ? $('#breadcrumb').innerHeight() : 0;
    
    const footerHeight: number = $('.app-footer').innerHeight() ? $('.app-footer').innerHeight() : 0;
    mainContentHeight = Math.ceil(this.browserScreenHeight) - (Math.ceil(mainHeaderHeight) + Math.ceil(footerHeight));
    // mainContentHeight = mainContentHeight - 20;
    window.sessionStorage.setItem('mainContentHeight', mainContentHeight);
    return mainContentHeight;
  }

  ngAfterViewInit() {
    this.cdRef.detectChanges();
  }

  ngOnDestroy() {
  }

  openConfirmationPopup(title: any, msg: any, flag: any) {
    this.modalRefBox = this.modalService.open(ConfirmationComponent, {
      centered: true,
      windowClass: 'alertModalWindowClass',
      backdrop: 'static',
      keyboard: false,
    });
    this.modalRefBox.componentInstance.title = title;
    this.modalRefBox.componentInstance.message = msg;
    this.modalRefBox.componentInstance.showCancelButton = flag;
  }

  setupActivityListener() {
    ['click', 'mousemove', 'keydown', 'scroll', 'touchstart'].forEach((event) => {
      document.body.addEventListener(event, () => {
        localStorage.setItem('lastActiveTime', new Date().toISOString());
        this.reset();
      });
    });
  }

  onMouseEnter(mainTab: MainMenuTab) {
    const mainMenuIndex = this.mainTabs.findIndex((mainMenuTab) => mainTab.mainTabName === mainMenuTab.mainTabName);
    this.selectedMainMenuIndex = mainMenuIndex;
    // if (mainTab.mainTabUrl) {
    //   window.localStorage.setItem('type', 'login');
    //   window.sessionStorage.setItem('selectedMainMenuIndex', this.selectedMainMenuIndex);
    //   this.selectedTabMenuGrpIndex = 0;
    //   window.sessionStorage.setItem('selectedTabMenuGrpIndex', this.selectedTabMenuGrpIndex);
    //   this.selectedSubMenuIndex = 0;
    //   window.sessionStorage.setItem('selectedSubMenuIndex', this.selectedSubMenuIndex);
    //   this.onClickMenuPanel('close');
    //   this.router.navigate([mainTab.mainTabUrl]);
    // }
    this.isHovering = true;
  }

  // Method to handle mouse leave event
  onMouseLeave() {
    this.selectedMainMenuIndex = Number(window.sessionStorage.getItem('selectedMainMenuIndex'));
    this.isHovering = false;
    this.isUserPersonaHovering = false;
  }

  generateBreadcrumbs(mainTabs: any[], data?: any) {
    let breadcrumbs = [];

    // Find the main tab
    const selectedMainMenuIndex2 = Number(window.sessionStorage.getItem('selectedMainMenuIndex'));
    const selectedTabMenuGrpIndex2 = Number(window.sessionStorage.getItem('selectedTabMenuGrpIndex'));
    const selectedSubMenuIndex2 = Number(window.sessionStorage.getItem('selectedSubMenuIndex'));
    const selectedSupplier = this.encDecSrvice.get(window.localStorage.getItem('selectedSupplier'));
    if ((selectedMainMenuIndex2 === 0 || mainTabs[selectedMainMenuIndex2].labelName === 'Home') && selectedSupplier === 'ALL') {
      breadcrumbs.push({ label: 'Home', url: mainTabs[0].mainTabUrl });
      return breadcrumbs;
    } else if(selectedSupplier === 'ALL'){
     breadcrumbs.push({ label: 'Home', url: mainTabs[0].mainTabUrl });

      const mainTab = mainTabs.find((tab, indexMain) => indexMain === selectedMainMenuIndex2);
      if (mainTab) {
        if(mainTab.labelName !== 'Home' || mainTab.mainTabUrl){
        breadcrumbs.push({ label: mainTab.labelName, url: mainTab.mainTabUrl });
        }
        // Find the tab group
        const tabGroup = mainTab?.tabGroups.find((group, indexGroup) => indexGroup === selectedTabMenuGrpIndex2);
        if (tabGroup?.tabGroupName) {
          if (tabGroup.tabGroupName != 'Questionnaire' && tabGroup.tabGroupName != 'Incident Tracker' &&
            tabGroup.tabGroupName != 'Contacts' &&
            tabGroup.tabGroupName != 'PERFORMANCE SCORECARD'
            && tabGroup.tabGroupName != 'TOOLS'
            && tabGroup.tabGroupName != 'Trainings'
            && tabGroup.tabGroupName != 'Messages'
          && tabGroup.tabGroupName !='  '&& tabGroup.tabGroupName !='   ' ) {
            breadcrumbs.push({ label: tabGroup.tabGroupName, url: tabGroup.tabGroupUrl });
          }

          // Find the sub tab
          const subTab = tabGroup.subTabs.find((sub, indexSub) => indexSub === selectedSubMenuIndex2);
          const href = window.location.href;
          const url = new URL(href);
          const pathname = url.pathname.endsWith('/') ? url.pathname.slice(0, -1) : url.pathname;
          const segments = pathname.split('/');
          let lastSegment = segments.pop();
          if (data) {
            lastSegment = data;
          }

          const subtabUrl = tabGroup.subTabs.find((sub, indexSub) => sub.subTabUrl.includes(lastSegment));

          if (subTab && subtabUrl.subTabUrl.includes(lastSegment)) {
            breadcrumbs.push({ label: subtabUrl.subTabName, url: subTab.subTabUrl });
          }
        }
      }
      return breadcrumbs;      
    }else{
      //breadcrumbs.push({ label: 'Home', url: '/supplier-new-home' });
      breadcrumbs = [];
      if(this.loggedInUser?.role?.value === 'scw_supplier_user_res'){
        breadcrumbs.push({ label: 'Home', url: '/supplier-new-home' });
        if(this.router.url.includes('supplier-new-home') ){
          return breadcrumbs;
        }

      }else{
        breadcrumbs.push({ label: selectedSupplier + ' '  +'Home', url: '/supplier-new-home' });

      }

      const mainTab = mainTabs.find((tab, indexMain) => indexMain === selectedMainMenuIndex2);
      if (mainTab) {
        if(mainTab.labelName !== 'Home'){
        breadcrumbs.push({ label: mainTab.labelName, url: mainTab.mainTabUrl });
        }

        // Find the tab group
        const tabGroup = mainTab.tabGroups.find((group, indexGroup) => indexGroup === selectedTabMenuGrpIndex2);
        if (tabGroup.tabGroupName) {
          if (tabGroup.tabGroupName != 'Questionnaire' && tabGroup.tabGroupName != 'Incident Tracker' &&
            tabGroup.tabGroupName != 'Contacts' &&
            tabGroup.tabGroupName != 'PERFORMANCE SCORECARD'
            && tabGroup.tabGroupName != 'TOOLS'
            && tabGroup.tabGroupName != 'Trainings'
            && tabGroup.tabGroupName != 'Messages') {
            breadcrumbs.push({ label: tabGroup.tabGroupName, url: tabGroup.tabGroupUrl });
          }

          // Find the sub tab
          const subTab = tabGroup.subTabs.find((sub, indexSub) => indexSub === selectedSubMenuIndex2);
          const href = window.location.href;
          const url = new URL(href);
          const pathname = url.pathname.endsWith('/') ? url.pathname.slice(0, -1) : url.pathname;
          const segments = pathname.split('/');
          let lastSegment = segments.pop();
          if (data) {
            lastSegment = data;
          }

          const subtabUrl = tabGroup.subTabs.find((sub, indexSub) => sub.subTabUrl.includes(lastSegment));

          if (subTab && subtabUrl.subTabUrl.includes(lastSegment)) {
            breadcrumbs.push({ label: subtabUrl.subTabName, url: subTab.subTabUrl });
          }
        }
      }

      return breadcrumbs;
    }
  }

  getTagLineLookupValues() {
    this.lookupService.getDropdownLookup('SCW_SUPP_TAG_LINE')
      .subscribe({
        next: (respData: any) => {
          this.SCWTagLine = respData.lookupArray[0].description;
        },
        error: (error: any) => {
          this.openConfirmationPopup('Error', error.error.errorMsg, false);
        }
      });
  }
  onMouseEnterPersona(flag: any) {
    this.isUserPersonaHovering = flag;
  }

  onClickMyCompanyFromPersona() {
    window.localStorage.setItem('redirectedFrom-To', 'SCW Home-Collaboration');
    window.localStorage.setItem('site-level-view', 'true');
    this.sharedService.setRedirectionUrl('contact-management');
  }

  addLink(obj: any): void {
    const existingIndex = this.visitedLinks.findIndex(link => link.linkUrl === obj.linkUrl);

    const visitedLinksString = sessionStorage.getItem('visitedLinks');
    if (visitedLinksString) {
      this.visitedLinks = JSON.parse(visitedLinksString);
    }
    if (existingIndex !== -1) {
      this.visitedLinks.splice(existingIndex, 1);
    }
    if (obj.linkUrl.startsWith('/') && obj.linkName !== 'My Company') {
      this.visitedLinks.unshift(obj);
      this.visitedLinks = this.visitedLinks.slice(0, this.maxLinks);
      window.sessionStorage.setItem('visitedLinks', JSON.stringify(this.visitedLinks));
    }
  }

  onClickRecentVisits(obj: any) {
    this.isUserPersonaHovering = false;
    window.localStorage.setItem('redirectedFrom-To', 'Home-' + obj.mainTabName);
    this.sharedService.setRedirectionUrl(obj.linkUrl.replace(/\//, ''));
  }

  onRemoveSupplierRecentVisit() {
    if(this.loggedInUser.role.value !== 'scw_supplier_user_res'){
    const visitedLinksString = sessionStorage.getItem('visitedLinks');
      if (visitedLinksString) {
        this.visitedLinks = JSON.parse(visitedLinksString);
        let filteredVisitedLinks = this.visitedLinks.filter(item => item.supplier === 'ALL');
        this.visitedLinks = filteredVisitedLinks.slice(0, this.maxLinks);
        window.sessionStorage.setItem('visitedLinks', JSON.stringify(this.visitedLinks));
      }
    }
  }

  scrollMessage(){
    setTimeout(() => {
      this.dataService.currentData.subscribe((data) => {
        this.scrollMsg = data;
      });
      },500);
  }

  toggleFavorite(breadcrumb: any) {
    const index = this.favorites.findIndex(fav => fav.URL === breadcrumb.url);

    if (index === -1) {
      this.favorites.push({ LABEL: breadcrumb.label, URL: breadcrumb.url });
      this.showToast('Added to favorites');

    } else {
      this.favorites.splice(index, 1);
      this.showToast('Removed from favorites');

    }

    // Save favorites to local storage or backend
    this.saveFavorites();
  }

  isFavorite(breadcrumb: any): boolean {
    return this.favorites.some(fav => fav.URL === breadcrumb.url);
  }

  saveFavorites() {
    localStorage.setItem('favorites', JSON.stringify(this.favorites));
    this.getFavorites();
    this.addFavorites();

  }

  addFavorites(){
    this.navigationService.updateFavorites(this.favorites)
    .subscribe({
      next: async (respData: any) => {
          if (respData && respData.length > 0) {
           
          }
      },
      error: (err: any) => {
        this.loaderService.closeLoader();
        this.openConfirmationPopup('Error', err.error.errorMsg, false);
      }
    });
  }

  loadFavorites() {
    if(this.loggedInUser.role.value !== 'scw_supplier_user_res'){
    this.navigationService.geFavorites()
    .subscribe({
      next: async (respData: any) => {
          if (respData && respData.length > 0) {
            this.favorites = respData;            
             this.getFavorites();            
          }
      },
      error: (err: any) => {
        this.loaderService.closeLoader();
        this.openConfirmationPopup('Error', err.error.errorMsg, false);
      }
    });

  }

  }

  getFavorites() {
    let toolsLength = 5;
    const toolsTabIndex = this.mainTabs.findIndex((mainTab) => (mainTab.mainTabName === 'Favorites'));
   if (this.mainTabs[toolsTabIndex] && this.mainTabs[toolsTabIndex].tabGroups) {
     this.mainTabs[toolsTabIndex].tabGroups[this.mainTabs[toolsTabIndex].tabGroups.length - 1].subTabs = [];
   }
   if (toolsTabIndex === -1) {
      return;
    }
  //  this.mainTabs[toolsTabIndex].tabGroups.push({
  //   tabGroupName: '',
  //   tabGroupTooltip: '',
  //   tabGroupUrl: '',
  //   isTabGroupVisible: true,
  //   subTabs: []
  // });

  this.mainTabs[toolsTabIndex].tabGroups = [{
    tabGroupName: '',
    tabGroupTooltip: '',
    tabGroupUrl: '',
    isTabGroupVisible: true,
    subTabs: []
}];
    this.favorites.forEach((tool: any, index: any) => {
      if ((index === toolsLength)) {
        this.mainTabs[toolsTabIndex].tabGroups.push({
          tabGroupName: '',
          tabGroupTooltip: '',
          tabGroupUrl: '',
          isTabGroupVisible: true,
          subTabs: []
        });
        toolsLength += 5;
      }
        

        //toolsLength = 5 * this.mainTabs[toolsTabIndex].tabGroups.length;
      
      if (index < toolsLength) {
          this.mainTabs[toolsTabIndex].tabGroups[this.mainTabs[toolsTabIndex].tabGroups.length - 1].subTabs.push({
          subTabName: tool.LABEL,
          subTabTooltip: tool.LABEL,
          isSubTabVisible: true,
          subTabUrl: tool.URL
        });
      }
    });

  }

  findMatchingTabByUrl(url: string) {
    for (let mainTab of this.mainTabs) {
        for (let tabGroup of mainTab.tabGroups) {
            for (let subTab of tabGroup.subTabs) {
                if (subTab.subTabUrl === url) {
                    return { mainTab, tabGroup, subTab };
                }
            }
        }
    }
    return null;
}

navigateToTab(mainTab: MainMenuTab, tabGroup: TabMenuGroup, subTab: SubMenuTabs) {
  const mainMenuIndex = this.mainTabs.findIndex((mainMenuTab) => mainTab.mainTabName === mainMenuTab.mainTabName);
  this.selectedMainMenuIndex = mainMenuIndex;
  window.sessionStorage.setItem('selectedMainMenuIndex', this.selectedMainMenuIndex);

  const tabGroupIndex = this.mainTabs[this.selectedMainMenuIndex].tabGroups.findIndex((tabGrp) => tabGroup.tabGroupName === tabGrp.tabGroupName);
  this.selectedTabMenuGrpIndex = tabGroupIndex;
  window.sessionStorage.setItem('selectedTabMenuGrpIndex', this.selectedTabMenuGrpIndex);

  const subMenuIndex = this.mainTabs[this.selectedMainMenuIndex].tabGroups[tabGroupIndex].subTabs.findIndex((sTab) => sTab.subTabName === subTab.subTabName);
  this.selectedSubMenuIndex = subMenuIndex;
  window.sessionStorage.setItem('selectedSubMenuIndex', this.selectedSubMenuIndex);

  const selectedSupplier = this.mainTabs[this.selectedMainMenuIndex].labelName === 'Admin' ? 'ALL' : this.selectedSupplier;
  window.localStorage.setItem('selectedSupplier', this.encDecSrvice.set(selectedSupplier));
  window.sessionStorage.setItem('selectedSupplier', this.encDecSrvice.set(selectedSupplier));

  this.onClickMenuPanel('close');

  let currentUrl = this.router.url;
  if (currentUrl === subTab.subTabUrl) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate([currentUrl]);
      });
  } else {
      this.router.navigateByUrl('/').then(() => {
          this.router.navigate([subTab.subTabUrl]);
      });
  }
}

showToast(message: string) {
this.snackBar.open(message, '', {
  duration: 2000,
  horizontalPosition: 'left',
  verticalPosition: 'top',
  panelClass: ['custom-snackbar']
});
}

}
