import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, from, of, throwError } from 'rxjs';
import { catchError, concatMap, delay, retryWhen, switchMap } from 'rxjs/operators';
import { RouterConstants } from './constants/router-constants';
import { SplunkLogsService } from './services/splunkLogs.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { SharedService } from './shared-service/shared.service';
import { Router } from '@angular/router';
import { EncrDecrService } from './services/encrypt-decrypt.service';
import { AuthService } from './auth/auth.service';

@Injectable()
export default class ApiInterceptor implements HttpInterceptor {
    customError: any = { error: {} };
    constructor(
        private routerConstant: RouterConstants,
        private splunkLogsService: SplunkLogsService,
        private sharedService: SharedService,
        private oauthService: OAuthService,
        private router: Router,
        private encDecSrvice: EncrDecrService,
        private authService: AuthService
    ) { }

    retryCount: any = 3;

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const loggedInUser = this.routerConstant.getLoggedInUser();
        const ipifyUrl = req.url.split('/');
        //const modifiedUrl = req?.url.includes("https://int-id.cisco.com") ? req.url : (window.location.hostname.includes('localhost') ? `http://localhost:8080/gsmscw/api${req.url}` : `/gsmscw/api${req.url}`);
        const modifiedUrl = req.url;
        const authToken = this.oauthService.getAccessToken();//window.localStorage.getItem('adata');
        let cloneReq;

        const regionCode = window.localStorage.getItem('regionCode');
        const countryCode = window.localStorage.getItem('countryCode');
        const continentCode = window.localStorage.getItem('continentCode');        

        if ((ipifyUrl[2] === 'jsonip.com') || (ipifyUrl[2] === 'ipapi.co')) {
            cloneReq = req.clone({
                url: req.url
            });
        } else {
            cloneReq = req.clone({
                setHeaders: {
                    emailId: loggedInUser ? loggedInUser.email : '',
                    userId: loggedInUser ? loggedInUser.userId : '',
                    role: loggedInUser ? loggedInUser.role.value : '',
                    regionCode: regionCode ? regionCode : '',
                    countryCode: countryCode ? countryCode : '',
                    continentCode: continentCode ? continentCode : '',
                    Authorization:  req?.url.includes("https://int-id.cisco.com") ? '' : (authToken ? `Bearer ${this.oauthService.getAccessToken()}` : ''),
                    isWhitepaperAdmin: loggedInUser ? loggedInUser.isWhitepaperAdmin : 'N'
                   // Authorization:  req?.url.includes("https://int-id.cisco.com") ? '' : (authToken ? `Bearer ${this.encDecService.get(authToken)}` : '')
                },
                url: modifiedUrl
            });
        }
        const apiReq = cloneReq;

        return next.handle(apiReq).pipe(
            retryWhen(errors =>
                errors.pipe(
                    concatMap((error, count) => {
                        if (count < this.retryCount && error.status === 504) {
                            return of(error);
                        }
                        return throwError(error);
                    }),
                    delay(500)
                )
            ),
            catchError((err: any) => {
                if (err instanceof HttpErrorResponse && err.status === 504) {
                    // server-side error
                    this.addSplunkLog(err, req);
                    //this.customError.error.errorMsg = 'Connection Timed Out. Kindly reload the page to view data.';
                    this.customError.error.errorMsg = "";
                    setTimeout(() => {
                    const errorMsgElementsContent = document.querySelectorAll('.modal-content');
                    const errorMsgElementsDialog = document.querySelectorAll('.modal-dialog');
                    errorMsgElementsDialog.forEach((element) => {
                        element.classList.add('new-error-class');
                        (element as HTMLElement).style.maxWidth = '700px';
                      });
                      errorMsgElementsContent.forEach((element) => {
                        element.classList.add('new-error-class-content');
                        (element as HTMLElement).style.height = '500px';
                      });

                    let errMessage = document.getElementsByClassName('line-broken-msg') as HTMLCollection;
                    if (errMessage.length > 0) {
                        errMessage[0].innerHTML = "<p>We've attempted to refresh the page several times, but it seems there's still an issue.</p>"
                        + "<p>We apologize for the inconvenience and appreciate your patience. Here's what you can do next:</p>"

                       +"<ol><li><strong>Check Your Connection:</strong> Ensure your internet connection is stable and active.</i>"
                        
                        +"<li><strong>Clear Your Browser Cache:</strong> Sometimes old data can cause loading issues. Clearing your browser cache may resolve this problem.</li>"
                        
                        +"<li><strong>Try a Different Browser:</strong> Occasionally, browser-specific issues can affect page loading. If possible, open the application in a different web browser.</li>"
                        
                        +"<li><strong>Disable Browser Extensions:</strong> Some browser extensions can interfere with website functionality. Try disabling them to see if that helps.</li>"
                        
                        + "<li><strong>Visit Later:</strong> We might be experiencing high traffic or technical difficulties. You may want to try again in a few minutes.</li></ol>"
                        
                        + "<p>If you need immediate assistance or if the issue persists, please contact our support team at <a href='mailto:scw_support@cisco.com'>[scw_support@cisco.com]</a></p>"
                        
                        +"We're here to help!";
                    }                   

                },300);              

                return throwError(this.customError);
                
                } else {
                    console.log(err)
                    if(err.error.errorMsg === 'Session Expired') {
                        console.log('Session Expired');                   
                    }else {
                        // client-side error
                        return throwError(err);
                    }
                }
            })
        );
    }

    addSplunkLog(err: any, req: any) {
        const requestObj: any = {
            subLogType: 'gatewayTimeout',
            message: 'RequestTimeout',
            errorCode: err.status,
            serviceName: req.url
        };
        this.splunkLogsService.additionalLogs(requestObj).subscribe();
    }
}
