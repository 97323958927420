<mat-toolbar class="app-header">
  <!-- main header starts -->
  <mat-toolbar-row class="header">
    <span class="icon-cisco header-logo" style="color: #00BCEB !important;"></span>
    <span class="header-logo">Supplier Connection Workbench</span>
    <span class="example-spacer"></span>
    <ng-template [ngIf]="isLoggedIn">
      <span style="font-size: 12px;color: #212529;">Welcome, {{loggedInUser?.fullName}},
        {{loggedInUser?.role?.name}}
      </span>
      <button mat-mini-fab *ngIf="loggedInUser?.role?.value === 'scw_supplier_user_res'" class="secondary"
        matTooltip="SCW Home Page" matTooltipClass="cisco-tooltip" matTooltipPosition="right"
        (click)="onClickHomePage()">
        <span class="icon-home icon-small"></span>
      </button>
      <button mat-mini-fab *ngIf="loggedInUser?.role?.value === 'gsm_general_use' || loggedInUser?.role?.value === 'SCO_scw_general_user_res'
        || loggedInUser?.role?.value === 'sucm_res' || loggedInUser?.role?.value === 'comm_dir_res'" class="secondary"
        matTooltip="Quick Tour" matTooltipClass="cisco-tooltip" matTooltipPosition="right"
        (click)="onClickQuickTour('open')">
        <span class="icon-touch-point icon-small"></span>
      </button>
      <!-- <button mat-mini-fab *ngIf="loggedInUser?.role?.value === 'scw_supplier_user_res'" class="secondary"
        matTooltip="My Company" matTooltipClass="cisco-tooltip" matTooltipPosition="right" (click)="onClickMyCompany()">
        <span class="icon-profile-settings icon-small"></span>
      </button> -->
      <button mat-mini-fab class="success" matTooltipClass="cisco-tooltip" matTooltip="Report Issues/Provide Feedback"
        matTooltipPosition="right" (click)="onClickReportIssues('open')">
        <span class="icon-help-outline icon-small"></span>
      </button>
      <button mat-mini-fab class="primary" matTooltipClass="cisco-tooltip" matTooltip="Logout"
        matTooltipPosition="right" (click)="logout()">
        <span class="icon-sign-out icon-small"></span>
      </button>
    </ng-template>
  </mat-toolbar-row>
  <!-- main header ends -->
  
  <!-- sub header starts -->
  <mat-toolbar-row class="selectedTabHeader" *ngIf="isLoggedIn">
    <button mat-icon-button matTooltip="Toggle Filter Panel" matTooltipClass="cisco-tooltip" matTooltipPosition="right"
      *ngIf="loggedInUser?.role?.value !== 'scw_supplier_user_res'"
      (click)="onClickFilterPanel(isFilterOpened?'close':'open')">
      <span [ngClass]="isFilterOpened?'icon-close':'icon-filter'" style="color: #ffffff !important;"></span>
    </button>
    <button mat-icon-button matTooltip="Toggle Menu Panel" matTooltipClass="cisco-tooltip" matTooltipPosition="right"
      (click)="onClickMenuPanel(isMenuOpened?'close':'open')">
      <span [ngClass]="isMenuOpened?'icon-close':'icon-toggle-menu'" style="color: #ffffff !important;"></span>
    </button>
    <span class="selected-supplier-header" *ngIf="router.url !== '/' && router.url !== '/logout'">
      {{displaySelectedSuplier()}} : {{displaySelectedTabName()}}
    </span>
    <span class="example-spacer"></span>
    <button mat-mini-fab class="white" matTooltip="Supplier Notes" matTooltipClass="cisco-tooltip"
      *ngIf="showHideSupplierIcons('supplier-notes')" matTooltipPosition="right" (click)="addNotesToSuplier('open')">
      <span class="icon-notes icon-small"></span>
    </button>
    <button mat-mini-fab class="white" matTooltip="Feedback" matTooltipClass="cisco-tooltip"
      *ngIf="showHideSupplierIcons('feedback')" matTooltipPosition="right">
      <a href="https://app.smartsheet.com/b/form/b43f4673683f4a0c8b2784b0cf1c771c" target="_blank">
        <span class="icon-group-chat icon-small"></span>
      </a>
    </button>
    <button mat-mini-fab class="white" *ngIf="showHideSupplierIcons('email')" matTooltipPosition="right"
      matTooltip="For any queries, please reach out to team ( {{getSupportMail()}} )" matTooltipClass="cisco-tooltip">
      <a href="mailto:{{getSupportMail()}}" target="_blank">
        <span class="icon-email icon-small"></span>
      </a>
    </button>
    <button mat-mini-fab class="white" matTooltip="Event Timeline" matTooltipPosition="right"
      *ngIf="router.url.includes('supplier-home')" matTooltipClass="cisco-tooltip">
      <a (click)="onClickEventsTimeline()">
        <span class="icon-calendar icon-small"></span>
      </a>
    </button>
    <button mat-mini-fab class="white" matTooltip="Tools Quick Access" matTooltipPosition="right"
      *ngIf="showHideSupplierIcons('tools')" matTooltipClass="cisco-tooltip">
      <a (click)="onClickQuickAccessTools()">
        <span class="icon-tools icon-small"></span>
      </a>
    </button>
    <button mat-mini-fab class="white" matTooltip="Data Refresh Status" matTooltipPosition="right"
      *ngIf="showDataIntegrationIcon()" matTooltipClass="cisco-tooltip">
      <a (click)="onClickDataIntegrationIcon('open')">
        <span class="icon-backup-data icon-small"></span>
      </a>
    </button>
    <button mat-mini-fab class="white" matTooltip="Read Me" matTooltipPosition="right"
      *ngIf="showHideSupplierIcons('read-me')" matTooltipClass="cisco-tooltip">
      <a (click)="onClickInfoIcon('open')">
        <span class="icon-info icon-small"></span>
      </a>
    </button>
    <button *ngIf="loggedInUser?.role?.value !== 'scw_supplier_user_res'" mat-mini-fab class="white"
      matTooltip="SCW Adoption Metrics" matTooltipPosition="right" matTooltipClass="cisco-tooltip">
      <a (click)="redirectToSCWMetrics()">
        <span class="icon-analysis icon-small"></span>
      </a>
    </button>
    <button mat-mini-fab class="white" matTooltip="Home" matTooltipPosition="right"
      *ngIf="showHideSupplierIcons('home-icon') && loggedInUser?.role?.value !== 'scw_supplier_user_res'">
      <span class="icon-home icon-small" (click)="onClickHomePage()"></span>
    </button>
  </mat-toolbar-row>
  <!-- sub header starts -->
  <mat-toolbar-row id="restricted-supplier-watermark" class="restricted-supplier-watermark" *ngIf="isLoggedIn && loggedInUser?.role?.value !== 'scw_supplier_user_res' && isSupplierRestricted && displaySelectedSuplier() !== 'ALL'
     && !isMenuOpened">
    {{displaySelectedSuplier()}} is a restricted supplier and has No access to SCW Application
  </mat-toolbar-row>
  <!-- supplier code top panel-->
  <div class="toolbar-container">

  <!-- <mat-toolbar-row (mouseenter)="onMouseLeave()" class="header-supplaier" *ngIf="loggedInUser?.role?.value === 'scw_supplier_user_res' || loggedInUser?.role?.value === 'sucm_res' || loggedInUser?.role?.value === 'socm_res' || loggedInUser?.role?.value === 'comm_dir_res' || loggedInUser?.role?.value === 'SCO_scw_general_user_res' || loggedInUser?.role?.value === 'gsm_general_use' || loggedInUser?.role?.value === 'scw_admin_res'">
    <span class="icon-cisco header-logo" style="color: #ffffff !important;font-size: 30px !important;"></span>

    <div class="header-content-supplier col-9">
      <span class="header-logo-supplier" style="color: #ffffff !important;">Supplier Connection Workbench</span>

      <span style="color: #ffffff !important; font-size: 12px !important;padding-left: 28px !important;">
        <quill-view-html style="font-size: 2px !important;" [content]="SCWTagLine ? SCWTagLine: ''"></quill-view-html>

      </span>

  </div>
  <div class="right-aligned-container">
  <span    (mouseenter)="onMouseEnterPersona(false)"
  *ngIf="isLoggedIn && loggedInUser?.role?.value === 'scw_supplier_user_res'" style="color: #ffffff !important; font-size: 12px !important;text-align: right !important;margin-right: 16px !important;">{{displaySelectedSuplier().replace('-RFQ', '')}}</span>
  <span class="material-icons user-icon"  *ngIf="isLoggedIn"      
   (mouseenter)="onMouseEnterPersona(true)"
    style="color:  lightgray !important;font-size: 36px !important;padding-right: 8px !important;">
    account_circle
    </span>
  </div>


</mat-toolbar-row> -->

<div class="user-persona" *ngIf="isUserPersonaHovering" (mouseleave)="onMouseEnterPersona(false)" >
  <div style="text-align: center;"><strong  style="font-size: 12px !important;">{{loggedInUser?.fullName}}</strong></div>
  <div style="text-align: center;"><span style="font-size: 12px !important;">{{loggedInUser?.email}}</span></div>
  <div *ngIf="loggedInUser?.role?.value === 'scw_supplier_user_res'" style="text-align: center;border-bottom: 1px solid gray;padding-bottom: 4px;"><span style="font-size: 12px !important;">Component Supplier</span></div>
 <div *ngIf="loggedInUser?.role?.value === 'scw_supplier_user_res'" style="padding-top: 5px;"><span style="font-size: 12px !important;color: #0070d2 !important;cursor: pointer !important;padding-bottom: 2px !important;" (click)="onClickMyCompanyFromPersona()">My Company</span></div>
 <div *ngIf="loggedInUser?.role?.value !== 'scw_supplier_user_res'">
 <ng-container *ngFor="let role of this.loggedInUser?.roles; let i = index" >
  <span style="font-size: 12px !important;">{{ role.name }}</span><ng-container *ngIf="i < this.loggedInUser?.roles.length - 1">, </ng-container>
</ng-container>
</div>
 <div class="recent-links">
  <div>
  <strong  style="font-size: 12px !important;">Recent Visits: </strong>
  </div> 
    <div *ngFor="let link of visitedLinks" style="text-align: left;">
      <span style="font-size: 12px !important;color: #0070d2 !important;cursor: pointer !important;" (click)="onClickRecentVisits(link)">{{link.linkName}}</span>
    </div>
</div>
<div>
  <button class="button-logout" mat-raised-button (click)="logout()">Logout</button>
</div>
</div>
</div>
<!-- supplier code top ends -->

<!-- sucm starts-->


<!-- <div class="user-persona" *ngIf="isUserPersonaHovering" (mouseleave)="onMouseEnterPersona(false)" >
  <div style="text-align: center;"><strong  style="font-size: 12px !important;">{{loggedInUser?.fullName}}</strong></div>
  <div style="text-align: center;"><span style="font-size: 12px !important;">{{loggedInUser?.email}}</span></div>
 <div class="recent-links">
  <div>
  <strong  style="font-size: 12px !important;">Recent Visits: </strong>
  </div> 
    <div *ngFor="let link of visitedLinks" style="text-align: left;">
      <span style="font-size: 12px !important;color: #0070d2 !important;cursor: pointer !important;" (click)="onClickRecentVisits(link)">{{link.linkName}}</span>
    </div>
</div>
<div>
  <button class="button-logout" mat-raised-button (click)="logout()">Logout</button>
</div>
</div> -->

 <!-- suppier menu starts -->
  <!-- <mat-toolbar-row  *ngIf="isLoggedIn && (loggedInUser?.role?.value === 'scw_supplier_user_res' || loggedInUser?.role?.value === 'sucm_res' || loggedInUser?.role?.value === 'socm_res' || loggedInUser?.role?.value == 'comm_dir_res' || loggedInUser?.role?.value === 'SCO_scw_general_user_res' || loggedInUser?.role?.value === 'gsm_general_use' || loggedInUser?.role?.value === 'scw_admin_res')">
    <ul class="main-menu" >
      <span *ngFor="let mainMenu of mainTabs;let menuIndex = index">
        <ng-container *ngIf="mainMenu.isMainTabVisible === true">
        <li class="main-menu-li" *ngIf="mainMenu.mainTabUrl!==''; else NotMainTab"
        [ngClass]="menuIndex === selectedMainMenuIndex?'selected-main-menu':''"
        (mouseenter)="onMouseLeave()"
        (click)="onClickMainMenuTab(mainMenu)">
        {{mainMenu.labelName}}
        </li>
        <ng-template #NotMainTab>
          <li class="main-menu-li" *ngIf="mainMenu?.tabGroups && mainMenu?.tabGroups.length > 0"
            [ngClass]="menuIndex === selectedMainMenuIndex?'selected-main-menu':''"
          (mouseenter)="onMouseEnter(mainMenu)">
          {{mainMenu.labelName}}
  
          </li>
        </ng-template>
      </ng-container>
      </span>
     </ul>
  </mat-toolbar-row>  -->
  
  <!-- supplier menu ends -->


</mat-toolbar>



<!-- Supplier Login menu panel start-->
   
  <div (mouseleave)="onMouseLeave()" *ngIf="isHovering && isLoggedIn" class="top-div" style="width: 99% !important;display: inline-flex !important;overflow-y: auto !important;margin-left: 0.5% !important
  ;margin-right: 0.5%  !important;background-color: #f4f2f2;    border-top: 1px solid #d9d9d9;">
    <ng-container *ngFor="let tabGroup of mainTabs[selectedMainMenuIndex]?.tabGroups;let tabGrpIndex = index">
      
      <ul *ngIf="tabGroup.isTabGroupVisible" class="tab-group-list-supplier">  
        <li class="tab-group-header-supplier" style="padding-bottom: 10px !important;" matTooltip="{{tabGroup.tabGroupTooltip}}" matTooltipClass="cisco-tooltip"
          matTooltipPosition="right"
          *ngIf="tabGroup.tabGroupName && tabGroup.tabGroupName !== 'Questionnaire' && tabGroup.tabGroupName !=='Incident Tracker' &&
          tabGroup.tabGroupName !== 'Contacts' && tabGroup.tabGroupName !== 'PERFORMANCE SCORECARD'
          && tabGroup.tabGroupName !=='TOOLS' && tabGroup.tabGroupName !== 'Trainings' && tabGroup.tabGroupName !== 'Messages'" >
          {{tabGroup.tabGroupName}}</li>
        <li>
          <ul class="sub-menu-list-supplier">
            <ng-container *ngFor="let subTab of tabGroup?.subTabs;let subTabIndex = index">
              <li *ngIf="subTab.isSubTabVisible" style="padding-bottom: 12px !important;"
                (click)="onClickSubMenuTab(mainTabs[selectedMainMenuIndex],tabGroup,subTab)"
                [ngClass]="subTab.subTabName === displaySelectedTabName()?'selected-sub-menu-item-supplier':''"
                matTooltip="{{subTab.subTabTooltip}}" matTooltipClass="cisco-tooltip" matTooltipPosition="right">
                {{subTab.subTabName}}
              </li>
            </ng-container>
          </ul>
        </li>

      </ul>
    </ng-container>
  </div>
  <!-- Supplier Login menu panel start-->
<!-- <div id="breadcrumb" style="height: 24px !important;background-color: #ffffff !important
  ;margin-left: 15px !important;" 
  *ngIf="breadcrumbs.length > 0 && isLoggedIn && loggedInUser?.role?.value !== 'scw_supplier_user_res' && loggedInUser?.role?.value !== 'sucm_res' && loggedInUser?.role?.value !== 'socm_res' && loggedInUser?.role?.value !== 'comm_dir_res' && loggedInUser?.role?.value !== 'SCO_scw_general_user_res' && loggedInUser?.role?.value !== 'gsm_general_use' && loggedInUser?.role?.value !== 'scw_admin_res'">
    <ol class="breadcrumb" style="height: 24px !important;background-color: #ffffff !important;padding: 5px !important;padding-left: 18px; !important">
      <ng-container *ngFor="let breadcrumb of breadcrumbs; let breadcrumbIndex = index">

      <li class="breadcrumb-item" style="font-size: 14px;" *ngIf="breadcrumb.label !== ' '" >
        <span *ngIf="breadcrumbIndex==0 || breadcrumbIndex==breadcrumbs.length-1; else lastBreadcrumb">
          <span  *ngIf="breadcrumbIndex==0 else nextBreadcrum" (click)="onClickMainMenuBreadCrum(breadcrumb)" 
          style="color: #0070d2 !important;cursor: pointer !important;font-weight: 400 !important;">{{ breadcrumb.label }}</span>
          <ng-template  #nextBreadcrum>
          <span  [routerLink]="breadcrumb.url"
          style="color: #0070d2 !important;cursor: pointer !important;font-weight: 400 !important;">{{ breadcrumb.label }}</span>
          
        </ng-template>
        <ng-container *ngIf="(breadcrumbs.length === 1 || (breadcrumbs.length > 1 && breadcrumbIndex === breadcrumbs.length - 1)) && displaySelectedSuplier() == 'ALL' && loggedInUser?.role?.value !== 'scw_supplier_user_res'">
          <span (click)="toggleFavorite(breadcrumb)" style="cursor: pointer; margin-left: 5px;">
            <i class="fa" [ngClass]="{'fa-star': isFavorite(breadcrumb), 'fa-star-o': !isFavorite(breadcrumb)}"
                [ngStyle]="{'color': isFavorite(breadcrumb) ? 'orange' : 'inherit'}"></i>
          </span>
        </ng-container>
      </span>        
        <ng-template  #lastBreadcrumb><span>{{ breadcrumb.label }}</span></ng-template>
             
      </li>
      
    </ng-container>   
    <li class="marquee-item" style="padding-left: 28px !important;" *ngIf="displaySelectedSuplier() !== 'ALL'">
      <marquee behavior="scroll" direction="left" style="color: red;">{{scrollMsg}}</marquee>

    </li>

    </ol>
  </div> -->

<mat-sidenav-container autosize [style.height.px]="setMainContainerHeight()">
  <!-- filter panel starts -->
  <mat-sidenav [mode]="router.url.includes('general-user-home')?'side':'over'" [(opened)]="isFilterOpened"
    class="filter-panel" [style.height.px]="browserScreenHeight"
    *ngIf="isLoggedIn && loggedInUser?.role?.value !== 'scw_supplier_user_res'">
    <div class="row" style="height: 45px !important;">
      <div class="col-12" style="text-align: end;">
        <button mat-icon-button matTooltip="Reset Filter" (click)="onSelectSupplier('ALL')"
          matTooltipClass="cisco-tooltip" matTooltipPosition="right" style="width: 45px;">
          <span class="icon-reset"></span>
        </button>
        <button mat-icon-button matTooltip="Info" matTooltipClass="cisco-tooltip" matTooltipPosition="right"
          (click)="onClickInfoIcon('open','filter-info')" style="width: 45px;">
          <span class="icon-info-outline"></span>
        </button>
      </div>
    </div>
    <div class="row sidenav-fields" style="height: 65px !important;">
      <div class="col-12">
        <mat-form-field appearance="outline">
          <mat-label>View By Commodity</mat-label>
          <mat-select panelClass="sidenav-select-fields" multiple (selectionChange)="onChangeCommodity()"
            [(ngModel)]="selectedCommodity" [ngModelOptions]="{ standalone: true }">
            <input id="commodity" class="search" placeholder="Search Commodity" [(ngModel)]="searchedCommodity">
            <mat-option class="selectAll" value="Select All">
              <mat-checkbox (change)="selectAllCommodities($event)" [(ngModel)]="isAllCommoditiesSelected"
                [ngModelOptions]="{ standalone: true }">Select All</mat-checkbox>
            </mat-option>
            <mat-option *ngFor="let sucm of commodityList"
              [ngClass]="{ 'hide-unmatched-mat-option': commodityList | search: sucm.commodityName : searchedCommodity: 'commodity' }"
              [value]="sucm.commodityName">{{sucm.commodityName}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row sidenav-fields" style="height: 65px !important;">
      <div class="col-12">
        <mat-form-field appearance="outline">
          <mat-label>View By SUCM</mat-label>
          <mat-select panelClass="sidenav-select-fields" multiple (selectionChange)="onChangeSucm()"
            [(ngModel)]="selectedSucm" [ngModelOptions]="{ standalone: true }">
            <input id="sucm" class="search" placeholder="Search Sucm" [(ngModel)]="searchedSucm">
            <mat-option class="selectAll" value="Select All">
              <mat-checkbox (change)="selectAllSucm($event)" [(ngModel)]="isAllSucmSelected"
                [ngModelOptions]="{ standalone: true }">Select All</mat-checkbox>
            </mat-option>
            <mat-option *ngFor="let sucm of sucmList"
              [ngClass]="{ 'hide-unmatched-mat-option': sucmList | search: sucm.sucmName : searchedSucm: 'sucm' }"
              [value]="sucm.sucmName">
              {{sucm.sucmName}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row sidenav-fields" style="height: 65px !important;">
      <div class="col-12">
        <mat-form-field class="sortSpend" appearance="outline">
          <mat-label>Sort Spend Suppliers</mat-label>
          <mat-select (selectionChange)="onChangeSortType($event.value)" [(ngModel)]="sortSupplierType">
            <mat-option value="SortBySpend">Sort supplier by spend</mat-option>
            <mat-option value="SortAlphabetically">Sort supplier alphabetically</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row sidenav-fields">
      <div class="col-12" style="height: 45px !important;">
        <mat-form-field appearance="outline">
          <mat-label>View By Supplier</mat-label>
          <input type="text" placeholder="Search Supplier..." [(ngModel)]="searchedSupplier"
            [ngModelOptions]="{ standalone: true }" matInput>
        </mat-form-field>
      </div>
      <div class="col-12" style="overflow: auto !important;" [style.height.px]="setHeightOfSupplierList()">
        <mat-selection-list multiple="false">
          <mat-list-option *ngFor="let supplier of supplierList;let i=index" [value]="changeForSpend(supplier)"
            class="{{applyClassForSupplier(supplier)}}"
            [ngClass]="{'hide-unmatched-mat-option': supplierList | search: changeForSpend(supplier) : searchedSupplier}"
            (click)="onSelectSupplier(supplier)">
            {{changeForSpend(supplier)}}&nbsp;
            <span *ngIf="showRestrictedFlag(supplier)" class="icon-blocked icon-small restricted-flag"
              matTooltip="{{tooltipForRestrictedSupplier(supplier)}}" matTooltipClass="cisco-tooltip"></span>
          </mat-list-option>
        </mat-selection-list>
      </div>
    </div>
    <!-- filter panel ends -->
  </mat-sidenav>

  <!-- menu panel starts -->
  <mat-sidenav mode="over" position="end" [(opened)]="isMenuOpened" class="menu-panel" *ngIf="isLoggedIn"
    [@.disabled]="disableToggleAnimation">
    <div class="row" style="height: 100% !important;">
      <div class="col-3" style="height: 100% !important;">
        <ul class="main-menu-list">
          <ng-container *ngFor="let mainMenu of mainTabs;let menuIndex = index">
            <li *ngIf="mainMenu.isMainTabVisible" (click)="onClickMainMenuTab(mainMenu)"
              [ngClass]="menuIndex === selectedMainMenuIndex?'selected-main-menu':''"
              matTooltip="{{mainMenu.mainTabTooltip}}" matTooltipClass="cisco-tooltip" matTooltipPosition="right">
              {{mainMenu.mainTabName}}</li>
          </ng-container>
        </ul>
      </div>
      <div class="col-9" style="height: 100% !important;display: inline-flex !important;overflow-x: auto !important;">
        <ng-container *ngFor="let tabGroup of mainTabs[selectedMainMenuIndex]?.tabGroups;let tabGrpIndex = index">
          <ul *ngIf="tabGroup.isTabGroupVisible" class="tab-group-list">
            <li class="tab-group-header" matTooltip="{{tabGroup.tabGroupTooltip}}" matTooltipClass="cisco-tooltip"
              matTooltipPosition="right">
              {{tabGroup.tabGroupName}}</li>
            <li>
              <ul class="sub-menu-list">
                <ng-container *ngFor="let subTab of tabGroup?.subTabs;let subTabIndex = index">
                  <li *ngIf="subTab.isSubTabVisible"
                    (click)="onClickSubMenuTab(mainTabs[selectedMainMenuIndex],tabGroup,subTab)"
                    [ngClass]="subTab.subTabName === displaySelectedTabName()?'selected-sub-menu-item':''"
                    matTooltip="{{subTab.subTabTooltip}}" matTooltipClass="cisco-tooltip" matTooltipPosition="right">
                    {{subTab.subTabName}}
                  </li>
                </ng-container>
              </ul>
            </li>
          </ul>
        </ng-container>
      </div>
    </div>
  </mat-sidenav>
  <!-- menu panel ends -->

  <!-- main side content starts -->
  <div class="sidenav-content" [style.height.px]="setMainContainerHeight()" [style.overflow-y]="(router.url.includes('sucm-home') || router.url.includes('faq')
    || router.url.includes('about-us') || router.url.includes('supplier-whitepaper'))?'hidden':'auto'"
    style="overflow-x: hidden !important;">
    <div class="row sidenav-main-content">
    <ng-container *ngIf="isLoggedIn && router.url.includes('supplier-home') && (addSupplierNotes || viewSupplierNote)">
      <div class="col-12 supplier-notes-div" style="padding: 0.5% 0.5% !important;">
        <div class="row">
          <div class="col-6" style="padding-left: 0.5% !important;width: 50% !important;">
            <label style="vertical-align: -webkit-baseline-middle">
              <h3 style="font-weight: 700;">Supplier Note
              </h3>
            </label>
          </div>
          <div class="col-6" *ngIf="addSupplierNotes"
            style="padding: 0.5% 0.5% !important;width: 50% !important;text-align: end;">
            <label style="color: red;vertical-align: -webkit-baseline-middle;font-size: 13px;font-weight: 400;">
              Maximum 120 characters are allowed.
            </label>
          </div>
          <div class="col-6" style="padding: 0.5% 0.5% !important;width: 50% !important;" *ngIf="addSupplierNotes">
            <label style="color: red;vertical-align: -webkit-baseline-middle;font-size: 13px;font-weight: 400;">
              Note: use the "&#64;" symbol to mention a Supplier to enable the link to the Suppliers home page
            </label>
          </div>
          <div class="col-6 supplier-note-action" style="text-align: end;padding: 0.5% 0.5% !important;width: 50% !important;"
            *ngIf="addSupplierNotes">
            <mat-checkbox class="deleted-checkbox" [checked]="enableSupplierNote === 'Y'"
              (change)="enableSupplierNote = $event.checked ? 'Y':'N'">
              Enable Supplier Note</mat-checkbox>
            <button mat-raised-button class="btn btn-primary" [disabled]="supplierNotes === ''"
              (click)="saveSupplierNote('save')">Save</button>
            <button mat-raised-button class="btn btn-dark" [disabled]="supplierNotes === ''"
              (click)="saveSupplierNote('cancel')">Cancel</button>
          </div>
          <quill-editor *ngIf="addSupplierNotes" id="supplier-notes" [placeholder]="''" class="content-editor supplier-notes"
          [modules]="tools" [(ngModel)]="supplierNotes" [mention]="suppliers" [mentionConfig]="mentionConfig"
          (onContentChanged)="onSupplierNotesChanged($event)">
        </quill-editor>
        <quill-view-html *ngIf="viewSupplierNote" class="view-supplier-notes" [content]="supplierNotes"
          (click)="onClickSupplierNote($event)"
          [ngClass]="{'supplier-cusor-style':loggedInUser?.role?.value === 'scw_supplier_user_res'}"></quill-view-html>
        </div>
      </div>
    </ng-container>

      <router-outlet></router-outlet>


      <div class="modal fade help-page-popup" *ngIf="isHelpPageOpened"
        [style.display]="isHelpPageOpened?'block': 'none'" style="background: rgb(0 0 0 / 62%) !important;">
        <div class="modal-dialog" role="document" style="max-width: 100% !important;height: 97% !important;">
          <div class="modal-content"
            style="height:95% !important;margin-top: 0% !important;background: transparent !important;border: none !important;">
            <div class="modal-body" style="text-align: initial;">
              <div class="row">
                <div class="col-12">
                  <label style="color: #ffffff;
        width: 10%;
        position: absolute;
        left: 78%;
        top: 22%;
        font-weight: 700;">To report any issue with data, create a case here</label>
                  <img src="assets/images/report_arrow.png" style="width: 10% !important;
        position: absolute;
        top: 30px;
        left: 88%;">

                  <label style="color: #ffffff;
        width: 10%;
        position: absolute;
        left: 9%;
        top: 49%;
        font-weight: 700;">The filter allows to view data for specific Suppliers by Commodity or by SUCM</label>
                  <img src="assets/images/filter_arrow.png" style="width: 10% !important;
        position: absolute;
        left: -1%;
        top: 10%;
        height: 50%;">

                  <label style="color: #ffffff;
        width: 10%;
        position: absolute;
        left: 13%;
        top: 15%;
        font-weight: 700;">Use the Menu to quickly navigate across the various pages</label>
                  <img src="assets/images/menu_arrow.png" style="width: 10% !important;
        position: absolute;
        left: 3%;
        top: 10%;">
                </div>
              </div>
            </div>
            <div class="modal-footer" style="border: none !important;">
              <div class="row" style="display: flex !important;">
                <div class="col-12" style="text-align: center;">
                  <label style="color: #ffffff;cursor: pointer !important;font-size: 17px;font-weight: 700;"
                    *ngIf="!router.url.includes('sucm-home')" (click)="onClickQuickTour('close')">Close</label>
                  <label style="color: #ffffff;cursor: pointer !important;font-size: 17px;font-weight: 700;"
                    *ngIf="router.url.includes('sucm-home')" (click)="onClickQuickTour('sucm-quick-tour')">Next</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-report-issues *ngIf="openReportIssuesPopup" (clickReportIssues)="onClickReportIssues($event)">
  </app-report-issues>
  <!-- main side content ends -->
</mat-sidenav-container>


<!-- footer starts -->
<mat-toolbar class="app-footer">
  <mat-toolbar-row class="footer">
    <ul class="footer-list">
      <li>
        <a href="https://www.cisco.com/c/en/us/about/legal/terms-conditions.html" target="_blank">
          Terms & Condition
        </a>
      </li>
      <li>
        <a href="https://www.cisco.com/c/en/us/about/legal/privacy-full.html" target="_blank">
          Privacy Statement
        </a>
      </li>
      <li>
        <a href="https://www.cisco.com/c/en/us/about/legal/privacy-full.html#cookies" target="_blank">
          Cookie Policy
        </a>
      </li>
      <li>
        <a href="https://www.cisco.com/c/en/us/about/legal/trademarks.html" target="_blank">
          TradeMarks
        </a>
      </li>
    </ul>
  </mat-toolbar-row>
</mat-toolbar>
<!-- footer ends -->


<app-info-details *ngIf="infoPopup" [infoData]="readMeInfo" (clickClose)="onClickInfoIcon($event)"></app-info-details>

<div class="modal fade dataRefreshPopup" [style.display]="isDataRefreshPopup?'block':'none'">
  <div class="modal-dialog" role="document">
    <div class="modal-content" style="margin-top: 2% !important;">
      <div class="modal-header">
        <div class="row">
          <div class="col-11">
            <h1 class="modal-title">Data Refresh Info </h1>
          </div>
          <div class="col-1">
            <button mat-icon-button style="float: right;" (click)="onClickDataIntegrationIcon('close')">
              <span class="icon-close" style="float: right;">
              </span>
            </button>
          </div>
        </div>
      </div>
      <div class="modal-body" style="text-align: initial;">
        <div class="row" style="margin-top: 1%;height: 100% !important;">
          <div class="col-12" style="height: 100% !important;">
            <ag-grid-angular #dataRefreshGrid class="ag-theme-balham" style="height: 100% !important;"
              [rowData]="dataRefreshGridConfig.rowData" [columnDefs]="dataRefreshGridConfig.columnDefs"
              [defaultColDef]="dataRefreshGridDefaultColDef" [animateRows]="true" [suppressDragLeaveHidesColumns]="true"
              [columnTypes]="columnTypes" [context]="context" [components]="frameworkComponents"
              [gridOptions]="dataRefreshGridOptions">
            </ag-grid-angular>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>