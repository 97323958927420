import { Injectable } from '@angular/core';
import { AuthConfig } from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root',
})
export class EnvService {
  // API url
  public apiUrl = '';

  // Whether or not to enable debug mode
  public enableDebug = true;

  // Ping Access config
  public ssoUrl = '';
  public clientId = '';

  // API version

  // API sub path

  // API version
  public commonAPIVersion = '/v1';

  // API sub path
  public commonSubPath = '/common';

  constructor() {
    this.ssoUrl = window['__env'].ssoUrl;
    this.clientId = window['__env'].clientId;
  }

  public oauthConfig(): AuthConfig {
    return {
      // Url of the Identity Provider
      issuer: this.ssoUrl,

      // URL of the SPA to redirect the user to after login
      redirectUri: window.location.origin.includes("localhost") ? window.location.origin + '/callback' : window.location.origin + '/callback',

      logoutUrl: this.ssoUrl + '/v1/logout',

      postLogoutRedirectUri: window.location.origin.includes("localhost") ? window.location.origin + '/logout' : window.location.origin + '/logout',

      // URL of the SPA to redirect the user after silent refresh
      silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',

      /**
       * Defines when the token_timeout event should be raised.
       * If you set this to the default value 0.75, the event
       * is triggered after 75% of the token's life time.
       */
      // timeoutFactor: 0.2, // only for debugging
      timeoutFactor: 0.75,


      // The SPA's id. The SPA is registerd with this id at the auth-server
      clientId: this.clientId,

      // code flow + PKCE
      responseType: 'code',

      // set the scope for the permissions the client should request
      scope: 'openid profile email offline_access groups',

      showDebugInformation: this.enableDebug,

    };
  }
}
