import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, CanLoad, Router, RouterStateSnapshot, Route } from '@angular/router';
import { SharedService } from '../shared-service/shared.service';

@Injectable()
export class AuthGuard implements CanLoad, CanDeactivate<any> {
    isValid = false;
    constructor(
        private router: Router,
        private sharedService: SharedService
    ) { }

    canDeactivate(component: any,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot) {
        this.isValid = window.localStorage.length === 0 ? false : true;
        if ((currentState.url === '/logout' || currentState.url === '/no-cepm-role'
            || currentState.url === '/supplier-out-of-scope') && !this.isValid) {
            return false;
        }
        else {
            return true;
        }
    }

    checkTokenExpiration() {
        const currentUrl: any = window.location.href;

        // checking whether your token has expired
        if (window.localStorage.length === 0 && currentUrl && currentUrl.split('#')[1] && currentUrl.split('#')[1] !== '/') {
            return false;
        }
        else {
            return true;
        }
    }

    checkUserRole(requiredRoles: string[]): boolean {
        const userRoles = JSON.parse(window.localStorage.getItem('userRoles') || '[scw_supplier_user]');
        return requiredRoles.some(role => userRoles.includes(role));
    }

    canLoad(route: Route) {
        // this.isValid = this.checkTokenExpiration();
        // if (!this.isValid) {
        //     this.sharedService.sendClickEvent(false);
        //     return false;
        // }

        const requiredRoles = route?.data && route?.data.roles ? route?.data?.roles : [];       
        const userRoles = ['scw_supplier_user'];
        const respData = JSON.parse(window.localStorage.getItem('cepmRespData'));
        if (respData && respData.roles && respData.roles.length > 0) {
            if ((!requiredRoles.includes('scw_supplier_user')) && (respData.roles[0].value === 'scw_supplier_user')) {
                this.router.navigate(['/supplier-new-home']);
                return false;
            }
        }       

        return true;
    }
}
