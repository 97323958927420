<span class="icon-file-archive-o grid-renderer-archive-icon" title="Archive"
    *ngIf="params.colDef.field === 'questionAction' && params.node.level === 0 && params?.column?.userProvidedColDef?.cellRendererParams?.incidentsTab === 'incTab2'"
    (click)="onClickDeleteOrArchive('A')"></span>

<span class="icon-delete grid-renderer-delete-icon" title="Only SCW Admin Can Delete Incidents"
    *ngIf="params.colDef.field === 'questionAction' && params.node.level === 0 && params?.column?.userProvidedColDef?.cellRendererParams?.incidentsTab === 'incTab1' && params.data.responsePercent === 0.0"
    [ngClass]="{'disable':(userType !== 'scw_admin_res')}" (click)="onClickDeleteOrArchive('D')"></span>

<span title="Reminder" class="icon-bell grid-renderer-reminder-icon" (click)="sendIncidentReminder()"
    *ngIf="params.colDef.field === 'questionAction' && params.data.incidentStatus ==='OPEN' && params?.column?.userProvidedColDef?.cellRendererParams?.incidentsTab === 'incTab1' && params.data.surveyStatus === 'PUBLISHED' && params.node.level === 0 && (userType ==='scw_admin_res' || userType ==='sucm_res') && (params.data.publishedSupplierCount > 0 && params.data.responsePercent !== 100)">
</span>

<!-- <span title="Publish" class="icon-share-contain grid-renderer-publish-icon" (click)="publishSurvey()"
    *ngIf="params.colDef.field === 'questionAction' && params.data.incidentStatus === 'OPEN' && params?.column?.userProvidedColDef?.cellRendererParams?.incidentsTab === 'incTab1' && params.node.level === 0 && (userType ==='scw_admin_res' || userType ==='sucm_res')">
</span> -->

<span title="Unpublish Incident"
    *ngIf="params.colDef.field === 'questionAction' && params.node.level === 0 && params?.column?.userProvidedColDef?.cellRendererParams?.incidentsTab === 'incTab1' && params.data.unpublishFlag === 'E' && (userType === 'scw_admin_res' || userType === 'sucm_res')"
    class="icon-prevent-download-contain" (click)="unpublishIncident()"></span>

<span title="Edit" class="icon-edit grid-renderer-edit-icon" (click)="updateSurveyInQuestion()"
    *ngIf="params.colDef.field === 'questionAction' && params.data.incidentStatus === 'OPEN' && params?.column?.userProvidedColDef?.cellRendererParams?.incidentsTab === 'incTab1' && params.data.surveyStatus !== 'PUBLISHED' && params.node.level === 0 && (userType ==='scw_admin_res' || userType ==='sucm_res')">
</span> 

<ng-container
    *ngIf="params.colDef.field === 'questionAction' && params.data.incidentStatus === 'OPEN' && params?.column?.userProvidedColDef?.cellRendererParams?.incidentsTab === 'incTab1' && params.node.level === 0 && (userType ==='scw_admin_res' || userType ==='sucm_res')">
    <br>
    <label title="Publish" (click)="publishSurvey()"
        style="width: 100%;background: #07a7077a;text-align: center;font-weight: bold;cursor: pointer;">
        Publish
    </label>
</ng-container>
