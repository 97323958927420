import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LookupService } from '../services/lookup.service';
import { LoaderService } from '../loader/loader.service';
@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.css']
})
export class ConfirmationComponent {
  @Input() title;
  @Input() message;
  @Input() showCancelButton: boolean;
  @Input() showYesNoBtn = 'No';
  @Input() showInfoIcon: boolean;
  @Input() okButtonLabel: string = 'OK';
  infoPopup: boolean = false;
  readMeInfo: any;
  constructor(public activeModal: NgbActiveModal,
    public loaderService: LoaderService,
    public lookupService: LookupService) { }
    ngOnInit(): void {
      // if (this.shouldPreventActivation()) {
      //   this.activeModal.close(true) ;
      // }
      //this.closeComponentAfterCondition();
    }
  onClickInfoIcon(val: any, type?: any) {
    if (val === 'open') {
      this.infoPopup = true;
      this.loaderService.openLoader();
      this.lookupService.getDropdownLookup('SCW_INFO_ICON_DATA')
        .subscribe({
          next: (respData: any) => {
            this.loaderService.closeLoader();
            if (respData && respData.lookupArray && respData.lookupArray.length > 0) {
              if (type === 'Valid_Domain_Info') {
                this.readMeInfo = respData.lookupArray.filter((s: any) => s.code === 'VALID DOMAIN INFO')[0];
              }
            }
          },
          error: (error) => {
            this.loaderService.closeLoader();
          }
        });
    }
    else {
      this.infoPopup = false;
      this.readMeInfo = '';
    }
  }

  closeComponentAfterCondition() {
    if (this.title === 'Confirmation' && !this.showCancelButton) {
      setTimeout(() => {
        this.activeModal.close(true);
      }, 3000);
    }
  }

  shouldPreventActivation(): boolean {
    return ((this.title == 'Error') && ((this.message == '') || (this.message == undefined)));
  }
}
