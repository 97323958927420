import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule, MAT_MENU_DEFAULT_OPTIONS } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipDefaultOptions, MatTooltipModule, MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule, MAT_SELECT_CONFIG } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, } from '@angular/material/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { MatExpansionModule } from '@angular/material/expansion';
// import { MdePopoverModule } from '@material-extended/mde';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatBadgeModule } from '@angular/material/badge';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DragDropModule } from '@angular/cdk/drag-drop';

export const matTooltipDefaults: MatTooltipDefaultOptions = {
    showDelay: 0,
    hideDelay: 0,
    touchendHideDelay: 1500,
    disableTooltipInteractivity: true
};
@NgModule({
    imports: [
        MatButtonModule,
        MatMenuModule,
        MatToolbarModule,
        MatIconModule,
        MatCardModule,
        MatSidenavModule,
        MatTooltipModule,
        MatTabsModule,
        MatListModule,
        MatSelectModule,
        MatInputModule,
        MatCheckboxModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatRadioModule,
        MatExpansionModule,
        // MdePopoverModule,
        MatDialogModule,
        MatSnackBarModule,
        MatSlideToggleModule,
        MatChipsModule,
        MatBadgeModule,
        ScrollingModule,
        DragDropModule
    ],
    exports: [
        MatButtonModule,
        MatMenuModule,
        MatToolbarModule,
        MatIconModule,
        MatCardModule,
        MatSidenavModule,
        MatTooltipModule,
        MatTabsModule,
        MatListModule,
        MatSelectModule,
        MatInputModule,
        MatCheckboxModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatRadioModule,
        MatExpansionModule,
        // MdePopoverModule,
        MatDialogModule,
        MatSnackBarModule,
        MatSlideToggleModule,
        MatChipsModule,
        MatBadgeModule,
        ScrollingModule,
        DragDropModule
    ],
    providers: [
        {
            provide: MatDialogRef,
            useValue: {}
        },
        {
            provide: MAT_MENU_DEFAULT_OPTIONS,
            useValue: { overlayPanelClass: 'menu-overlay-pane' }
        },
        {
            provide: MAT_SELECT_CONFIG,
            useValue: { overlayPanelClass: 'mat-select-overlay-pane' }
        },
        {
            provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
            useValue: matTooltipDefaults
        }
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MaterialModule { }
