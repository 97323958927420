import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})

export class RegionService {
    ipAddr: any;
    constructor(private http: HttpClient) {
    }

    // fetch region based on IP Address
    getRegion() {
        if ((this.ipAddr !== undefined) || (this.ipAddr !== null)) {
            this.http.get('https://ipapi.co/' + this.ipAddr + '/json').subscribe((res: any) => {
                window.localStorage.setItem('regionCode', res.region_code);
                window.localStorage.setItem('countryCode', res.country_code);
                window.localStorage.setItem('continentCode', res.continent_code);
                window.localStorage.setItem('os', res.os);
            });
        }
    }
    // fetch IP Address for get region
    getIpAddr() {
        // this.http.get('https://jsonip.com').subscribe((res: any) => {
        //     this.ipAddr = res.ip;
        //     this.getRegion();
        // });

        window.localStorage.setItem('regionCode', '');
        window.localStorage.setItem('countryCode', '');
        window.localStorage.setItem('continentCode','');
        window.localStorage.setItem('os', '');
    }
}
