<!-- <span class="ag-icon ag-icon-tree-closed" *ngIf="params?.node?.level === 0" (click)="onExpandCollapseClick($event)">{{ params.value }}</span> -->



<ng-container *ngIf="params?.node?.level === 0">
  <span class="ag-icon"
    [ngClass]="{ 'ag-icon-tree-closed': !params.node.expanded, 'ag-icon-tree-open': params.node.expanded }"
    (click)="onExpandCollapseClick($event)"></span>
  <span *ngIf="params.colDef.headerName === 'Response %' ; else other">
    <span>{{ params.value }} %</span>
  </span>
  <ng-template #other>
    <ng-template [ngIf]="params.colDef.headerName === 'Publish Status'">
      <span>Status : <span class="{{showStatusColor()}}">{{showPublishStatus()}}</span></span><br>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>Impacted Suppliers : {{params.data.impactedSupplierCount}}</span><br>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="{{showColorForPublishedSupplierCount()}}">Published Suppliers : {{params.data.publishedSupplierCount}}</span><br>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="black">Descoped Suppliers : {{params.data.descopedSupplierCount}}</span>
    </ng-template>
    <ng-template [ngIf]="params.colDef.headerName === 'Incident Status'">
     
      <span>{{params.data.incidentStatus}}</span><br>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style="color:#e2231a ; font-weight: bold;">Reported Impact : {{params.data.reportedImpact}}</span><br>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>Reported No Impact : {{params.data.reportedNoImpact}}</span><br>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>Reported Not Known : {{params.data.reportedNotKnown}}</span><br>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>Response Pending : {{params.data.reportedPending}}</span>
 
    </ng-template>
    <span *ngIf="params.colDef.headerName !== 'Publish Status'&& params.colDef.headerName !== 'Incident Status'">{{ params.value }}</span>
  </ng-template>
</ng-container>