import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class ContactManagementService {

    public apiUrl: any;
    constructor(private http: HttpClient) {
        this.apiUrl = window['__env'].apiUrl;
    }

    getContactDetails(requestObj: any, viewFlag: any) {
        return this.http.post(`${this.apiUrl}/contactService/viewAllContacts?viewFlag=${viewFlag}`, requestObj);
    }
   
    getPamDetails(supplierName:any) {
        return this.http.get(`${this.apiUrl}/contactService/fetchPamIds?supplierName=${supplierName}`)
    }

    createUpdateContact(requestObject: any, viewType: any, userType: any, cecIdRole: any) {
        return this.http.post(`${this.apiUrl}/contactService/createUpdateContact?viewType=${viewType}&userType=${userType}&cecIdRole=${cecIdRole}`,
            requestObject);
    }

    deleteContact(viewType: any, flag: any, requestObject: any) {
        return this.http.post(`${this.apiUrl}/contactService/deleteContact?viewType=${viewType}&flag=${flag}`, requestObject);
    }

    restoreContact(contactId: any) {
        return this.http.post(`${this.apiUrl}/contactService/restoreContact?contactId=${contactId}`, {});
    }

    checkInternalContact() {
        return this.http.post(`${this.apiUrl}/contactService/checkInternalContact`, {});
    }

    uploadContactsExcel(requestObj: any) {
        return this.http.post(`${this.apiUrl}/contactService/uploadBulkContact`, requestObj);
    }

    fetchSavedContactAttr(supp_name: any, viewType: any) {
        return this.http.get(`${this.apiUrl}/contactService/fetchSavedContactFields?supplierName=${supp_name}&viewType=${viewType}`)
    }

    saveContactGridAttr(requestObj: any) {
        return this.http.post(`${this.apiUrl}/contactService/saveContactFields`, requestObj)
    }

    downloadAttachments() {
        return this.http.get(`${this.apiUrl}/contactService/download/ContactMgmUploadData.xlsx`, {
            responseType: "blob",
            headers: new HttpHeaders().append("Content-Type", "application/json")
        });
    }

    getSupplierContacts(requestObj: any) {
        return this.http.post(`${this.apiUrl}/contactService/fetchSupplierContactFields`, requestObj);
    }

    filterContacts(requestObj: any) {
        return this.http.post(`${this.apiUrl}/contactService/fetchContactDetailsViaFilter`, requestObj);
    }
}
